<template>
  <div class="placeholder">
    <div class="error-message-style">
      {{ errorMessage }}
    </div>
    <video id="videoElement" height="100%" autoplay playsinline ref="localVideo"/>
  </div>
</template>

<script>
// import adapter from 'webrtc-adapter';

export default {
  name: 'CameraVideo',
  data() {
    return {
      errorMessage: '',
      readyVideo: false,
      videoConstraints: {
        audio: false,
        video: {
          width: { min: 240, ideal: 240, max: 240 },
          height: { min: 240, ideal: 240, max: 240 },
          deviceId: {
            ideal: '',
          },
        },
      },
    };
  },
  methods: {
    setCamera(deviceId) {
      this.errorMessage = '';
      this.videoConstraints.video.deviceId.ideal = deviceId;
      if (navigator.userAgent.indexOf('Firefox') !== -1) {
        console.log('firefox is used');
        this.videoConstraints.video.width = null;
        this.videoConstraints.video.height = null;
      }
      navigator.mediaDevices.getUserMedia(this.videoConstraints)
        .then((stream) => {
          this.$refs.localVideo.srcObject = stream;
          console.log(this.videoConstraints);
          return new Promise((resolve) => {
            this.$refs.localVideo.onplaying = resolve;
          });
        })
        .then(() => {
          this.readyVideo = true;
          console.log('video ready');
        })
        .catch((err) => {
          if (err.name === 'NotReadableError') {
            this.errorMessage = 'Camera access has been prevented. If you are running other '
              + 'voice/video applications please close them and reselect your camera.';
          } else {
            this.errorMessage = 'No camera found.';
          }
        });
    },
    checkReady() {
      let check = false;
      if (this.readyVideo) {
        check = true;
      }
      return check;
    },
  },
};
</script>

<style scoped>
  .placeholder {
    border: grey solid 1px;
    background-color: lightgray;
    width: 240px;
    height: 240px;
  }
  .error-message-style {
    color: red;
  }
</style>
