import Login from '../containers/login/index.vue';
import NotFound from '../containers/notfound/index.vue';
import EnterName from '../containers/entername/index.vue';
import SelectCamera from '../containers/selectcamera/index.vue';
import PictureProfile from '../containers/pictureprofile/index.vue';
import SelectSpeaker from '../containers/selectspeaker/index.vue';
import SelectMicrophone from '../containers/selectmicrophone/index.vue';
import Room from '../containers/room/index.vue';
import About from '../containers/about/index.vue';
import Tutorial from '../containers/tutorial/index.vue';
import LandingPage from '../containers/landingpage/index.vue';
import WhyMingly from '../containers/whymingly/index.vue';

import { iAmLeaving } from '../assets/js/webrtc';

const routes = [
  // External routes (Temporary?)
  {
    path: '/questionaire0',
    name: 'Questionnaire0',
    beforeEnter() {
      window.location.replace('https://docs.google.com/forms/d/e/1FAIpQLSd6D2TidJ8m-jg6PC2gvx8R5jc1RnfNkF4ecEi4HdT9pJbq9g/viewform?usp=sf_link');
    },
  },
  {
    path: '/questionaire1',
    name: 'Questionnaire1',
    beforeEnter() {
      window.location.replace('https://docs.google.com/forms/d/e/1FAIpQLSe78pxETJ3W0rRHEsqb40j9Rcgr_ngQgSbN81ac_TwhKPDlaQ/viewform?usp=sf_link');
    },
  },
  {
    path: '/questionaire2',
    name: 'Questionnaire2',
    beforeEnter() {
      window.location.replace('https://docs.google.com/forms/d/e/1FAIpQLSeFVGBu1CmXA50PotEyDSmMkTJdPjVlUBbwK9q3EX_0LvPWJg/viewform?usp=sf_link');
    },
  },
  {
    path: '/questionaire3',
    name: 'Questionnaire3',
    beforeEnter() {
      window.location.replace('https://docs.google.com/forms/d/e/1FAIpQLSdDhXFmb3JokKmS9gapwZVQwtRRHHf826J8GqsRQNYZJUt6yg/viewform?usp=sf_link');
    },
  },
  {
    path: '/questionaire4',
    name: 'Questionnaire4',
    beforeEnter() {
      window.location.replace('https://docs.google.com/forms/d/e/1FAIpQLSeDxQjiSe9KSl4yqp2Py1-4DsS6oNC2eiL7uWxO5DxUshypAQ/viewform?usp=sf_link');
    },
  },
  {
    path: '/questionaire5',
    name: 'Questionnaire5',
    beforeEnter() {
      window.location.replace('https://docs.google.com/forms/d/e/1FAIpQLSdU2gJ_CnIO1Sb7ScTB-n4Myp2L-bjb-6jpQL9-xEiYV03TtQ/viewform?usp=sf_link');
    },
  },
  {
    path: '/questionaire6',
    name: 'Questionnaire6',
    beforeEnter() {
      window.location.replace('https://docs.google.com/forms/d/e/1FAIpQLScSAiHNP66cT0eiqBAPKt8LEmzpqp_TBOr9Ka1CGRvlYX-ljQ/viewform?usp=sf_link');
    },
  },
  {
    path: '/questionaire7',
    name: 'Questionnaire7',
    beforeEnter() {
      window.location.replace('https://docs.google.com/forms/d/e/1FAIpQLSd7Q9BKFNOnQl5nxge91oFOAAOj1LKRDYwe_RnzBw5KIz3TRg/viewform?usp=sf_link');
    },
  },
  {
    path: '/questionaire8',
    name: 'Questionnaire8',
    beforeEnter() {
      window.location.replace('https://docs.google.com/forms/d/e/1FAIpQLSeOvPBGNTnr7Gj9bSEQ7l9eGsbA576oaxDYD6zYz5ApRJC4ww/viewform?usp=sf_link');
    },
  },
  {
    path: '/questionaire9',
    name: 'Questionnaire9',
    beforeEnter() {
      window.location.replace('https://docs.google.com/forms/d/e/1FAIpQLSf1kZq05PLfDyaub1mVEhOGWnVBHPNPj8qQUiXfpD0gvRNKyQ/viewform?usp=sf_link');
    },
  },
  {
    path: '/questionaire10',
    name: 'Questionnaire10',
    beforeEnter() {
      window.location.replace('https://docs.google.com/forms/d/e/1FAIpQLSfMRjRKbJNe5oQKnNbtq7FknQmx1uphh7bPXVK91ISPB4Ysmw/viewform?usp=sf_link');
    },
  },
  {
    path: '/newsletter',
    name: 'Newsletter',
    beforeEnter() {
      window.location.replace('https://us20.campaign-archive.com/home/?u=8ba5b701e789de48c1bfcf0b0&id=4f70176e45');
    },
  },
  // Internal routes
  {
    path: '/',
    name: 'LandingPage',
    component: LandingPage,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/name',
    name: 'EnterName',
    component: EnterName,
  },
  {
    path: '/camera',
    name: 'SelectCamera',
    component: SelectCamera,
  },
  {
    path: '/pictureprofile',
    name: 'PictureProfile',
    component: PictureProfile,
  },
  {
    path: '/speaker',
    name: 'SelectSpeaker',
    component: SelectSpeaker,
  },
  {
    path: '/microphone',
    name: 'SelectMicrophone',
    component: SelectMicrophone,
  },
  {
    path: '/roomtemp',
    name: 'Canvas',
    component: Room,
    beforeLeave() {
      iAmLeaving();
    },
  },
  {
    path: '/about',
    name: 'About',
    component: About,
  },
  {
    path: '/whymingly',
    name: 'WhyMingly',
    component: WhyMingly,
  },
  {
    path: '/tutorial',
    name: 'Tutorial',
    component: Tutorial,
  },
  {
    path: '*',
    name: 'NotFound',
    component: NotFound,
    meta: {
      title: 'Page not found',
    },
  },
];

export default routes;
