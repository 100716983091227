// We use dangling underscores to indicate that properties are "private"
/* eslint-disable no-underscore-dangle */

// Magnus' preferences, up for discussion:
/* eslint-disable brace-style,padded-blocks,operator-linebreak,function-paren-newline */
/* eslint-disable no-lonely-if */

// the Board class serves as a skeleton for board definitions
export default class Board {
  constructor(parentContainer) {

    // Properties the Board has default values for
    this.avatarSize = 1;
    this.avatarRadius = 1;
    this.maxSpeed = 1;
    this.scalingFactor = 1;
    this.menuOverlay = false;
    this.move_keyboard = false;
    this.hasInteracted = false; // just used as a pause function at entry
    this.hasWaited = false; // just used as a pause function at entry
    this.isExternal = false; // Type of enviroment (Vue = External, legacy = internal)

    // Keep track of owner
    if (parentContainer) {
      this.parentContainer = parentContainer;
    } else {
      console.error('Board.constructor: parentContainer not defined');
    }
  }

  // functions that inheriting classes need to override

  /* eslint-disable-next-line no-unused-vars */
  static isSpotAvailable(x, y) {
    console.error('Board.isSpotAvailable: function not defined');
  }

  /* eslint-disable-next-line no-unused-vars */
  static clearAvatar(participant) {
    console.error('Board.clearAvatar: function not defined');
  }

  /* eslint-disable-next-line no-unused-vars */
  static drawAvatar(participant) {
    console.error('Board.drawAvatar: function not defined');
  }

  /* eslint-disable-next-line no-unused-vars */
  static setScalingFactor(scalingFactor) {
    console.error('Board.setScalingFactor: function not defined');
  }

  // functions that are common for all board types

  // properties the Board needs to be aware of

  // width
  set width(boardWidth) {
    this._width = boardWidth;
  }

  get width() {
    if (!this._width) {
      console.error('Board.width: width not set');
    }
    return this._width;
  }

  // heigth
  set height(boardHeight) {
    this._height = boardHeight;
  }

  get height() {
    if (!this._height) {
      console.error('Board.height: height not set');
    }
    return this._height;
  }

  // participants
  set participants(participants) {
    this._participants = participants;
  }

  get participants() {
    if (!this._participants) {
      console.error('Board.participants: participants not set');
    }
    return this._participants;
  }

  // me - the client's participant object
  set me(participant) {
    this._me = participant;
  }

  get me() {
    if (!this._me) {
      console.error('Board.me: me not set');
    }
    return this._me;
  }

  // room
  set room(room) {
    this._room = room;
  }

  get room() {
    if (!this._room) {
      console.error('Board.room: room not set');
    }
    return this._room;
  }

  // isTouch - does client have a touch screen?
  set isTouch(isTouch) {
    // make sure we set a Boolean, not an object or whatever we're fed
    if (isTouch) {
      this._isTouch = true;
    }
    else {
      this._isTouch = false;
    }
  }

  get isTouch() {
    // console.error(typeof this_isTouch)
    if (typeof this._isTouch === 'boolean') {
      return this._isTouch;
    }
    console.error('Board.isTouch: isTouch not set');
    return false;
  }

  set isIpad(isIpad) {
    // make sure we set a Boolean, not an object or whatever we're fed
    if (isIpad) {
      this._isIpad = true;
    }
    else {
      this._isIpad = false;
    }
  }

  get isIpad() {
    // console.error(typeof this_isTouch)
    if (typeof this._isIpad === 'boolean') {
      return this._isIpad;
    }
    console.error('Board.isIpad: isIpad not set');
    return false;
  }

}
