import PeerLinkedList from '../../utilities/PeerLinkedList';

// eslint-disable-next-line import/prefer-default-export
export const getBaseState = () => ({
  username: '',
  userID: '',
  host: false,
  room: '',
  menuItems: {
    broadCastMenu: true,
    shareMediaMenu: true,
    pollMenu: true,
    gameMenu: true,
  },
  profilePicture: new Image(),
  videovar: false,
  hoover: false,
  participants: new PeerLinkedList(),
  chatActive: false,
  messages: [{
    chatID: 'everyone-id',
    unreadMessages: 0,
    isActive: true,
    payload: [],
  },
  ],
  totalUnreadMessages: 0,
  pollData: {
    question: '',
    alternatives: [],
    creatorId: '',
    isAnonymous: false,
    isActive: false,
    onCanvas: false,
    quiz: false,
    timed: false,
    answerSelf: false,
    correctAnswerPos: null,
  },
  pollResults: [],
  backgroundImageUrl: 'https://www.dropbox.com/s/fk0u89rhzxfkcmi/IMG_7328.jpeg?raw=1',
});
