<template>
  <div class="background" v-bind:style="{ backgroundImage: 'url(' + backgroundImageUrl + ')' }">
    <v-row>
      <v-col c_ols="12" s_m="6">
        <LoginBox />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import LoginBox from './_components/LoginBox.vue';
// import Video from './_components/Video.vue';

export default {
  name: 'index.vue',
  components: { LoginBox /* , Video */ },
  data: () => ({
    // Sample images from https://imgur.com/gallery/YEY7J
    imageURLs: [
      'https://www.dropbox.com/s/eeaq8lkpwlvhaio/IMG_2775.jpg?raw=1',
    ],
  }),
  computed: {
    ...mapGetters('$_userdata', ['backgroundImageUrl']),
  },
  methods: {
    ...mapActions('$_userdata', ['commitSetBackgroundImage']),
    fetchBackground() {
      const random = Math.floor(Math.random() * 1);
      this.commitSetBackgroundImage(this.imageURLs[random]);
    },
  },
  created() {
    this.fetchBackground();
  },
};
</script>

<style scoped>
.background {
  background-size: cover;
  height: 101%
}
</style>
