<template>
  <div class="background" v-bind:style="{ backgroundImage: 'url(' + backgroundImageUrl + ')' }">
    <v-row>
      <v-col>
        <EnterName />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import EnterName from './_components/EnterName.vue';

export default {
  name: 'index',
  components: { EnterName },
  data: () => ({
    // Sample images from https://imgur.com/gallery/YEY7J
    imageURLs: [
      'https://i.imgur.com/8tcxHWh.jpeg',
      'https://i.imgur.com/BF3ty6o.jpeg',
      'https://i.imgur.com/rel7mXd.jpeg',
      'https://i.imgur.com/JmubWzd.jpeg',
      'https://i.imgur.com/FwnpMqO.jpeg',
    ],
  }),
  computed: {
    ...mapGetters('$_userdata', ['backgroundImageUrl']),
  },
};
</script>

<style scoped>
.background {
  background-size: cover;
  height: 101%
}
</style>
