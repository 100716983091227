export default class CanvasObject {
  constructor(x, y, width, height, source, canvas, type, board) {
    this.uuid = null;
    this.x = x;
    this.y = y;
    this.dx = 0;
    this.dy = 0;
    this.width = width;
    this.height = height;
    this.text = null;
    this.type = type || 'picture';
    this.board = board || null;
    this.source = null;
    this.canvas = canvas;
    this.onClick = null; // function if clicking on object
    this.onHover = {
      hover: false,
      fun: null,
    }; // when hovering over object
    this.isWalkable = true; // can you place yourself on top of the object
    this.ctx = canvas.getContext('2d');
    this.childUuid = [];
    this.reqAnimation = null;
    this.vidReady = null;
    this.isActive = true; // if not active it will not display
    this.dat = null; // a possibility to pass other data to functions
    if (source.muted) {
      this.muted = source.muted;
    } else {
      this.muted = false;
    }
    if (source.loop) {
      this.loop = source.loop;
    } else {
      this.loop = false;
    }
    this.setObject(source);
  }

  drawObject() {
    switch (this.type) {
      case 'picture':
        this.ctx.drawImage(this.source,
          this.x, this.y, this.width, this.height);
        break;
      case 'canvasDrawing':
        // might be tricky to do it this way
        // need to make sure it has the 4 inputs
        // console.log('drawing drawObject!!!!!');
        this.source(this.x, this.y, this.width, this.height, this.text, this.ctx, this.dat);
        break;
      case 'canvasVideo':
        // this is not the right way to do it either!
        this.drawVideo(this.source);
        break;
      default:
        break;
    }
  }

  setVideo(source, loop) {
    this.source = document.createElement('video');
    this.source.setAttribute('autoplay', '');
    if (loop) {
      this.source.setAttribute('loop', '');
    }
    this.source.src = source.src;
    this.source.addEventListener('canplay', () => {
      this.vidReady = true;
      this.source.muted = this.muted;
      this.drawVideo(this.source);
    });
  }

  setObject(source) {
    switch (this.type) {
      case 'picture':
        this.source = new Image();
        this.source = source;
        break;
      case 'canvasDrawing':
        this.source = source;
        break;
      case 'canvasVideo':
        this.setVideo(source, this.loop);
        break;
      default:
        break;
    }
  }

  drawVideo() {
    if (!this.type === 'canvasVideo' || !this.vidReady) {
      return;
    }
    const v = this.source;
    if (v.paused || v.ended || v == null) {
      cancelAnimationFrame(this.reqAnimation);
    }
    const w = this.width;
    const h = (v.videoHeight / v.videoWidth) * w;
    this.ctx.drawImage(v, this.x, this.y, w, h);
    this.reqAnimation = requestAnimationFrame(
      this.drawVideo.bind(this, this.source),
    );
  }

  pointOverObject(lastX, lastY) {
    let isOver = false;
    const transformMat = this.ctx.getTransform();
    const leftC  = (transformMat.a * this.x) + transformMat.e; // eslint-disable-line no-multi-spaces,max-len
    const rightC = (transformMat.a * (this.x + this.width)) + transformMat.e; // eslint-disable-line no-multi-spaces,max-len
    const uppC   = (transformMat.d * this.y) + transformMat.f; // eslint-disable-line no-multi-spaces,max-len
    const lowC   = (transformMat.d * (this.y + this.height)) + transformMat.f; // eslint-disable-line no-multi-spaces,max-len
    if ((lastX < rightC) && (leftC < lastX)
        && (lastY < lowC) && (uppC < lastY) ) { // eslint-disable-line space-in-parens
      isOver = true;
    }
    return isOver;
  }

  mouseCursorOnObjectForHover(lastX, lastY) {
    // console.log('stuff');
    // console.log(this.x);
    // console.log(this.y);
    // console.log(this.height);
    // console.log(this.width);
    const transformMat = this.ctx.getTransform();
    const leftC  = (transformMat.a * this.x) + transformMat.e; // eslint-disable-line no-multi-spaces,max-len
    const rightC = (transformMat.a * (this.x + this.width)) + transformMat.e; // eslint-disable-line no-multi-spaces,max-len
    const uppC   = (transformMat.d * this.y) + transformMat.f; // eslint-disable-line no-multi-spaces,max-len
    const lowC   = (transformMat.d * (this.y + this.height)) + transformMat.f; // eslint-disable-line no-multi-spaces,max-len
    if ((lastX < rightC) && (leftC < lastX)
        && (lastY < lowC) && (uppC < lastY) ) { // eslint-disable-line space-in-parens
      try {
        this.onHover.fun();
      } catch {
        window.mingly.log('noHover');
      }
    } else {
      try {
        this.onHover.nofun();
      } catch {
        window.mingly.log('nonoHover');
      }
    }
  }

  mouseCursorOnObjectForClick(lastX, lastY) {
    const transformMat = this.ctx.getTransform();
    const leftC  = (transformMat.a * this.x) + transformMat.e; // eslint-disable-line no-multi-spaces,max-len
    const rightC = (transformMat.a * (this.x + this.width)) + transformMat.e; // eslint-disable-line no-multi-spaces,max-len
    const uppC   = (transformMat.d * this.y) + transformMat.f; // eslint-disable-line no-multi-spaces,max-len
    const lowC   = (transformMat.d * (this.y + this.height)) + transformMat.f; // eslint-disable-line no-multi-spaces,max-len
    if ((lastX < rightC) && (leftC < lastX)
        && (lastY < lowC) && (uppC < lastY) ) { // eslint-disable-line space-in-parens
      if (this.onClick) {
        this.onClick();
      }
    }
  }

  clear() {
    const adjust = 10;
    this.ctx.clearRect(this.x - adjust, this.y - adjust,
      this.width + 2 * adjust, this.height + 2 * adjust);
    return true;
  }
}
