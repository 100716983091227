<template>
    <vue-drag-resize
                  :x="this.left" :y="this.top"
                  :w="this.width" :h="this.height"
                  :minw="300" :minh="300"
                  :sticks="['tl','tr','br','bl']"
                  :z="999"
                  :aspectRatio="true"
                  v-on:resizing="resize"
                  v-on:deactivated="onDeactivated"
                  v-on:activated="onActivated">
      <v-card dark
              :height="this.height"
              :width="this.width">
        <CloseButton :method="separateScreen" class="close_button" />
        <v-card-actions
          v-if="this.participants.getByUserId(this.user.userID).videovar"
          class="justify-center padding">
          <video id="vidEP" autoplay muted ref="participantCurrentVideo" />
        </v-card-actions>
        <div v-else class="padding">
          <img class="center-image"  ref="participantPic" />
        </div>
        <div v-if="this.height <= 620">
          <div class="text-center" >
            {{ this.participants.getByUserId(this.user.userID).username }}
          </div>
        </div>
        <div v-else>
          <div class="text-center" style="font-size: 3em;">
            {{ this.participants.getByUserId(this.user.userID).username }}
          </div>
        </div>
      </v-card>
    </vue-drag-resize>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import VueDragResize from 'vue-drag-resize';
import CloseButton from './CloseButton.vue';

export default {
  name: 'ParticipantVideo',
  components: {
    CloseButton, VueDragResize,
  },
  data() {
    return {
      pictureUse: false,
      isScreenActive: false,
      isHeld: false,
      width: 350,
      height: 300,
      top: 300,
      left: 300,
    };
  },
  computed: {
    ...mapGetters('$_userdata', ['username', 'userID', 'profilePicture', 'videovar', 'hoover', 'participants']),
    ...mapGetters('$_canvas', ['isShowParticipantVideoActive', 'user']),
  },
  mounted() {
    this.getVideo();
  },
  updated() {
    this.getVideo();
  },
  methods: {
    ...mapActions('$_canvas', [
      'commitSetShowParticipantVideoActive',
    ]),
    resize(newRect) {
      this.height = newRect.height;
      this.width = newRect.width;
      this.top = newRect.top;
      this.left = newRect.left;
    },
    onDeactivated() {
      this.isScreenActive = false;
    },
    onActivated() {
      const that = this;
      that.isScreenActive = true;
      window.mingly.board.move_keyboard = false;
      setTimeout(() => {
        that.isScreenActive = false;
      }, 2000);
    },
    fullScreen() {
      this.top = 0; // does not really work
      this.left = 0; // does not really work
      this.width = window.innerWidth;
      this.height = window.innerHeight;
    },
    checkActive() {
      window.mingly.board.move_keyboard = false;
    },
    separateScreen() {
      this.commitSetShowParticipantVideoActive({
        userID: this.user.userID,
        videovar: window.mingly.board.participants[this.user.userID].videovar,
      });
    },
    getVideo() {
      const id = this.user.userID;
      if (window.mingly.board.participants[id].videovar === 1) {
        const vid = window.mingly.board.participants[id].videoElement.srcObject;
        this.$refs.participantCurrentVideo.srcObject = vid;
      } else {
        this.$refs.participantPic.src = window.mingly.board.participants[id].pic.src;
      }
    },
  },
};
</script>

<style scoped>
  .center-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }
  .padding {
    padding-top: 3.5vh;
    padding-right: 2vh;
    padding-left: 2vh;
  }
  #vidEP {
    width: 100%;
    height: 100%;
  }
  .close_button {
    position: absolute;
    right: 0.2vh;
    top: 0.2vh;
  }
  .fullscreen_button {
    position: absolute;
    left: 0.2vh;
    top: 0.2vh;
  }
</style>
