<template>
  <div>
    <vue-drag-resize :isResizable="false"
                     :w="this.width" :h="this.height"
                     :x="this.left" :y="this.top">
      <v-card dark :height="this.height" :width="this.width">
        <v-row>
          <v-col>
            <h2 class="pa-3 header_scrollbar" style="text-align: center;">
              {{ this.pollData.question }}
            </h2>
          </v-col>
        </v-row>
        <div class="scrollbar">
          <v-list-item v-for="(entry, index) in this.pollData.alternatives" :key="index">
            <v-row>
              <v-col cols="4">
                {{ entry.data }}
              </v-col>
              <v-col cols="7">
                <v-progress-linear rounded
                                   style="top: 50%;"
                                   height="10"
                                   color="red"
                                   :value="(pollResults[index] * 100) / totalAnswers" />
              </v-col>
              <v-col>
                <div style="top: 48%; position: absolute;">
                  {{ pollResults[index] }}
                </div>
              </v-col>
            </v-row>
          </v-list-item>
        </div>
        <v-row>
          <v-col class="" style="text-align: center;">
            <v-btn v-on:click="shareResults()">Share Result</v-btn>
          </v-col>
          <v-col class="" style="text-align: center;">
            <v-btn v-on:click="closeThePoll()">Close Poll</v-btn>
          </v-col>
          <v-col class="text-center">
            <v-btn v-on:click="toCSV()" >Download result</v-btn>
          </v-col>
        </v-row>
      </v-card>
    </vue-drag-resize>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import VueDragResize from 'vue-drag-resize';
import { closePoll, sendPollStats } from '../../../../assets/js/webrtc';

export default {
  name: 'StatCreatorPoll',
  components: {
    VueDragResize,
  },
  data: () => ({
    top: 0.15 * window.innerHeight,
    left: 0.02 * window.innerWidth,
    width: 0.3 * window.innerWidth,
    height: 0.4 * window.innerHeight,
  }),
  computed: {
    ...mapGetters('$_userdata', ['pollData', 'pollResults']),
    totalAnswers() {
      let result = 0;
      for (let i = 0; i < this.pollResults.length; i += 1) {
        result += this.pollResults[i];
      }
      return result;
    },
  },
  methods: {
    ...mapActions('$_userdata', [
      'commitSetPoll',
      'commitPollResults',
    ]),
    ...mapActions('$_canvas', [
      'commitSetStatCreatorPollActive',
    ]),
    shareResults() {
      // this.checkedAlternatives
      sendPollStats(window.mingly.pollResults);
      console.log('Sharing results');
    },
    closeThePoll() {
      // reset pollData
      const pollDat = {
        question: '',
        alternatives: [],
        creatorId: '',
        isAnonymous: false,
        isActive: false,
      };
      this.commitSetPoll(pollDat);
      this.commitPollResults([]);
      // Send to everyone that it is closed
      closePoll();
      // close this page
      this.commitSetStatCreatorPollActive();
      console.log('Closing Poll');
    },
    toCSV() {
      let csv = '';

      // add question
      csv += this.pollData.question;
      csv += '\n';

      // add alternatives
      this.pollData.alternatives.forEach((entry) => {
        csv += entry.data;
        csv += ';';
      });
      csv += '\n';

      // add the data
      for (let i = 0; i < this.pollResults.length; i += 1) {
        csv += this.pollResults[i];
        csv += ';';
      }
      const anchor = document.createElement('a');
      anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
      anchor.target = '_blank';
      anchor.download = 'result.csv';
      anchor.click();
    },
  },
};
</script>

<style scoped>
  .header_scrollbar {
    max-height: 10vh;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .scrollbar {
    height: 23vh;
    overflow-y: auto;
    overflow-x: hidden;
  }
  ::-webkit-scrollbar {
    width: 3px;
  }
  ::-webkit-scrollbar-thumb {
    background: #b9b9b9;
  }
</style>
