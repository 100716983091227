const isChatBoxActive = (state) => state.isChatBoxActive;
const isParticipantActive = (state) => state.isParticipantActive;
const isSettingMenuActive = (state) => state.isSettingMenuActive;
const isCanvasViewActive = (state) => state.isCanvasViewActive;
const isEnterVideoActive = (state) => state.isEnterVideoActive;
const isVideoShareActive = (state) => state.isVideoShareActive;
const isScreenShareActive = (state) => state.isScreenShareActive;
const isMuteActive = (state) => state.isMuteActive;
const isDeafenActive = (state) => state.isDeafenActive;
const isSleepActive = (state) => state.isSleepActive;
const isShareMediaActive = (state) => state.isShareMediaActive;
const isShowParticipantVideoActive = (state) => state.isShowParticipantVideoActive;
const isBroadCastActive = (state) => state.isBroadCastActive;
const isPollActive = (state) => state.isPollActive;
const isGamesActive = (state) => state.isGamesActive;
const isAnswerPollActive = (state) => state.isAnswerPollActive;
const isStatCreatorPollActive = (state) => state.isStatCreatorPollActive;
const isStatDisplayPollActive = (state) => state.isStatDisplayPollActive;
const isGameInviteActive = (state) => state.isGameInviteActive;
const isGameInvitationActive = (state) => state.isGameInvitationActive;
const isScoreboardGTCActive = (state) => state.isScoreboardGTCActive;
const isSideCanvasActive = (state) => state.isSideCanvasActive;
const game = (state) => state.game;
const user = (state) => state.user;
const userBroadCast = (state) => state.userBroadCast;
const gameData = (state) => state.gameData;
const isBroadcastMenuActive = (state) => state.isBroadcastMenuActive;
const isBroadcastMinglyModeActive = (state) => state.isBroadcastMinglyModeActive;
const isChatBehaviorActive = (state) => state.isChatBehaviorActive;
const isChatDraggableActive = (state) => state.isChatDraggableActive;
const isFocusViewActive = (state) => state.isFocusViewActive;
const isHostMenuActive = (state) => state.isHostMenuActive;
const isHostSettingsActive = (state) => state.isHostSettingsActive;
const isLockPositionActive = (state) => state.isLockPositionActive;
const isMuteAllActive = (state) => state.isMuteAllActive;
const isNoVideoAllActive = (state) => state.isNoVideoAllActive;
const isBroadCastMeActive = (state) => state.isBroadCastMeActive;
const isFollowMyViewActive = (state) => state.isFollowMyViewActive;
const isNoHostMenuActive = (state) => state.isNoHostMenuActive;
const isRaiseHandActive = (state) => state.isRaiseHandActive;
const raisedHands = (state) => state.raisedHands;
const isRaisedHandOverviewActive = (state) => state.isRaisedHandOverviewActive;

export default {
  isChatDraggableActive,
  isChatBehaviorActive,
  isChatBoxActive,
  isParticipantActive,
  isSettingMenuActive,
  isCanvasViewActive,
  isEnterVideoActive,
  isVideoShareActive,
  isScreenShareActive,
  isRaisedHandOverviewActive,
  isMuteActive,
  isDeafenActive,
  isShareMediaActive,
  isShowParticipantVideoActive,
  isBroadCastActive,
  user,
  userBroadCast,
  game,
  gameData,
  isPollActive,
  isAnswerPollActive,
  isStatCreatorPollActive,
  isStatDisplayPollActive,
  isGamesActive,
  isGameInviteActive,
  isGameInvitationActive,
  isScoreboardGTCActive,
  isSideCanvasActive,
  isSleepActive,
  isBroadcastMenuActive,
  isBroadcastMinglyModeActive,
  isFocusViewActive,
  isHostMenuActive,
  isHostSettingsActive,
  isLockPositionActive,
  isMuteAllActive,
  isNoVideoAllActive,
  isBroadCastMeActive,
  isFollowMyViewActive,
  isNoHostMenuActive,
  isRaiseHandActive,
  raisedHands,
};
