<template>
  <div class="background">
    <v-row style="padding-top: 10vh;">
      <v-col cols="1">
      </v-col>
      <v-col cols="4">
        <TextBox text="“During the early days of COVID, I realized that the current
        video conferencing solutions did not work well in social settings.
        Only one can speak at a time,
        forcing everyone in the room to participate in the same conversation.
        This is very different from meeting up in-person, where multiple conversations are
        happening simultaneously.
        When I started teaching online,
        I also realized that it was difficult to create
        bonds between students for exactly the same reason.
        Setting up break out rooms was cumbersome, and the “walls”
        between the rooms created artificial barriers that made quick
        interactions almost impossible” (Christian Heyerdahl-Larsen, co-founder Mingly)" />
      </v-col>
      <v-col cols="1">
      </v-col>
      <v-col cols="1">
      </v-col>
      <v-col cols="4">
        <video autoplay muted loop width="100%" height="100%"
               src="https://www.dropbox.com/s/88atrlgnk89yh06/SharingOfficeV2.mp4?raw=1" />
      </v-col>
      <v-col cols="1">
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="1">
      </v-col>
      <v-col cols="4">
        <video autoplay muted loop width="100%" height="100%"
               src="https://www.dropbox.com/s/lgdjs10dbdist35/TeachingIllustration.mp4?raw=1" />
      </v-col>
      <v-col cols="1">
      </v-col>
      <v-col cols="1">
      </v-col>
      <v-col cols="4">
        <TextBox text="Mingly is a browser based video chat application
         that has the spatial dimension. You can “move” around in a
          room, and you hear people close to you better. In this way,
          multiple conversations can happen simultaneously in the same room.
          Mingly tears down the walls between the breakout rooms, creating
          a much more organic way of letting people choose who they want
          to talk with at any given moment." />
      </v-col>
      <v-col cols="1">
      </v-col>
    </v-row>
    <v-row style="padding-top: 10vh;">
      <v-col cols="1">
      </v-col>
      <v-col cols="4">
        <TextBox text="When developing Mingly, the focus has been
         on making the user interface as intuitive as possible.
         If you have used google maps, the feel in Mingly should
         be familiar. You can easily get an overview of the room
         by zooming out, quickly moving around by click-and-drag,
         and easily focus the screen just as you like it." />
      </v-col>
      <v-col cols="1">
      </v-col>
      <v-col cols="1">
      </v-col>
      <v-col cols="4">
        <video autoplay muted loop width="100%" height="100%"
               src="https://www.dropbox.com/s/dhgdswewmkbblbk/DragAndDrop.mp4?raw=1" />
      </v-col>
      <v-col cols="1">
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="1">
      </v-col>
      <v-col cols="4">
        <video autoplay muted loop width="100%" height="100%" class="rounded-corners"
               src="https://www.dropbox.com/s/vqrg5ml2qkknpdu/ZoomToGif.mp4?raw=1" />
      </v-col>
      <v-col cols="1">
      </v-col>
      <v-col cols="1">
      </v-col>
      <v-col cols="4">
        <TextBox text="Mingly provides a space that is highly customizable.
        You can easily change the feel or purpose of the room by changing the background,
         adding media such as videos, audio or pictures and share your screen. " />
      </v-col>
      <v-col cols="1">
      </v-col>
    </v-row>
  </div>
</template>

<script>
import TextBox from './_component/TextBox.vue';

export default {
  name: 'index',
  components: { TextBox },
};
</script>

<style scoped>
  .rounded-corners {
    border-radius: 25px;
  }
  .background {
    /* background: url('https://www.dropbox.com/s/fk0u89rhzxfkcmi/IMG_7328.jpeg?raw=1'); */
    background: url('https://www.dropbox.com/s/eeaq8lkpwlvhaio/IMG_2775.jpg?raw=1');
    background-size: cover;
  }
</style>
