<template>
  <div class="text-center">
    <v-row>
      <v-col>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-if="!isHostMenuActive"
                   fab dark v-on="on" v-bind="attrs" v-on:click="makeHostMenuActive()">
              <v-icon>
                mdi-account-hard-hat
              </v-icon>
            </v-btn>
             <v-btn v-else
                   fab dark v-on="on" v-bind="attrs" v-on:click="makeHostMenuActive()">
              <v-icon color="orange lighten-1">
                mdi-account-hard-hat
              </v-icon>
            </v-btn>
          </template>
          <span>Host menu</span>
        </v-tooltip>
      </v-col>
       <v-col v-if="this.isHostMenuActive">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-if="!isHostSettingsActive"
                   fab dark v-on="on" v-bind="attrs" v-on:click="hostSettings()">
              <v-icon>
                mdi-menu
              </v-icon>
            </v-btn>
            <v-btn v-else
                   fab dark v-on="on" v-bind="attrs" v-on:click="hostSettings()">
              <v-icon color="orange lighten-1">
                mdi-menu
              </v-icon>
            </v-btn>
          </template>
          <span>Host settings</span>
        </v-tooltip>
      </v-col>
      <v-col v-if="this.isHostMenuActive">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-if="!isLockPositionActive"
                  fab dark v-on="on" v-bind="attrs" v-on:click="placeAndLock()">
              <v-icon>
                  mdi-account-switch
              </v-icon>
            </v-btn>
            <v-btn v-else
                   fab dark v-on="on" v-bind="attrs" v-on:click="placeAndLock()">
              <v-icon color="orange lighten-1">
                  mdi-account-switch
              </v-icon>
            </v-btn>
          </template>
          <span>Place and lock participants to position</span>
        </v-tooltip>
      </v-col>
      <v-col v-if="this.isHostMenuActive">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-if="!isMuteAllActive"
                  fab dark v-on="on" v-bind="attrs" v-on:click="muteAll()">
              <v-icon>
                mdi-volume-mute
              </v-icon>
            </v-btn>
            <v-btn v-else
                  fab dark v-on="on" v-bind="attrs" v-on:click="muteAll()">
              <v-icon color="orange lighten-1">
                mdi-volume-mute
              </v-icon>
            </v-btn>
          </template>
          <span>Mute all</span>
        </v-tooltip>
      </v-col>
      <v-col v-if="this.isHostMenuActive">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-if="!isNoVideoAllActive"
                  fab dark v-on="on" v-bind="attrs" v-on:click="turnOffVideoAll()">
              <v-icon>
                  mdi-video-off
              </v-icon>
            </v-btn>
            <v-btn v-else
                  fab dark v-on="on" v-bind="attrs" v-on:click="turnOffVideoAll()">
              <v-icon color="orange lighten-1">
                  mdi-video-off
              </v-icon>
            </v-btn>
          </template>
          <span>Turn everyones video off</span>
        </v-tooltip>
      </v-col>
       <v-col v-if="this.isHostMenuActive">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-if="!isBroadCastMeActive"
                  fab dark v-on="on" v-bind="attrs" v-on:click="broadCast()">
              <v-icon>
                  mdi-account-box-outline
              </v-icon>
            </v-btn>
            <v-btn v-else
                  fab dark v-on="on" v-bind="attrs" v-on:click="broadCast()">
              <v-icon color="orange lighten-1">
                  mdi-account-box-outline
              </v-icon>
            </v-btn>
          </template>
          <span>Broadcast your video and audio</span>
        </v-tooltip>
      </v-col>
      <v-col v-if="this.isHostMenuActive">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-if="!isFollowMyViewActive"
                  fab dark v-on="on" v-bind="attrs" v-on:click="followMyView()">
              <v-icon>
                mdi-glasses
              </v-icon>
            </v-btn>
             <v-btn v-else
                    fab dark v-on="on" v-bind="attrs" v-on:click="followMyView()">
              <v-icon color="orange lighten-1">
                mdi-glasses
              </v-icon>
            </v-btn>
          </template>
          <span>Follow my view</span>
        </v-tooltip>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// To do here:
// Create clicked on to change color on buttons
// when they are activated
import { mapActions, mapGetters } from 'vuex';
import {
  // eslint-disable-next-line no-unused-vars
  allFollowMyView,
  localBroadCast,
  hostMute,
  hostNoVideo,
  hostPlaceAndLockParticipants,
} from '../../../assets/js/webrtc';

export default {
  name: 'CanvasHostMenu',
  computed: {
    ...mapGetters('$_canvas', [
      'isHostMenuActive',
      'isHostSettingsActive',
      'isLockPositionActive',
      'isMuteAllActive',
      'isNoVideoAllActive',
      'isBroadCastMeActive',
      'isFollowMyViewActive',
    ]),
    ...mapGetters('$_userdata', [
      'host',
    ]),
  },
  methods: {
    ...mapActions('$_canvas', [
      'commitSetHostMenueActive',
      'commitSetHostSettingsActive',
      'commitSetLockPositionActive',
      'commitSetMuteAllActive',
      'commitSetNoVideoAllActive',
      'commitSetBroadCastMeActive',
      'commitSetFollowMyViewActive',
    ]),
    // ...mapActions('$_userdata', [
    //   'commitSetChatBox',
    //   'commitSetTotalMessageCount',
    // ]),
    makeHostMenuActive() {
      this.commitSetHostMenueActive();
    },
    followMyView() {
      this.commitSetFollowMyViewActive();
      allFollowMyView();
    },
    muteAll() {
      this.commitSetMuteAllActive();
      if (this.isMuteAllActive) {
        hostMute('mute');
      } else {
        hostMute('on');
      }
      // need to disable the possibility of turn on audio
    },
    turnOffVideoAll() {
      this.commitSetNoVideoAllActive();
      if (this.isNoVideoAllActive) {
        hostNoVideo('off');
      } else {
        hostNoVideo('on');
      }
      // need to disable the possibility of turn on video
    },
    broadCast() {
      // should trigger reply menu
      // need to think about the most sensible way of doing this
      this.commitSetBroadCastMeActive();
      localBroadCast();
    },
    placeAndLock() {
      this.commitSetLockPositionActive();
      hostPlaceAndLockParticipants();
      // need to disable the possibility of moving
    },
    hostSettings() {
      // spin up a new menu
      this.commitSetHostSettingsActive();
    },
  },
};
</script>

<style scoped>
  ::-webkit-scrollbar {
    width: 5px;
    padding-left: 2px;
  }
  ::-webkit-scrollbar-thumb {
    background: #b9b9b9;
  }
</style>
