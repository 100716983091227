<template>
  <div>
    <v-card class="mx-auto login pa-4" max-width="500">
      <h1 class="text-center pa-2">Sign in</h1>
      <v-card-actions>
        <v-text-field v-model="username"
                      label="Room"
                      required
                      filled
                      rounded
                      hide-details
                      prepend-inner-icon="mdi-account-circle" />
      </v-card-actions>
      <v-card-actions>
        <v-text-field v-model="password"
                      label="Password"
                      type="password"
                      required
                      filled
                      rounded
                      hide-details
                      prepend-inner-icon="mdi-lock"
                      v-on:keydown.enter.prevent="login" />
      </v-card-actions>
      <!--v-row>
        <v-col>
          <v-card-actions>
            <v-checkbox label="Remember me" class="ma-0" />
          </v-card-actions>
        </v-col>
        <v-col>
          <v-card-actions class="justify-end">
            <router-link :to="resetPassword">
              Forgot password?
            </router-link>
          </v-card-actions>
        </v-col>
      </v-row-->
      <v-card-actions class="justify-center">
        <v-btn dark elevation="8"
               large class="btn_width"
               v-on:click="login">Login</v-btn>
      </v-card-actions>
      <!--v-card-actions class="justify-center">
        <router-link :to="createNewAccount">
          Don't have a user? Create a new account
        </router-link>
      </v-card-actions>
      <v-card-actions class="justify-center guest_btn_pos">
        <v-btn dark elevation="8"
               large class="btn_width"
               v-on:click="createGuestName">Join as guest</v-btn>
      </v-card-actions-->
      <div v-if="isError" style="text-align: center;">
        <b style="color: red;">{{ errorMessage }}</b>
      </div>
    </v-card>
  </div>
</template>

<script>
import axios from 'axios';
import { mapActions } from 'vuex';

export default {
  name: 'LoginBox',
  data() {
    return {
      username: '',
      password: '',
      isError: false,
      errorMessage: '',
    };
  },
  computed: {
    resetPassword() {
      return '';
    },
    createNewAccount() {
      return '';
    },
  },
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('room') && urlParams.get('password')) {
      this.username = urlParams.get('room');
      this.password = urlParams.get('password');
      this.login();
    }
  },
  methods: {
    ...mapActions('$_userdata', ['commitSetRoom', 'commitMenuItems', 'commitSetBackgroundImage']),
    login() {
      if (this.username === '' && this.password === '') {
        this.isError = true;
        this.errorMessage = 'You need to enter the room name and the password!';
        return;
      }

      if (this.username === '') {
        this.isError = true;
        this.errorMessage = 'You need to enter the room name!';
        return;
      }

      if (this.password === '') {
        this.isError = true;
        this.errorMessage = 'You need to enter the password!';
        return;
      }

      axios.post(`${this.$API_URL}/user/signin`, { identity: this.username, password: this.password })
        .then((response) => {
          if (response.data.error) {
            this.isError = true;
            this.errorMessage = response.data.general;
          } else {
            // eslint-disable-next-line no-lonely-if
            if (response.data.access_token) {
              // window.alert(response.data.access_token);
              localStorage.setItem('access_token', response.data.access_token);
              this.commitSetRoom(this.username);
              // these should of course be done less ad hoc
              // if (this.username === 'F300Spring2022-8243') {
              //   window.mingly.wsurl = 'wss://soup-us-east-2-01.mingly.io:8443';
              // }
              // if (this.username === 'F300Spring2022-10523') {
              //   window.mingly.wsurl = 'wss://soup-us-east-2-02.mingly.io:8443';
              // }
              if (this.username === 'SafetykleenRoom') {
                const picURL = 'https://www.dropbox.com/s/96veu7bbs4d6ab7/Key%20visual%20with%20brand%20in%20corner.jpg?raw=1';
                this.commitSetBackgroundImage(picURL);
              } else if (this.username === 'HPC_dome') {
                const picURL = 'https://www.dropbox.com/s/upmvxmqvuv8s93k/photo_2022-09-20%2014.27.58.jpeg?raw=1';
                this.commitSetBackgroundImage(picURL);
              } else if (this.username === 'ChristiansRoom') {
                const picURL = 'https://www.dropbox.com/s/2jov5w0pa0se4uu/CHLBackground.jpg?raw=1';
                this.commitSetBackgroundImage(picURL);
              }
              this.$router.push({ name: 'EnterName' });
            } else {
              // eslint-disable-next-line prefer-template
              console.log('login(): unhandled response data: ' + response.data);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    createGuestName() {
      this.$router.push({ name: 'EnterName' });
    },
  },
};
</script>

<style scoped>
.login {
  margin-top: 24vh;
}
.guest_btn_pos {
  padding-bottom: 1em;
  padding-top: 2em;
}
.btn_width {
  width: 80%;
}
</style>
