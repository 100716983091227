<template>
  <div>
    <v-row v-if="id !== userID">
      <v-col cols="9" >
        <div class="card_bubble_others">
          <p class="ma-0" v-on:mouseover="draggOff()" v-on:mouseleave="draggOn()">
            {{ data }}
          </p>
          <div class="username_others">
            {{ username.slice(0, 10) }}
          </div>
        </div>
      </v-col>
      <v-col cols="3"></v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="3">
      </v-col>
      <v-col cols="9" class="text-right" >
        <div class="card_bubble_own">
          <p v-on:mouseover="draggOff()" v-on:mouseleave="draggOn()">
            {{ data }}
          </p>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'MessageBubble',
  props: {
    username: String,
    id: String,
    data: String,
  },
  computed: {
    ...mapGetters('$_userdata', ['userID']),
  },
  methods: {
    ...mapActions('$_canvas', [
      'commitSetChatBehaviorActive',
      'commitSetChatDraggableActive',
    ]),
    draggOff() {
      this.commitSetChatBehaviorActive(true);
      this.commitSetChatDraggableActive(false);
    },
    draggOn() {
      this.commitSetChatBehaviorActive(false);
      this.commitSetChatDraggableActive(true);
    },
  },
};
</script>

<style scoped>
  .username_others {
    font-size: small;
    text-align: right;
  }
 .card_bubble_others {
   border-radius: 0 15px 15px 15px;
   background: #4a4d49;
   color: white;
   margin: 0 0 0 1vh;
   padding: 5px 15px 0 15px;
   display: inline-block;
   min-width: 10vh;
 }
 .card_bubble_own {
   border-radius: 15px 0 15px 15px;
   background: #cccccc;
   color: black;
   margin: 0 1vh 0 0;
   padding: 5px 15px 0 15px;
   display: inline-block;
   min-width: 5vh;
   text-align: left;
 }
</style>
