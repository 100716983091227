<template>
  <div>
    <v-card class="mx-auto pa-4 picture_card" max-width="600">
      <v-img class="profile_image mx-auto"
             :src="require('../../../assets/temporary_profile_img.jpg')" />
      <v-card-actions class="justify-center padding_bot padding">
        <v-btn dark elevation="8" large class="btn_width" v-on:click="retakePhoto">
          Retake Photo
        </v-btn>
      </v-card-actions>
      <v-card-actions class="justify-center padding_bot">
        <v-btn dark elevation="8" large class="btn_width" v-on:click="selectSound">
          Continue
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'PictureCard',
  methods: {
    retakePhoto() {
      this.$router.push({ name: 'SelectCamera' });
    },
    selectSound() {
      this.$router.push({ name: 'SelectSpeaker' });
    },
  },
};
</script>

<style scoped>
.picture_card {
  margin-top: 18vh;
}
.profile_image {
  border-radius: 50% !important;
  width: 30vh;
  height: 30vh;
}
.btn_width {
  width: 60%;
}
.padding {
  padding-top: 2vh;
}
.padding_bot {
  padding-bottom: 2vh;
}
</style>
