<template>
    <vue-drag-resize
                  :x="this.left" :y="this.top"
                  :w="this.width" :h="this.height"
                  :minw="600" :minh="600"
                  :sticks="['tl','tr','br','bl']"
                  :z="999"
                  :aspectRatio="true"
                  v-on:resizing="resize"
                  v-on:deactivated="onDeactivated"
                  v-on:activated="onActivated">
      <v-card dark
             :height="this.height"
              :width="this.width">
        <v-card-actions
          class="justify-center padding">
          <div class="placeholder">
            <video id="vidEP" autoplay ref="participantCurrentVideo"/>
          </div>
        </v-card-actions>
       <!-- <v-card-actions class="justify-center padding_bot">
        <v-btn v-if="!this.isFullScreen" dark large elevation="8" v-on:click="fullScreen">
          Full Screen
        </v-btn>
        <v-btn v-else dark elevation="8" large v-on:click="ResetFullScreen">
          Exit full screen
        </v-btn>
      </v-card-actions> -->
      </v-card>
    </vue-drag-resize>
</template>

<script>
import { mapGetters } from 'vuex';
import VueDragResize from 'vue-drag-resize';

export default {
  name: 'BroadCast',
  components: {
    VueDragResize,
  },
  data() {
    return {
      pictureUse: false,
      isScreenActive: false,
      isHeld: false,
      isFullScreen: false,
      width: 600,
      height: 600,
      top: window.innerHeight / 2 - 600 / 2,
      left: window.innerWidth / 2 - 600 / 2,
    };
  },
  computed: {
    ...mapGetters('$_userdata', ['username', 'userID', 'profilePicture', 'videovar', 'hoover', 'participants']),
    ...mapGetters('$_canvas', ['isBroadCastActive', 'userBroadCast']),
  },
  mounted() {
    this.getVideo();
  },
  updated() {
    this.getVideo();
  },
  methods: {
    // ...mapActions('$_canvas', [
    //   'commitSetShowParticipantVideoActive',
    // ]),
    resize(newRect) {
      this.height = newRect.height;
      this.width = newRect.width;
      this.top = newRect.top;
      this.left = newRect.left;
    },
    onDeactivated() {
      this.isScreenActive = false;
    },
    onActivated() {
      const that = this;
      that.isScreenActive = true;
      window.mingly.board.move_keyboard = false;
      setTimeout(() => {
        that.isScreenActive = false;
      }, 2000);
    },
    fullScreen() {
      this.top = 0; // does not really work
      this.left = 0; // does not really work
      const vidHeight = this.$refs.participantCurrentVideo.height;
      const vidWidth = this.$refs.participantCurrentVideo.width;
      const vidRatio = vidHeight / vidWidth;
      const innerScreenRatio = window.innerHeight / window.innerWidth;
      if (vidRatio < innerScreenRatio) {
        this.width = window.innerWidth;
        this.height = window.innerWidth * vidRatio;
      } else {
        this.height = window.innerHeight;
        this.width = window.innerHeight / vidRatio;
      }
      this.isFullScreen = true;
    },
    ResetFullScreen() {
      this.width = 600;
      this.height = 600;
      this.top = window.innerHeight / 2 - 600 / 2;
      this.left = window.innerWidth / 2 - 600 / 2;
      this.isFullScreen = false;
    },
    checkActive() {
      window.mingly.board.move_keyboard = false;
    },
    // separateScreen() {
    //   this.commitSetShowParticipantVideoActive({
    //     userID: this.user.userID,
    //     videovar: window.mingly.board.participants[this.user.userID].videovar,
    //   });
    // },
    getVideo() {
      const id = this.userBroadCast.userID;
      let vid;
      if (this.userBroadCast.isMe) {
        // it is me broadcasting
        console.log('isMe is true');
        vid = window.mingly.board.me.msStream;
        this.$refs.participantCurrentVideo.srcObject = vid;
        this.$refs.participantCurrentVideo.volume = 0;
      } else {
        vid = window.mingly.board.participants[id].msStream;
        this.$refs.participantCurrentVideo.srcObject = vid;
      }
      // this.$refs.participantCurrentVideo.volume = 1;
    },
  },
};
</script>

<style scoped>
  .center-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }
  .padding {
    padding-top: 3.5vh;
    padding-right: 2vh;
    padding-left: 2vh;
  }
  .placeholder {
    width: 100%;
    height: auto;
  }
  #vidEP {
    width: 100%;
    height: 100%;
  }
  .close_button {
    position: absolute;
    right: 0.2vh;
    top: 0.2vh;
  }
  .fullscreen_button {
    position: absolute;
    left: 0.2vh;
    top: 0.2vh;
  }
</style>
