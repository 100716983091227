export const SET_USERNAME = 'SET_USERNAME';
export const SET_USER_ID = 'SET_USER_ID';
export const SET_HOST = 'SET_HOST';
export const SET_ROOM = 'SET_ROOM';
export const SET_VIDEOVAR = 'SET_VIDEOVAR';
export const SET_HOOVER = 'SET_SET_HOOVER';
export const ADD_PARTICIPANT = 'ADD_PARTICIPANT';
export const REMOVE_PARTICIPANT = 'REMOVE_PARTICIPANT';
export const SET_PROFILE_PICTURE = 'SET_PROFILE_PICTURE';
export const ADD_MESSAGE = 'ADD_MESSAGE';
export const SET_CHAT_ACTIVE = 'SET_CHAT_ACTIVE';
export const SET_NEWNAME_PARTICIPANT = 'SET_NEWNAME_PARTICIPANT';
export const SET_NEWPROFILEPIC_PARTICIPANT = 'SET_NEWPROFILEPIC_PARTICIPANT';
export const SET_CHAT_BOX_ACTIVE = 'SET_CHAT_BOX_ACTIVE';
export const SET_TOTAL_MESSAGE_COUNT = 'SET_TOTAL_MESSAGE_COUNT';
export const SET_POLL = 'SET_POLL';
export const SET_POLL_RESULTS = 'SET_POLL_RESULTS';
export const SET_MENU_ITEMS = 'SET_MENU_ITEMS';
export const SET_BACKGROUND_IMAGE = 'SET_BACKGROUND_IMAGE';

export default {
  [SET_CHAT_BOX_ACTIVE](state) {
    state.chatActive = !state.chatActive;
  },
  [SET_USERNAME](state, username) {
    state.username = username;
  },
  [SET_USER_ID](state, userID) {
    state.userID = userID;
  },
  [SET_HOST](state) {
    state.host = !state.host;
  },
  [SET_ROOM](state, room) {
    state.room = room;
  },
  [SET_PROFILE_PICTURE](state, profilePicture) {
    state.profilePicture = profilePicture;
  },
  [SET_VIDEOVAR](state, data) {
    state.participants.updateVideovar(data);
  },
  [SET_HOOVER](state, data) {
    state.participants.updateHoover(data);
  },
  [ADD_PARTICIPANT](state, data) {
    state.participants.add(data);

    // Also add entry for chat
    const entry = {
      chatID: data.userID,
      isActive: false,
      unreadMessages: 0,
      payload: [],
    };
    state.messages.push(entry);
  },
  [SET_NEWNAME_PARTICIPANT](state, data) {
    state.participants.getByUserId(data.id).username = data.name;
  },
  [SET_NEWPROFILEPIC_PARTICIPANT](state, data) {
    state.participants.updateProfilePic(data);
  },
  [REMOVE_PARTICIPANT](state, id) {
    state.participants.removeByUserId(id);
  },
  [ADD_MESSAGE](state, payload) {
    // initialize new array if it is a new chat
    let isNewChat = true;
    for (let i = 0; i < state.messages.length; i += 1) {
      if (state.messages[i].chatID === payload.chatID) {
        isNewChat = false;
      }
    }
    if (isNewChat) {
      state.messages.push({
        chatID: payload.chatID,
        unreadMessages: 0,
        isActive: true,
        payload: [],
      });
    }

    // add the chat data
    for (let i = 0; i < state.messages.length; i += 1) {
      if (state.messages[i].chatID === payload.chatID) {
        state.messages[i].payload.push(payload.data);
      }
    }

    // handle unread messages notification
    if (payload.data.id !== state.userID) {
      // find the chat in question
      for (let i = 0; i < state.messages.length; i += 1) {
        if (state.messages[i].chatID === payload.chatID) {
          // check if chat is active, if it is we already see the message
          if (!state.chatActive) {
            // add to the total count and to the chat count
            state.totalUnreadMessages += 1;
            // if chat-box is not active, but the chat is active we dont want to add to chat count
            // as this will be the chat active once we open the chat-box
            if (!state.messages[i].isActive) {
              state.messages[i].unreadMessages += 1;
            }
          } else if (state.chatActive) {
            if (!state.messages[i].isActive) {
              state.totalUnreadMessages += 1;
              state.messages[i].unreadMessages += 1;
            }
          }
        }
      }
    }
  },
  [SET_CHAT_ACTIVE](state, id) {
    // Set all chats to not active
    for (let i = 0; i < state.messages.length; i += 1) {
      state.messages[i].isActive = false;
      if (state.messages[i].chatID === id) {
        // Set the chat with id active
        state.messages[i].isActive = true;

        // remove potential unread chat notifications
        const amount = state.messages[i].unreadMessages;
        state.messages[i].unreadMessages = 0;
        state.totalUnreadMessages -= amount;
      }
    }
  },
  [SET_TOTAL_MESSAGE_COUNT](state, count) {
    state.totalUnreadMessages = count;
  },
  [SET_POLL](state, data) {
    state.pollData.question = data.question;
    state.pollData.alternatives = data.alternatives;
    state.pollData.creatorId = data.creatorId;
    state.pollData.isAnonymous = data.isAnonymous;
    state.pollData.isActive = data.isActive;
  },
  [SET_POLL_RESULTS](state, data) {
    state.pollResults = [];
    state.pollResults = data;
  },
  [SET_MENU_ITEMS](state, data) {
    state.menuItems = data;
  },
  [SET_BACKGROUND_IMAGE](state, data) {
    state.backgroundImageUrl = data;
  },
};
