<template>
  <Page />
</template>

<script>
import Page from './containers/page/index.vue';
import store from './store/userdata';

export default {
  name: 'App',
  components: {
    Page,
  },
  async created() {
    const STORE_KEY = '$_userdata';
    // eslint-disable-next-line no-underscore-dangle
    if (!(STORE_KEY in this.$store._modules.root._children)) {
      this.$store.registerModule(STORE_KEY, store);
    }
  },
};
</script>
