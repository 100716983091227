<template>
  <div>
    <h1>Don't know where you wanted to go... ¯\_(ツ)_/¯</h1>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
};
</script>

<style></style>
