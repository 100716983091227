<template>
  <div>
    <v-card dark>
      <div class="padding">
        <h1>Broadcast</h1>
        <div v-for="(entry, index) in alternatives" :key="index"
             v-on:click="broadcastTypeSelected(entry)" class="broadcast-item">
          <BroadcastMenuItem :broadcast-type="entry" style="margin-bottom: 1vh" />
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import BroadcastMenuItem from './BroadcastMenuItem.vue';
import { broadCast, closeMSbroadCast, localBroadCast } from '../../../../assets/js/webrtc';

export default {
  name: 'BroadcastMenu',
  components: { BroadcastMenuItem },
  data: () => ({
    alternatives: ['Classic Mode', 'Mingly Mode', 'Local Video'],
  }),
  methods: {
    ...mapActions('$_canvas', [
      'commitSetBroadCastActive',
      'commitSetBroadcastMenuActive',
      'commitSetBroadcastMinglyModeActive',
    ]),
    broadcastTypeSelected(entry) {
      if (entry === 'Classic Mode') {
        if (window.mingly.msBroadCastId === window.mingly.board.me.uuid) {
          closeMSbroadCast();
        } else {
          broadCast('ms_video');
        }
        this.commitSetBroadcastMenuActive();
      } else if (entry === 'Mingly Mode') {
        this.commitSetBroadcastMinglyModeActive();
        this.commitSetBroadcastMenuActive();
      } else if (entry === 'Local Video') {
        localBroadCast();
        this.commitSetBroadcastMenuActive();
      }
      // close game selection window
      // this.commitSetGamesActive();

      // open game invite window. Commit with the entry so it knows what game to play
      // this.commitSetGameInviteActive(entry);
    },
  },
};
</script>

<style scoped>
  .padding {
    padding: 2vh;
  }
  .broadcast-item {
    cursor: pointer;
  }
  .broadcast-item:hover {
    background: grey;
  }
</style>
