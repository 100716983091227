<template>
  <div>
    <vue-drag-resize :isResizable="false"
                     :w="this.width" :h="this.height"
                     :x="this.left" :y="this.top">
      <v-card dark :height="this.height" :width="this.width">
        <v-row>
          <v-col>
            <h2 class="pa-3 header_scrollbar" style="text-align: center;">
              {{ this.pollData.question }}
            </h2>
          </v-col>
        </v-row>
        <div class="scrollbar">
          <div v-for="(entry, index) in this.pollData.alternatives" :key="index">
            <v-row>
              <v-col class="padding-col">
                <v-card-actions class="padding-card-action">
                  <v-checkbox
                    :label=entry.data
                    :value=entry.index
                    v-model="checkedAlternatives"
                    class="ma-0" />
                </v-card-actions>
              </v-col>
            </v-row>
          </div>
        </div>
        <v-row>
          <v-col class="pa-6" style="text-align: center;">
            <v-btn v-on:click="submitAnswer()">Submit</v-btn>
          </v-col>
        </v-row>
      </v-card>
    </vue-drag-resize>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import VueDragResize from 'vue-drag-resize';
import { answerPoll } from '../../../../assets/js/webrtc';

export default {
  name: 'AnswerPoll',
  components: {
    VueDragResize,
  },
  data: () => ({
    top: 0.15 * window.innerHeight,
    left: 0.02 * window.innerWidth,
    width: 0.3 * window.innerWidth,
    height: 0.4 * window.innerHeight,
    checkedAlternatives: [],
  }),
  computed: {
    ...mapGetters('$_userdata', ['pollData']),
  },
  methods: {
    ...mapActions('$_canvas', [
      'commitSetAnswerPollActive',
    ]),
    submitAnswer() {
      // this.checkedAlternatives
      const pollAnswerData = {
        checkedAlternatives: this.checkedAlternatives,
        creatorId: this.pollData.creatorId,
        isAnonymous: false,
      };
      console.log(pollAnswerData);
      answerPoll(pollAnswerData);
      this.commitSetAnswerPollActive();
    },
  },
};
</script>

<style scoped>
  .header_scrollbar {
    max-height: 10vh;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .padding-col {
    padding-top: 0;
    padding-bottom: 0;
  }
  .padding-card-action {
    padding-bottom: 0;
    padding-top: 0.5vh;
  }
  .scrollbar {
    height: 23vh;
    overflow-y: auto;
    overflow-x: hidden;
  }
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background: #b9b9b9;
  }
</style>
