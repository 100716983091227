<template>
    <vue-drag-resize
                  :x="this.left" :y="this.top"
                  :w="this.width" :h="this.height"
                  :aspectRatio="true"
                  :sticks="['tl','tr','br','bl']"
                  :z="999"
                  v-on:resizing="resize"
                  v-on:deactivated="onDeactivated"
                  v-on:activated="onActivated">
        <div ref="sideCanvasArea">
        </div>
    </vue-drag-resize>
</template>
<script>
// import { mapActions, mapGetters } from 'vuex';
import VueDragResize from 'vue-drag-resize';
// import CloseButton from './CloseButton.vue';

export default {
  name: 'MiniCanvas',
  components: {
    VueDragResize,
  },
  data() {
    return {
      isScreenActive: false,
      isHeld: false,
      width: window.mingly.board.canvasSide0.widht,
      height: window.mingly.board.canvasSide0.height,
      top: 0,
      left: 0,
    };
  },
  methods: {
    resize(newRect) {
      // calculate the biggest
      const ratio = window.mingly.board.height / window.mingly.board.width;
      this.width = newRect.width;
      this.height = newRect.width * ratio;
      window.mingly.board.canvasSide0.width = newRect.width;
      window.mingly.board.canvasSide0.height = newRect.width * ratio;
      window.mingly.board.canvasSide.width = newRect.width;
      window.mingly.board.canvasSide.height = newRect.width * ratio;
      window.mingly.board.updateSideCanvas();
      this.top = newRect.top;
      this.left = newRect.left;
    },
    onDeactivated() {
      this.isScreenActive = false;
    },
    onActivated() {
      const that = this;
      that.isScreenActive = true;
      window.mingly.board.move_keyboard = false;
      setTimeout(() => {
        that.isScreenActive = false;
      }, 2000);
    },
  },
  mounted() {
    window.mingly.board.sideCanvasActive = true;
    // this.width = window.mingly.board.canvasSide0.widht;
    // this.height = window.mingly.board.canvasSide0.height;
    this.$refs.sideCanvasArea.appendChild(window.mingly.board.canvasSide0);
    this.$refs.sideCanvasArea.appendChild(window.mingly.board.canvasSide);
  },
};
</script>

<style scoped>
</style>
