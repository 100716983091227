<template>
  <div>
    <v-card flat>
      <v-responsive :aspect-ratio="16 / 9">
        <iframe width="100%" height="100%" allowfullscreen :src="src" />
      </v-responsive>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'Video',
  props: {
    src: String,
  },
};
</script>

<style scoped>
</style>
