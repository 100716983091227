<template>
  <div class="background">
    <v-row style="padding-top: 10vh;">
      <v-col cols="1">
      </v-col>
      <v-col cols="4">
        <TextBox text="Mingly is a video conference platform where the audio volume and video
            selection depend on the distance to your fellow minglers. You can choose your
            location by moving on a virtual map." />
      </v-col>
      <v-col cols="1">
      </v-col>
      <v-col cols="1">
      </v-col>
      <v-col cols="4">
        <video autoplay muted loop width="100%" height="100%" class="rounded-corners"
               src="https://www.dropbox.com/s/cyft3py40qs6w60/FirstPageV1.mp4?raw=1" />
      </v-col>
      <v-col cols="1">
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="1">
      </v-col>
      <v-col cols="4">
        <video autoplay muted loop width="100%" height="100%" class="rounded-corners"
               src="https://www.dropbox.com/s/na035ifb4m4oqyk/Front%20Page%20Multiple%20Conversations.mp4?raw=1" />
      </v-col>
      <v-col cols="1">
      </v-col>
      <v-col cols="1">
      </v-col>
      <v-col cols="4">
        <TextBox text="The map provides an organic way of mingling with other
            people – you hear the people that are close to you better
            than those further away. Multiple conversations can happen at
            the same time in the same room." />
      </v-col>
      <v-col cols="1">
      </v-col>
    </v-row>
    <v-row style="padding-top: 10vh;">
      <v-col cols="1">
      </v-col>
      <v-col cols="4">
        <TextBox text="With current video conference solutions,
        you either hear full sound or no sound at all…
        but as we all know, interesting things happens in between
         – and these are what Mingly captures and provides." />
      </v-col>
      <v-col cols="1">
      </v-col>
      <v-col cols="1">
      </v-col>
      <v-col cols="4">
        <video autoplay muted loop width="100%" height="100%" class="rounded-corners"
               src="https://www.dropbox.com/s/lef9ygumdfyjrt5/First%20Page%20Share%20Screen%20on%20Canvas.mp4?raw=1" />
      </v-col>
      <v-col cols="1">
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="1">
      </v-col>
      <v-col cols="4">
        <video autoplay muted loop width="100%" height="100%" class="rounded-corners"
               src="https://www.dropbox.com/s/i4e1atw01k6d01s/First%20Page%20N2.mp4?raw=1" />
      </v-col>
      <v-col cols="1">
      </v-col>
      <v-col cols="1">
      </v-col>
      <v-col cols="4">
        <TextBox text="Mingly provides a space that is highly customizable -
        Easily change the feel or purpose of the room by changing the background,
         adding media such as videos, audio or pictures and share your screen. " />
      </v-col>
      <v-col cols="1">
      </v-col>
    </v-row>
  </div>
</template>

<script>
import TextBox from './_component/TextBox.vue';

export default {
  name: 'index',
  components: { TextBox },
};
</script>

<style scoped>
  .rounded-corners {
    border-radius: 25px;
  }
  .background {
    /* background: url('https://www.dropbox.com/s/fk0u89rhzxfkcmi/IMG_7328.jpeg?raw=1'); */
    background: url('https://www.dropbox.com/s/eeaq8lkpwlvhaio/IMG_2775.jpg?raw=1');
    background-size: cover;
  }
</style>
