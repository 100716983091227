<template>
  <div>
    <v-card dark>
      <div class="padding">
        <h1>Select a game</h1>
        <div v-for="(entry, index) in testAlternatives" :key="index"
             v-on:click="gameSelected(entry)" class="game-item">
          <GameItem :game="entry" style="margin-bottom: 1vh" />
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import GameItem from './GameItem.vue';

export default {
  name: 'GameSelection',
  components: { GameItem },
  data: () => ({
    testAlternatives: ['Guess the city'],
  }),
  methods: {
    ...mapActions('$_canvas', [
      'commitSetGameInviteActive', 'commitSetGamesActive',
    ]),
    gameSelected(entry) {
      console.log(entry);
      // close game selection window
      this.commitSetGamesActive();

      // open game invite window. Commit with the entry so it knows what game to play
      this.commitSetGameInviteActive(entry);
    },
  },
};
</script>

<style scoped>
  .padding {
    padding: 2vh;
  }
  .game-item {
    cursor: pointer;
  }
  .game-item:hover {
    background: grey;
  }
</style>
