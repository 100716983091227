<template>
  <div>
    <div v-if="isAllChat">
      <v-avatar size="36">
        <v-icon>
          mdi-account-group
        </v-icon>
      </v-avatar>
      Everyone
      <div class="notification" v-if="getUnreadMessages('everyone-id') > 0">
        {{ getUnreadMessages('everyone-id') }}
        <v-icon size="12">
          mdi-bell-ring
        </v-icon>
      </div>
    </div>
    <div v-else>
      <v-avatar size="36">
        <v-img :src="person.picture.src" />
      </v-avatar>
      {{ person.username.slice(0, 10) }}
      <div class="notification" v-if="getUnreadMessages(person.userID) > 0">
        {{ getUnreadMessages(person.userID) }}
        <v-icon size="12">
          mdi-bell-ring
        </v-icon>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ChatItem',
  props: {
    person: Object,
    isAllChat: Boolean,
  },
  computed: {
    ...mapGetters('$_userdata', [
      'messages',
    ]),
  },
  methods: {
    getUnreadMessages(chatID) {
      let amount = 0;
      for (let i = 0; i < this.messages.length; i += 1) {
        if (this.messages[i].chatID === chatID) {
          amount = this.messages[i].unreadMessages;
        }
      }
      return amount;
    },
  },
};
</script>

<style scoped>
  .notification {
    position: absolute;
    right: 1vh;
    bottom: 0;
    font-size: 10px;
  }
</style>
