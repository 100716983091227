<template>
  <div>
    <v-card class="mx-auto pa-4 speaker_card" max-width="500">
      <audio ref="audioElement" loop src="https://www.dropbox.com/s/s1j2mdws9jmxpyh/bensound-creativeminds.mp3?raw=1"/>
      <v-select :items="speakers" v-model="speaker" return-object
                v-on:change="changeSpeaker"
                label="Select Speaker"
                class="dropdown_width mx-auto" />
      <h2 class="text-center padding">
        Continue when you hear a sound
      </h2>
      <v-card-actions class="justify-center padding_bot">
        <v-btn dark elevation="8" large class="btn_width" v-on:click="saveSpeakerSelection">
          Continue
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'SpeakerCard',
  data: () => ({
    speaker: {
      text: localStorage.getItem('audio_output_name'),
      value: localStorage.getItem('audio_output_id'),
    },
    speakers: [],
  }),
  mounted: function getSpeakers() {
    this.$refs.audioElement.play();
    if (navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.enumerateDevices()
        .then((devices) => {
          devices.forEach((device) => {
            if (device.kind === 'audiooutput') {
              console.log(`devideID: ${device.deviceId}`);
              this.speakers.push({ text: device.label, value: device.deviceId });
            }
          });
        })
        .catch((err) => {
          console.error(`error: ${err.name}: ${err.message}`);
        });
    } else {
      this.$refs.audioElement.play();
      // todo: alert the user that we didn't find speakers
    }
  },
  methods: {
    changeSpeaker(speakerSelection) {
      if (speakerSelection.value) {
        console.log(`devideID: ${speakerSelection.value}`);
        this.setSpeaker(speakerSelection.value);
      }
    },
    setSpeaker(deviceId) {
      if (deviceId) {
        this.$refs.audioElement.setSinkId(deviceId);
        this.$refs.audioElement.play();
      }
    },
    saveSpeakerSelection() {
      window.mingly.audioOutSource = this.speaker.value;
      localStorage.setItem('audio_output_name', this.speaker.text);
      localStorage.setItem('audio_output_id', this.speaker.value);
      this.$router.push({ name: 'SelectCamera' });
    },
  },
};
</script>

<style scoped>
.speaker_card {
  margin-top: 30vh;
}
.dropdown_width {
  width: 80%;
}
.btn_width {
  width: 60%;
}
.padding {
  padding-top: 2vh;
}
.padding_bot {
  padding-bottom: 2vh;
}
</style>
