<template>
  <div style="position: absolute; z-index: 1; width: 100%;">
    <!-- <v-app-bar color="#203e5f" dark> -->
    <v-app-bar color="transparent" flat dark>
      <v-app-bar-nav-icon class="hidden-sm-and-up" @click="drawer = true" />
      <v-toolbar-side-icon>
          <v-img @click="goToHome"
          class="mr-3" src="../../../assets/RandomLogoNoBackGround.png"
          height="50px" width="100px">
          </v-img>
     </v-toolbar-side-icon>
      <v-spacer></v-spacer>
      <v-toolbar-items class="hidden-xs-only">
        <!-- <v-btn text link @click="goToTutorial" class="menu-link">Tutorial</v-btn> -->
        <v-btn text link @click="goToNewsLetter" class="menu-link">Newsletter</v-btn>
        <v-btn text link @click="goToAbout" class="menu-link">About</v-btn>
        <v-btn text link @click="goToWhyMingly" class="menu-link">Why Mingly?</v-btn>
        <!-- <v-btn text link @click="goToAbout" class="menu-link">Community</v-btn>
        <v-btn text link @click="goToAbout" class="menu-link">Features</v-btn> -->
        <v-btn text link @click="goToLogin" class="menu-link">Join a room</v-btn>
      </v-toolbar-items>
      <!-- <v-toolbar-items class="hidden-xs-only">
        <v-btn text link @click="logout()" class="menu-link">
          Logout <v-icon>mdi-logout</v-icon>
        </v-btn>
      </v-toolbar-items> -->
    </v-app-bar>
      <!-- <v-toolbar-items class="hidden-xs-only">
        <v-btn text link @click="goToAbout" class="menu-link">About</v-btn>
        <v-btn text link class="menu-link">Tutorial</v-btn>
        <v-btn text link class="menu-link">FAQ</v-btn>
        <v-btn text link class="menu-link">Contact</v-btn>
      </v-toolbar-items> -->
    <v-navigation-drawer v-model="drawer" app temporary>
      <v-list dense>
        <v-list-item>
          <v-list-item-action>
            <v-icon>Mingly</v-icon>
          </v-list-item-action>
        </v-list-item>
        <v-list-item link @click="goToLogin">
          <v-list-item-content>
            <v-list-item-title>Join a room</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Header',
  data: () => ({
    drawer: false,
  }),
  computed: {
    ...mapGetters('$_userdata', ['room']),
  },
  methods: {
    goToAbout() {
      this.$router.push({ name: 'About' });
    },
    goToWhyMingly() {
      this.$router.push({ name: 'WhyMingly' });
    },
    goToNewsLetter() {
      this.$router.push({ name: 'Newsletter' });
    },
    goToHome() {
      this.$router.push({ name: 'LandingPage' });
    },
    goToLogin() {
      this.$router.push({ name: 'Login' });
    },
    // goToTutorial() {
    //   this.$router.push({ name: 'Tutorial' });
    // },
    logout() {
      // standard route
      // this.$router.push({ name: 'Login' });

      // Temporary for testing
      if (this.room === 'TestRoom0') {
        this.$router.push({ name: 'Questionnaire0' });
      } else if (this.room === 'TestRoom1') {
        this.$router.push({ name: 'Questionnaire1' });
      } else if (this.room === 'TestRoom2') {
        this.$router.push({ name: 'Questionnaire2' });
      } else if (this.room === 'TestRoom3') {
        this.$router.push({ name: 'Questionnaire3' });
      } else if (this.room === 'TestRoom4') {
        this.$router.push({ name: 'Questionnaire4' });
      } else if (this.room === 'TestRoom5') {
        this.$router.push({ name: 'Questionnaire5' });
      } else if (this.room === 'TestRoom6') {
        this.$router.push({ name: 'Questionnaire6' });
      } else if (this.room === 'TestRoom7') {
        this.$router.push({ name: 'Questionnaire7' });
      } else if (this.room === 'TestRoom8') {
        this.$router.push({ name: 'Questionnaire8' });
      } else if (this.room === 'TestRoom9') {
        this.$router.push({ name: 'Questionnaire9' });
      } else if (this.room === 'TestRoom10') {
        this.$router.push({ name: 'Questionnaire10' });
      } else {
        this.$router.push({ name: 'LandingPage' });
      }
    },
  },
};
</script>

<style scoped>

</style>
