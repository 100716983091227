import Room from './room.js'; // eslint-disable-line import/extensions

export default class Mingly {
  constructor() {
    this.rooms = {}; // object that holds room configs
    this.board = null;

    // websocket server
    this.wsurl = null;
    // for debugging
    this.showConsole = true;
    // device setup
    this.videoSource = localStorage.getItem('camera_id');
    this.audioSource = localStorage.getItem('audio_input_id');
    this.audioOutSource = localStorage.getItem('audio_output_id');

    // media constraints
    this.constraints = {};

    // streams
    this.streamHighRes = new MediaStream();
    this.streamLowRes = new MediaStream();
    this.streamBlack = new MediaStream();
    this.streamBlackSilence = new MediaStream();
    this.localStream4 = new MediaStream();
    this.streamScreen = new MediaStream();

    // event handlers
    this.onAddParticipant = null; // triggered on new participants
    this.onRemoveParticipant = null; // triggered on new participants
    this.onChangeVideo = null; // triggered if I change video
    this.onChangeMicrophone = null; // triggered if I change mic
    this.onVideovar = null; // triggered when there is a change in participant.videovar variable
    this.onScreenShare = null; // triggered when screen sharing
    this.onChatMessage = null; // triggered when there is a chat messag
    this.onChangeName = null; // change the name of participant
    this.onChangeProfilePic = null; // change the profile picture
    this.onMSBroadcast = null; // when there is a MS broadcast
    this.onReadyCanvas = null; // callback when ready
    this.onMeIsHost = null; // triggered if I am host
    this.onNoHostMenu = null; // triggered when host wants no hosts to get options like raise hand
    this.onRaiseHand = null; // if someone raises their hand
    this.onLowerHand = null; // if host lowers your hand

    // mediasoup
    this.device = null; // the mediaserver
    this.consumers = []; // this is the stream that you consume over the ms
    // will need to find a better way to make this nicely organized
    // not sure if this is better as part of room, participant or sometihng
    // entirely different.
    this.sendTransportOptions = null;
    this.recvTransportOptions = null;
    this.recvTransport = null;
    this.sendTransport = null;
    this.camVideoProducer = null;
    this.camAudioProducer = null;
    this.screenVideoProducer = null;
    this.screenAudioProducer = null;
    this.msVideoStream = null;
    // broadcasting
    this.msBroadCastId = null; // this is the uuid of the broadcaster
    this.msBroadCastType = null; // this is the type: Video, screen (maybe audio only later)
    this.msBroadCastOnCanvasData = {
      id: null,
      x: null,
      y: null,
      size: null,
      aspectRatio: null,
      active: false,
    };
    //
    this.msVideoStream = null; // should remove this one
    this.myTransporstAreReady = 0; // 0, 1 or 2 transports ready
    this.msOnProduceCallback = null; // this has to be checked

    this.onGotPoll = null; // for sending polls (and quizzes)
    this.onGotPollAnswer = null; // for getting replies back from polls (and quizzes)
    this.onGotPollStats = null; // for getting replies back from polls (and quizzes)
    this.onClosePoll = null; // for closing a poll

    this.onGameInvite = null; // for game invite
    this.onGotGameAcceptance = null; // if accept/reject to play game
    this.onGotQuizAnswer = null; // quiz answers
    this.onStopGame = null; // stop game
    this.onUpdateGameData = null; // for updating scores etc

    // for benchmarking
    this.wsBenchmarkingServer = null;
    // Host realted variables etc.
    // Host states
    this.hostStates = {
      lockedMove: false,
      mutedAudio: false,
      turnedOffVideo: false,
      disabledMenues: [],
    };
    this.raisedHandData = {};
  }

  log(message) {
    if (this.showConsole) {
      console.log(message);
    }
  }

  warning(message) {
    if (this.showConsole) {
      console.warning(message);
    }
  }

  error(message) {
    if (this.showConsole) {
      console.error(message);
    }
  }

  debug(message) {
    if (this.showConsole) {
      console.debug(message);
    }
  }

  fetchRooms() {
    // todo: replace this with an api get
    function addRoomX(room) {
    // set common properties
    // room.backgroundPicture.src = 'https://www.dropbox.com/s/fk0u89rhzxfkcmi/IMG_7328.jpeg?raw=1';
      // eslint-disable-next-line no-param-reassign
      room.backgroundPicture.src = 'https://www.dropbox.com/s/eeaq8lkpwlvhaio/IMG_2775.jpg?raw=1';

      // add to object
      this.rooms[room.name] = room;
    }

    const addRoom = addRoomX.bind(this);
    this.rooms = {}; // clear rooms

    addRoom(new Room('Test1', 4, 1, 5, 10000, true, 1, true));
    addRoom(new Room('Test2', 4, 1, 5, 10000, true, 1.5, true));
    addRoom(new Room('Test3', 6, 1, 5, 10000, true, 1, true));
    addRoom(new Room('Test4', 6, 1, 20, 10000, true, 1.5, true));
    addRoom(new Room('Test5', 8, 1, 10, 10000, true, 1, true));
    addRoom(new Room('Test6', 8, 1, 20, 10000, true, 1.5, true));
    addRoom(new Room('Test7', 4, 1, 10, 10000, false, 1.5, true));
    addRoom(new Room('Lillis', 4, 1, 10, 10000, false, 1.5, true));

    // make sure these do not show in the room picker dropdown
    Object.values(this.rooms).forEach((room) => {
      // eslint-disable-next-line no-param-reassign
      room.enabled = false;
    });

    addRoom(new Room('A0', 4, 1, 5, 10000, true, 1.5, false));
    addRoom(new Room('A1', 6, 1, 10, 10000, true, 1.5, false));
    addRoom(new Room('A2', 4, 1, 5, 10000, true, 1.5, false));
    addRoom(new Room('A3', 4, 1, 5, 10000, true, 1.5, false));

    addRoom(new Room('B1', 4, 1, 5, 10, true, 1.5, false));
    addRoom(new Room('B2', 12, 1, 5, 10000, true, 1.5, false));
    addRoom(new Room('B3', 4, 1, 5, 10000, true, 1.5, false));

    addRoom(new Room('C1', 4, 1, 5, 10000, true, 1, false));
    addRoom(new Room('C2', 4, 1, 5, 10000, true, 1.5, false));
    addRoom(new Room('C3', 4, 1, 5, 10000, true, 2, false));

    addRoom(new Room('D1', 4, 1, 5, 10000, true, 0.8, false));
    addRoom(new Room('D2', 4, 1, 5, 10000, true, 0.8, false));
    addRoom(new Room('D3', 4, 1, 5, 10000, true, 0.8, false));

    addRoom(new Room('E1', 8, 1, 5, 10000, true, 1.5, false));
    addRoom(new Room('E2', 12, 1, 5, 10000, true, 1.5, false));
    addRoom(new Room('E3', 4, 1, 5, 10000, true, 1.5, false));

    addRoom(new Room('VicoteeRoom', 6, 1, 5, 10000, true, 1.5, false));
    addRoom(new Room('SafetykleenRoom', 6, 1, 5, 10000, true, 1.5, false));
    addRoom(new Room('F300Spring2022-8243', 6, 1, 10, 10000, true, 1.5, false));
    addRoom(new Room('F300Spring2022-10523', 6, 1, 10, 10000, true, 1.5, false));
    addRoom(new Room('F300Spring2022', 6, 1, 10, 10000, true, 1.5, false));
    addRoom(new Room('TestRoom8', 6, 1, 10, 10000, true, 1.5, false));
    addRoom(new Room('MinglyFriends', 6, 1, 10, 10000, true, 1.5, false));
    addRoom(new Room('ROIWAN', 6, 1, 10, 10000, true, 1.5, false));
    addRoom(new Room('HaakonHeyer', 6, 1, 10, 10000, true, 1.5, false));
    addRoom(new Room('ChristiansRoom', 6, 1, 10, 10000, true, 1.5, false));
    addRoom(new Room('HPC_dome', 6, 1, 10, 10000, true, 1.5, false));

    // additional room settings
    this.rooms.Lillis.brutalUpdate = true;
    this.rooms.A0.backgroundPicture.src = 'https://bl3301files.storage.live.com/y4m5TNsLJxVWCntUYFCbwz0-vbWu_2fMaCZVH0R7cmTt0PSHdWPWFfNMGN7xaRVJiLB1aE_FsBxl9BOLy09k0nFJ6-yhQCnkLNYZxRXVcnEVcjL7e4lAF39GBOY22eT4OJIxf1wiZQDHwAEHmRS7WR5OK_ibO-SRh7_fuBCkRMQnomA_EW93qk8p5S5KiRch37v?width=6368&height=5253&cropmode=none';
    this.rooms.A0.editBandWidth = false;
    this.rooms.A1.backgroundPicture.src = 'https://www.dropbox.com/s/fk0u89rhzxfkcmi/IMG_7328.jpeg?raw=1';
    this.rooms.A1.editBandWidth = true;
    this.rooms.A1.editVideoBandWidthOnly = true;
    this.rooms.A2.scalingFactor = 100;
    this.rooms.A3.scalingFactor = 20;
    this.rooms.B2.youTubeVideoId = 'nwAaGLo8OE8';
    this.rooms.B2.youTubePlaylist = 'nwAaGLo8OE8';
    this.rooms.B2.backgroundPicture.src = 'https://bl3301files.storage.live.com/y4mL4A-HzpP5kIUsm7mb5vqnVdgQ9hKQTGZlWUmMqfkQk3CaSvw1nsO9kxCwI_qH29gW4MTIFPJYJYknsR4pDoJxwXcu95QCVmqIQlqYZ4H6ixmRqFx5JPR5Y6ff105nKASbkvCk69jJo9758z4KnP84imPhjoCGKVGQ7vRuqho5yKaWx6VaRt71YC6EwAtlitz?width=5952&height=4480&cropmode=none';
    this.rooms.B3.backgroundPicture.src = 'https://bl3301files.storage.live.com/y4mL4A-HzpP5kIUsm7mb5vqnVdgQ9hKQTGZlWUmMqfkQk3CaSvw1nsO9kxCwI_qH29gW4MTIFPJYJYknsR4pDoJxwXcu95QCVmqIQlqYZ4H6ixmRqFx5JPR5Y6ff105nKASbkvCk69jJo9758z4KnP84imPhjoCGKVGQ7vRuqho5yKaWx6VaRt71YC6EwAtlitz?width=5952&height=4480&cropmode=none';
    this.rooms.B3.backgroundPicture.src = 'https://bl3301files.storage.live.com/y4mL4A-HzpP5kIUsm7mb5vqnVdgQ9hKQTGZlWUmMqfkQk3CaSvw1nsO9kxCwI_qH29gW4MTIFPJYJYknsR4pDoJxwXcu95QCVmqIQlqYZ4H6ixmRqFx5JPR5Y6ff105nKASbkvCk69jJo9758z4KnP84imPhjoCGKVGQ7vRuqho5yKaWx6VaRt71YC6EwAtlitz?width=5952&height=4480&cropmode=none'; // non-vue-skip-line
    // this.rooms.D2.frameRate = 24;
    // this.rooms.D3.frameRate = 18;
    this.rooms.E1.editBandWidth = true;
    this.rooms.E2.editBandWidth = true;
    this.rooms.E2.editVideoBandWidthOnly = true;
    this.rooms.E2.smoothAudio = false;
    this.rooms.E3.smoothAudio = true;
    // some additional rooms
    this.rooms.VicoteeRoom.backgroundPicture.src = 'https://www.dropbox.com/s/p8ruwfcyy1uxotq/vicotee-smart-city-3%20%281%29.jpg?raw=1';
    this.rooms.SafetykleenRoom.backgroundPicture.src = 'https://www.dropbox.com/s/96veu7bbs4d6ab7/Key%20visual%20with%20brand%20in%20corner.jpg?raw=1';
    this.rooms.TestRoom8.backgroundPicture.src = 'https://www.dropbox.com/s/ivv5284uv0hh85j/annie-spratt-reU05EIlNCQ-unsplash.jpg?raw=1';
    this.rooms.HaakonHeyer.backgroundPicture.src = 'https://www.dropbox.com/s/xk7yl3x0vjy8yam/Rosa%20NCS%20S%206010-Y70R.jpg?raw=1';
    this.rooms.ROIWAN.backgroundPicture.src = 'https://www.dropbox.com/s/jypvflw38ch64b8/GrayRec.png?raw=1';
    this.rooms.ChristiansRoom.backgroundPicture.src = 'https://www.dropbox.com/s/2jov5w0pa0se4uu/CHLBackground.jpg?raw=1';
    this.rooms.HPC_dome.backgroundPicture.src = 'https://www.dropbox.com/s/upmvxmqvuv8s93k/photo_2022-09-20%2014.27.58.jpeg?raw=1';
  }
}
