<template>
  <div class="broadcast-item">
    <h4 class="padding">{{ broadcastType }}</h4>
  </div>
</template>

<script>
export default {
  name: 'BroadcastMenuItem',
  props: {
    broadcastType: String,
  },
};
</script>

<style scoped>
  .broadcast-item {
    border: 1px solid grey;
  }
  .padding {
    padding: 1vh;
    text-align: center;
  }
</style>
