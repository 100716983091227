<template>
  <div class="background">
     <v-row>
        <v-col>
           <v-card class="mx-auto spatialTop pa-4" max-width="1200">
              <h1 class="text-center pa-2">Why Mingly?</h1>
              <v-row>
                <h2 class="text-center pa-2">Mingly provides a different experience than traditional
                video chat applications. Here we give you 8 reasons that
                we think make Mingly different and give  you a whole different
                experience than the alternatives.</h2>
              </v-row>
            </v-card>
        </v-col>
     </v-row>
     <v-row>
        <v-col>
           <v-card class="mx-auto spatial1 pa-4" max-width="1200">
              <h1 class="text-center pa-2">Spatial</h1>
              <v-row>
                  <v-col class="colorstyle1">
                     <h2 class="text-center pa-2"> Why </h2>
                  </v-col>
                   <v-col class="colorstyle1">
                      <h2 class="text-center pa-2">How </h2>
                  </v-col>
              </v-row>
              <v-row>
                  <v-col class="colorstyle1">
                     <video autoplay muted controls loop width="100%" height="100%"
               src="https://www.dropbox.com/s/ru89d9yjp8uia98/Why%20-%20Spatial%20Audio.mp4?raw=1" />

                  </v-col>
                   <v-col class="colorstyle1">
                     <video controls width="100%" height="100%"
               src="https://www.dropbox.com/s/88atrlgnk89yh06/SharingOfficeV2.mp4?raw=1" />

                  </v-col>
              </v-row>
            </v-card>
        </v-col>
     </v-row>
    <v-row>
        <v-col>
           <v-card class="mx-auto spatial2 pa-4" max-width="1200">
              <h1 class="text-center pa-2">Intuitive navigation</h1>
              <v-row>
                  <v-col class="colorstyle2">
                     <h2 class="text-center pa-2"> Why </h2>
                  </v-col>
                   <v-col class="colorstyle2">
                      <h2 class="text-center pa-2">How </h2>
                  </v-col>
              </v-row>
              <v-row>
                  <v-col class="colorstyle2">
                     <video autoplay muted controls loop width="100%" height="100%"
               src="https://www.dropbox.com/s/x2k1bk2q5iwfyyi/Why%20-%20Easy%20Navigation.mp4?raw=1" />

                  </v-col>
                   <v-col class="colorstyle2">
                     <video  controls width="100%" height="100%"
               src="https://www.dropbox.com/s/88atrlgnk89yh06/SharingOfficeV2.mp4?raw=1" />

                  </v-col>
              </v-row>
            </v-card>
        </v-col>
     </v-row>
      <v-row>
        <v-col>
           <v-card class="mx-auto spatial1 pa-4" max-width="1200">
              <h1 class="text-center pa-2">Personalized feel with background images</h1>
              <v-row>
                  <v-col class="colorstyle1">
                     <h2 class="text-center pa-2"> Why </h2>
                  </v-col>
                   <v-col class="colorstyle1">
                      <h2 class="text-center pa-2">How </h2>
                  </v-col>
              </v-row>
              <v-row>
                  <v-col class="colorstyle1">
                     <video autoplay muted controls loop width="100%" height="100%"
               src="https://www.dropbox.com/s/r2bva9b92u94uqb/Why%20-%20Fun%20Background.mp4?raw=1" />

                  </v-col>
                   <v-col class="colorstyle1">
                     <video  controls width="100%" height="100%"
               src="https://www.dropbox.com/s/88atrlgnk89yh06/SharingOfficeV2.mp4?raw=1" />

                  </v-col>
              </v-row>
            </v-card>
        </v-col>
     </v-row>
      <v-row>
        <v-col>
           <v-card class="mx-auto spatial2 pa-4" max-width="1200">
              <h1 class="text-center pa-2">Interactive backgrounds</h1>
              <v-row>
                  <v-col class="colorstyle2">
                     <h2 class="text-center pa-2"> Why </h2>
                  </v-col>
                   <v-col class="colorstyle2">
                      <h2 class="text-center pa-2">How </h2>
                  </v-col>
              </v-row>
              <v-row>
                  <v-col class="colorstyle2">
                     <video autoplay muted controls loop width="100%" height="100%"
               src="https://www.dropbox.com/s/k0ffpaufw0x5q7x/Why%20-%20Dynamic%20Backgrounds.mp4?raw=1" />

                  </v-col>
                   <v-col class="colorstyle2">
                     <video  controls width="100%" height="100%"
               src="https://www.dropbox.com/s/88atrlgnk89yh06/SharingOfficeV2.mp4?raw=1" />

                  </v-col>
              </v-row>
            </v-card>
        </v-col>
     </v-row>
      <v-row>
        <v-col>
           <v-card class="mx-auto spatial1 pa-4" max-width="1200">
              <h1 class="text-center pa-1">Dynamic presentations</h1>
              <v-row>
                  <v-col class="colorstyle1">
                     <h2 class="text-center pa-2"> Why </h2>
                  </v-col>
                   <v-col class="colorstyle1">
                      <h2 class="text-center pa-2">How </h2>
                  </v-col>
              </v-row>
              <v-row>
                  <v-col class="colorstyle1">
                     <video autoplay muted controls loop width="100%" height="100%"
               src="https://www.dropbox.com/s/7ti1b0cjujfctj2/Why%20-%20Presenations.mp4?raw=1" />

                  </v-col>
                   <v-col class="colorstyle1">
                     <video  controls width="100%" height="100%"
               src="https://www.dropbox.com/s/88atrlgnk89yh06/SharingOfficeV2.mp4?raw=1" />

                  </v-col>
              </v-row>
            </v-card>
        </v-col>
     </v-row>
      <v-row>
        <v-col>
           <v-card class="mx-auto spatial2 pa-4" max-width="1200">
              <h1 class="text-center pa-2">Spatial polling</h1>
              <v-row>
                  <v-col class="colorstyle2">
                     <h2 class="text-center pa-2"> Why </h2>
                  </v-col>
                   <v-col class="colorstyle2">
                      <h2 class="text-center pa-2">How </h2>
                  </v-col>
              </v-row>
              <v-row>
                  <v-col class="colorstyle2">
                     <video autoplay muted controls loop width="100%" height="100%"
               src="https://www.dropbox.com/s/zqwy2ddpzrcgsld/Why%20-%20Polling.mp4?raw=1" />

                  </v-col>
                   <v-col class="colorstyle2">
                     <video  controls width="100%" height="100%"
               src="https://www.dropbox.com/s/88atrlgnk89yh06/SharingOfficeV2.mp4?raw=1" />

                  </v-col>
              </v-row>
            </v-card>
        </v-col>
     </v-row>
       <v-row>
        <v-col>
           <v-card class="mx-auto spatial1 pa-4" max-width="1200">
              <h1 class="text-center pa-1">Spatial quiz</h1>
              <v-row>
                  <v-col class="colorstyle1">
                     <h2 class="text-center pa-2"> Why </h2>
                  </v-col>
                   <v-col class="colorstyle1">
                      <h2 class="text-center pa-2">How </h2>
                  </v-col>
              </v-row>
              <v-row>
                  <v-col class="colorstyle1">
                     <video autoplay muted controls loop width="100%" height="100%"
               src="https://www.dropbox.com/s/ka0xwywoyo3e7j5/Why%20-%20Guess%20the%20City.mp4?raw=1" />

                  </v-col>
                   <v-col class="colorstyle1">
                     <video  controls width="100%" height="100%"
               src="https://www.dropbox.com/s/88atrlgnk89yh06/SharingOfficeV2.mp4?raw=1" />

                  </v-col>
              </v-row>
            </v-card>
        </v-col>
     </v-row>
       <v-row>
        <v-col>
           <v-card class="mx-auto spatial2 pa-4" max-width="1200">
              <h1 class="text-center pa-2">Dynamic meeting controls</h1>
              <v-row>
                  <v-col class="colorstyle2">
                     <h2 class="text-center pa-2"> Why </h2>
                  </v-col>
                   <v-col class="colorstyle2">
                      <h2 class="text-center pa-2">How </h2>
                  </v-col>
              </v-row>
              <v-row>
                  <v-col class="colorstyle2">
                     <video autoplay muted controls loop width="100%" height="100%"
               src="https://www.dropbox.com/s/p0gzl78f5x7wn6x/Why%20-%20Host%20Controls.mp4?raw=1" />

                  </v-col>
                   <v-col class="colorstyle2">
                     <video  controls width="100%" height="100%"
               src="https://www.dropbox.com/s/88atrlgnk89yh06/SharingOfficeV2.mp4?raw=1" />

                  </v-col>
              </v-row>
            </v-card>
        </v-col>
     </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'index',
  computed: {
    ...mapGetters('$_userdata', ['backgroundImageUrl']),
  },
};
</script>

<style scoped>
  .rounded-corners {
    border-radius: 150px;
  }
  .background {
    background: url('https://www.dropbox.com/s/eeaq8lkpwlvhaio/IMG_2775.jpg?raw=1');
    background-size: cover;
    height: 101%
  }
  .box-style {
    padding: 1vh;
    text-align: center;
  }
  .colorstyle1 {
    /* background-color: rgba(255,255,255,.6);
    color: #000; */
    background-color:#203e5fff;
    color: #eac252;
  }
  .colorstyle2 {
    /* background-color: rgba(255,255,255,.6);
    color: #000; */
    background-color: #eac252;
    color: #203e5fff;
  }
  .spatialTop {
    margin-top: 24vh;
     background-color: #eac252;
    color:#203e5fff;
  }
  .spatial1 {
    margin-top: 5vh;
    background-color: #203e5fff;
    color:#eac252;
  }
  .spatial2 {
    margin-top: 5vh;
    background-color: #eac252;
    color:#203e5fff;
  }
</style>
