<template>
  <v-btn v-on:click="method" icon>
    <v-icon>mdi-close-circle-outline</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'CloseButton',
  props: {
    method: { type: Function },
  },
};
</script>

<style scoped>
</style>
