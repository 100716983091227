// eslint-disable-next-line max-classes-per-file
class Node {
  constructor(data, next = null) {
    this.data = data;
    this.next = next;
  }
}

export default class PeerLinkedList {
  constructor() {
    this.head = null;
    this.size = 0;
  }

  // add node
  add(data) {
    this.head = new Node(data, this.head);
    this.size += 1;
  }

  // get by index
  getByIndex(index) {
    let current = this.head;
    let count = 0;

    while (current) {
      if (count === index) {
        return current.data;
      }
      count += 1;
      current = current.next;
    }

    return null;
  }

  /**
   * Used to update other peers position on the canvas.
   *
   * @param payload - object containing { id, x, y } id of the sender, and the new x and y position
   */
  updatePosition(payload) {
    let current = this.head;

    while (current) {
      if (current.data.userID === payload.id) {
        current.data.position.x = payload.position.x;
        current.data.position.y = payload.position.y;
      }
      current = current.next;
    }
  }

  updateVideovar(data) {
    let current = this.head;

    while (current) {
      if (current.data.userID === data.uuid) {
        current.data.videovar = data.videovar;
      }
      current = current.next;
    }
  }

  updateHoover(data) {
    let current = this.head;

    while (current) {
      if (current.data.userID === data.uuid) {
        current.data.hoover = data.hoover;
      }
      current = current.next;
    }
  }

  updateProfilePic(data) {
    let current = this.head;

    while (current) {
      if (current.data.userID === data.userID) {
        current.data.profilePicture = data.profilePicture;
      }
      current = current.next;
    }
  }

  // get by id
  getByUserId(id) {
    let current = this.head;

    while (current) {
      if (current.data.userID === id) {
        return current.data;
      }
      current = current.next;
    }
    return null;
  }

  // remove by id
  removeByUserId(id) {
    let current = this.head;
    let previous = null;
    while (current) {
      if (current.data.userID === id) {
        if (previous === null) {
          // the user is placed first in the linked list
          this.head = current.next;
        } else if (current.next !== null) {
          // the user is placed between two nodes
          previous.next = current.next;
        } else {
          // the user is the last node
          previous.next = null;
        }
        this.size -= 1;
        return;
      }
      previous = current;
      current = current.next;
    }
  }

  // get all usernames
  getUsersAndPictures() {
    let current = this.head;
    const users = [];
    while (current) {
      users.push({
        username: current.data.username,
        userID: current.data.userID,
        picture: current.data.profilePicture,
        hover: current.data.hoover,
      });
      current = current.next;
    }
    return users;
  }

  // get users for chatbox
  getChatUsers() {
    let current = this.head;
    const users = [{ id: 'everyone-id', name: 'Everyone' }];
    while (current) {
      users.push({
        id: current.data.userID,
        name: current.data.username,
      });
      current = current.next;
    }
    return users;
  }

  // to array
  toArray() {
    let current = this.head;
    const array = [];

    while (current) {
      array.push(current.data);
      current = current.next;
    }
    return array;
  }

  // get size
  length() {
    return this.size;
  }
}
