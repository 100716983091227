<template>
  <div class="game-item">
    <h4 class="padding">{{ game }}</h4>
  </div>
</template>

<script>
export default {
  name: 'GameItem',
  props: {
    game: String,
  },
};
</script>

<style scoped>
  .game-item {
    border: 1px solid grey;
  }
  .padding {
    padding: 1vh;
    text-align: center;
  }
</style>
