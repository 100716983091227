<template>
<div class="background" v-bind:style="{ backgroundImage: 'url(' + backgroundImageUrl + ')' }">
  <div>
    <Canvas/>
    <EnterVideo v-if="isEnterVideoActive"/>
    <CanvasMenu class="top-right"/>
    <CanvasHostMenu class="host_menu" v-if="host"/>
    <CanvasNoHostMenu class="host_menu" v-if="isNoHostMenuActive"/>
    <RaisedHands class="raisedhand_box" v-if="isRaisedHandOverviewActive" />
    <ParticipantBox class="participant_box" v-if="isParticipantActive" />
    <SettingBox class="setting_box" v-if="isSettingMenuActive" />
    <ShareMedia class="share_media" v-if="isShareMediaActive" />
    <ParticipantVideo class="participant_video" v-if="isShowParticipantVideoActive" />
    <Chat class="chat_box" v-if="isChatBoxActive" />
    <CreatePoll class="create_poll" v-if="isPollActive" />
    <AnswerPoll class="answer_poll" v-if="isAnswerPollActive" />
    <StatCreatorPoll class="answer_poll" v-if="isStatCreatorPollActive" />
    <StatDisplayPoll class="answer_poll" v-if="isStatDisplayPollActive" />
    <GameSelection class="games_selection" v-if="isGamesActive" />
    <GameInvite class="games_selection" v-if="isGameInviteActive" />
    <GameInvitation class="games_selection" v-if="isGameInvitationActive" />
    <ScoreboardGTC class="score_quiz" v-if="isScoreboardGTCActive" />
    <PositionMenu class="position_menu" />
    <MiniCanvas class="participant_video" v-if="isSideCanvasActive" />
    <BroadcastMenu class="broadcast_menu" v-if="isBroadcastMenuActive"/>
    <BroadCast class="participant_video" v-if="isBroadCastActive" />
    <BroadcastMinglyMode class="broadcast_menu" v-if="isBroadcastMinglyModeActive" />
  </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex';
import Canvas from './_components/Canvas.vue';
import EnterVideo from './_components/EnterVideo.vue';
import store from './_store';
import CanvasMenu from './_components/CanvasMenu.vue';
import CanvasHostMenu from './_components/CanvasHostMenu.vue';
import CanvasNoHostMenu from './_components/CanvasNoHostMenu.vue';
import RaisedHands from './_components/RaisedHands.vue';
import ParticipantBox from './_components/ParticipantBox.vue';
import SettingBox from './_components/SettingBox.vue';
import ShareMedia from './_components/ShareMedia.vue';
import ParticipantVideo from './_components/ShowParticipantVideo.vue';
import Chat from './_components/textchat/Chat.vue';
import CreatePoll from './_components/poll/CreatePoll.vue';
import AnswerPoll from './_components/poll/AnswerPoll.vue';
import StatCreatorPoll from './_components/poll/StatCreatorPoll.vue';
import StatDisplayPoll from './_components/poll/StatDisplayPoll.vue';
import GameSelection from './_components/games/GameSelection.vue';
import GameInvite from './_components/games/GameInvite.vue';
import GameInvitation from './_components/games/GameInvitation.vue';
import PositionMenu from './_components/PositionMenu.vue';
import ScoreboardGTC from './_components/games/guessthecity/ScoreboardGTC.vue';
import MiniCanvas from './_components/MiniCanvas.vue';
import BroadcastMenu from './_components/broadcast/BroadcastMenu.vue';
import BroadCast from './_components/broadcast/BroadCast.vue';
import BroadcastMinglyMode from './_components/broadcast/BroadcastMinglyMode.vue';
import { iAmLeaving } from '../../assets/js/webrtc';

export default {
  name: 'index',
  components: {
    PositionMenu,
    GameInvite,
    GameInvitation,
    Canvas,
    EnterVideo,
    CanvasMenu,
    CanvasHostMenu,
    CanvasNoHostMenu,
    ParticipantBox,
    SettingBox,
    ShareMedia,
    ParticipantVideo,
    Chat,
    CreatePoll,
    AnswerPoll,
    StatCreatorPoll,
    StatDisplayPoll,
    GameSelection,
    ScoreboardGTC,
    MiniCanvas,
    BroadcastMenu,
    BroadCast,
    BroadcastMinglyMode,
    RaisedHands,
  },
  computed: {
    ...mapGetters('$_canvas', [
      'isChatBoxActive',
      'isParticipantActive',
      'isSettingMenuActive',
      'isCanvasViewActive',
      'isEnterVideoActive',
      'isShareMediaActive',
      'isShowParticipantVideoActive',
      'isBroadCastActive',
      'isPollActive',
      'isAnswerPollActive',
      'isStatCreatorPollActive',
      'isStatDisplayPollActive',
      'isGamesActive',
      'isGameInviteActive',
      'isGameInvitationActive',
      'isScoreboardGTCActive',
      'isSideCanvasActive',
      'isBroadcastMenuActive',
      'isBroadcastMinglyModeActive',
      'isNoHostMenuActive',
      'isRaisedHandOverviewActive',
    ]),
    ...mapGetters('$_userdata', ['backgroundImageUrl', 'host']),
  },
  beforeRouteLeave(to, from, next) {
    iAmLeaving();
    next();
  },
  async created() {
    const STORE_KEY = '$_canvas';
    // eslint-disable-next-line no-underscore-dangle
    if (!(STORE_KEY in this.$store._modules.root._children)) {
      this.$store.registerModule(STORE_KEY, store);
    }
  },
};
</script>

<style scoped>
  .background {
    background-size: cover;
    height: 100%
  }
  .top-right {
    position: fixed;
    top: 10vh;
    right: 1vh;
    bottom: 2vh;
    overflow-y: auto;
    overflow-x: hidden;
    white-space: nowrap;
    max-height: 75vh;
  }
  .host_menu {
    position: fixed;
    bottom: 2vh;
    left: 4vh;
  }
  .position_menu {
    position: fixed;
    bottom: 2vh;
    right: 2vh;
  }
  .participant_video {
    position: absolute;
  }
  /* .chat_box {
    position: absolute;
    bottom: 8vh;
    right: 40%;
    left: 3vh;
  } */
  /*
  .participant_box {
    position: absolute;
    left: 2vh;
    top: 15vh;
  }*/
  .setting_box {
    position: absolute;
    right: 11vh;
    top: 2vh;
  }
  .share_media {
    position: absolute;
    top: 15vh;
    left: 40%;
    right: 30%;
  }
  .broadcast_menu {
    position: absolute;
    top: 30vh;
    left: 30vh;
  }
  .create_poll {
    position: absolute;
    top: 15vh;
    left: 30vh;
  }
  .answer_poll {
    position: absolute;
    top: 15vh;
    left: 30vh;
  }
  .games_selection {
    position: absolute;
    top: 30vh;
    left: 30vh;
  }
  .score_quiz {
    position: absolute;
    top: 15vh;
    left: 30vh;
  }
</style>
