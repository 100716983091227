<template>
  <div class="background" v-bind:style="{ backgroundImage: 'url(' + backgroundImageUrl + ')' }">
    <!-- <div class="main-text">
      <h1>Mingly</h1>
      <h2>brings a new dimension to video conferences</h2>
    </div> -->
     <v-row class="video-boxes">
      <v-col cols="12" md="6" lg="6" xl="6">
        <div class="text-center">
          <h2>The Virtual Office</h2>
          <Video src="https://www.dropbox.com/s/yhghgarjafm81q3/Mingly%20-%20The%20virtual%20office%20V2.mp4?raw=1" />
        </div>
      </v-col>
    </v-row>
     <!-- <v-row class="video-boxes">
      <v-col cols="12" md="6" lg="6" xl="6">
        <div class="text-center">
          <h2>Introduction to Mingly</h2>
          <Video src="https://www.dropbox.com/s/57zbu29cmocnxx6/MinglyIntroV3.mp4?raw=1" />
        </div>
      </v-col>
    </v-row> -->
    <!-- <v-row class="video-boxes">
      <v-col cols="12" md="6" lg="6" xl="6">
        <div class="text-center">
          <h2>Logging into a room</h2>
          <Video src="https://www.dropbox.com/s/midz7831uaobcmg/LoginV2.mp4?raw=1" />
        </div>
      </v-col>
    </v-row>
    <v-row class="video-boxes">
      <v-col cols="12" md="6" lg="6" xl="6">
        <div class="text-center">
          <h2>Navigating in the room</h2>
          <Video src="https://www.dropbox.com/s/eojakvw2k30qkk6/NavigatingRoomV2.mp4?raw=1" />
        </div>
      </v-col>
    </v-row>
     <v-row class="video-boxes">
      <v-col cols="12" md="6" lg="6" xl="6">
        <div class="text-center">
          <h2>The menues</h2>
          <Video src="https://www.dropbox.com/s/1wbemasw1az7m8e/ButtonsV2.mp4?raw=1" />
        </div>
      </v-col>
    </v-row> -->
     <!-- <v-row class="video-boxes">
      <v-col cols="12" md="6" lg="6" xl="6">
        <div class="text-center">
          <h2>Common issues and how to fix them</h2>
          <Video src="https://www.youtube.com/embed/nhBVL41-_Cw" />
        </div>
      </v-col>
    </v-row> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Video from './_components/Video.vue';

export default {
  name: 'index',
  components: {
    Video,
  },
  computed: {
    ...mapGetters('$_userdata', ['backgroundImageUrl']),
  },
};
</script>

<style scoped>
.main-text {
  text-align: center;
  padding-top: 7vh;
}
.video-boxes {
  margin-top: 7vh;
  margin-left: 1vh;
  margin-right: 1vh;
}
.text {
  margin-top: 30vh;
  font-size: x-large;
}
.background {
  background-size: cover;
  height: 101%
}
</style>
