<template>
  <div class="text-center">
    <v-row>
      <v-col>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-if="!isRaiseHandActive"
                   fab dark v-on="on" v-bind="attrs" v-on:click="raiseMyHand()">
              <v-icon color="white">
                mdi-hand-back-left
              </v-icon>
            </v-btn>
             <v-btn v-else
                   fab dark v-on="on" v-bind="attrs" v-on:click="raiseMyHand()">
              <v-icon color="orange lighten-1">
                mdi-hand-back-left
              </v-icon>
            </v-btn>
          </template>
          <span>Host menu</span>
        </v-tooltip>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// To do here:
// Create clicked on to change color on buttons
// when they are activated
import { mapActions, mapGetters } from 'vuex';
import {
  // eslint-disable-next-line no-unused-vars
  raiseHand,
} from '../../../assets/js/webrtc';

export default {
  name: 'CanvasNoHostMenu',
  computed: {
    ...mapGetters('$_canvas', [
      'isRaiseHandActive',
    ]),
    ...mapGetters('$_userdata', [
      'host',
    ]),
  },
  methods: {
    ...mapActions('$_canvas', [
      'commitRaiseHandActive',
    ]),
    // ...mapActions('$_userdata', [
    //   'commitSetChatBox',
    //   'commitSetTotalMessageCount',
    // ]),
    raiseMyHand() {
      this.commitRaiseHandActive();
      raiseHand();
    },
  },
};
</script>

<style scoped>
  ::-webkit-scrollbar {
    width: 5px;
    padding-left: 2px;
  }
  ::-webkit-scrollbar-thumb {
    background: #b9b9b9;
  }
</style>
