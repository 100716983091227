<template>
  <div>
    <v-row class="padding-row">
       <v-col class="padding-col">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn small dark v-on="on" v-bind="attrs" v-on:click="activateSideCanvas()">
              <v-icon>
                mdi-earth-box
              </v-icon>
            </v-btn>
          </template>
          <span>Overview Map</span>
        </v-tooltip>
      </v-col>
      <v-col class="padding-col">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn small dark v-on="on" v-bind="attrs" v-on:click="zoomIn()">
              <v-icon>
                mdi-plus
              </v-icon>
            </v-btn>
          </template>
          <span>Zoom in</span>
        </v-tooltip>
      </v-col>
      <v-col class="padding-col">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn small dark v-on="on" v-bind="attrs" v-on:click="zoomReset()">
              <v-icon>
                mdi-arrow-expand
              </v-icon>
            </v-btn>
          </template>
          <span>Reset Zoom</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row class="padding-row">
      <v-col class="padding-col">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn small dark v-on="on" v-bind="attrs" v-on:click="cursorMode()">
              <v-icon>
                mdi-cursor-default
              </v-icon>
            </v-btn>
          </template>
          <span>Show cursor</span>
        </v-tooltip>
      </v-col>
      <v-col class="padding-col">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn small dark v-on="on" v-bind="attrs" v-on:click="zoomOut()">
              <v-icon>
                mdi-minus
              </v-icon>
            </v-btn>
          </template>
          <span>Zoom out</span>
        </v-tooltip>
      </v-col>
      <v-col class="padding-col">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn small dark v-on="on" v-bind="attrs" v-on:click="zoomToMe()">
              <v-icon>
                mdi-image-filter-center-focus
              </v-icon>
            </v-btn>
          </template>
          <span>Zoom to your position</span>
        </v-tooltip>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'PositionMenu',
  methods: {
    ...mapActions('$_canvas', [
      'commitSetSideCanvasActive']),
    zoomIn() {
      window.mingly.board.zoom(5, true);
    },
    zoomOut() {
      window.mingly.board.zoom(-5, true);
    },
    zoomToMe() {
      window.mingly.board.zoomtoParticipant(window.mingly.board.me);
    },
    zoomReset() {
      window.mingly.board.resetZoom();
    },
    cursorMode() {
      window.mingly.board.me.activateCursor();
    },
    activateSideCanvas() {
      this.commitSetSideCanvasActive();
    },
  },
};
</script>

<style scoped>
  .padding-row {
    padding: 0;
  }
  .padding-col {
    padding-right: 0.15vh;
    padding-left: 0.15vh;
    padding-top: 0.7vh;
    padding-bottom: 0.7vh;
  }
</style>
