<template>
<div>
    <v-card class="camera_box" width="240" height="240">
      <div class="placeholder">
        <video id="videoElement" height="100%" muted autoplay playsinline ref="localVideo"/>
      </div>
    </v-card>
</div>
</template>

<script>
// import adapter from 'webrtc-adapter';

export default {
  name: 'EnterVideo',
  mounted() {
    const vid = window.mingly.board.me.videoElement.srcObject;
    this.$refs.localVideo.srcObject = vid;
  },
};
</script>

<style scoped>
  .placeholder {
    background-color: lightgray;
    overflow: hidden;
    width: 240px;
    height: 240px;
  }
  .camera_box {
    position: absolute;
    left: 40%;
    top: 40%;
    margin: 0%;
  }
</style>
