<template>
  <div>
    <v-card class="mx-auto username_box pa-4" max-width="500">
      <v-card-actions class="padding">
        <v-text-field label="Name"
                      v-model="username"
                      v-on:keydown.enter="selectCamera()"
                      required
                      filled
                      rounded
                      hide-details
                      prepend-inner-icon="mdi-account-circle" />
      </v-card-actions>
      <v-card-actions class="justify-center padding_bot padding">
        <v-btn dark elevation="8" large class="btn_width" v-on:click="selectCamera">
          Continue
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'EnterName',
  data: () => ({
    username: '',
  }),
  methods: {
    ...mapActions('$_userdata', ['commitSetUsername', 'commitSetHost']),
    selectCamera() {
      // TODO: Validate username first
      this.commitSetUsername(this.username);
      if (this.username === 'Christian H-L') {
        this.commitSetHost();
      }
      this.$router.push({ name: 'SelectMicrophone' });
    },
  },
};
</script>

<style scoped>
.username_box {
  margin-top: 33vh;
}
.btn_width {
  width: 60%;
}
.padding {
  padding-top: 2vh;
}
.padding_bot {
  padding-bottom: 2vh;
}
</style>
