<template>
  <div class="background" v-bind:style="{ backgroundImage: 'url(' + backgroundImageUrl + ')' }">
    <v-row>
      <v-col>
        <MicrophoneCard />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MicrophoneCard from './_components/MicrophoneCard.vue';

export default {
  name: 'index',
  components: { MicrophoneCard },
  computed: {
    ...mapGetters('$_userdata', ['backgroundImageUrl']),
  },
};
</script>

<style scoped>
.background {
  background-size: cover;
  height: 101%
}
</style>
