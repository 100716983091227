<template>
  <div>
    <v-card dark>
      <v-btn v-on:click="backToMenu()">
        <v-icon>
          mdi-arrow-left
        </v-icon>
      </v-btn>
      <div class="padding">
        You selected: {{ this.game }} <br/>
        Please select participants:
        <div v-for="(participant, index) in this.participants.getUsersAndPictures()" :key="index">
          <v-card-actions>
            <v-checkbox
              :label=participant.username
              :value=participant.userID
              v-model="checkedAlternatives"
              class="ma-0" />
          </v-card-actions>
        </div>
        <v-btn v-on:click="sendInvites()">Invite</v-btn>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { sendGameInvite } from '../../../../assets/js/webrtc';

export default {
  name: 'GameInvite',
  computed: {
    ...mapGetters('$_userdata', [
      'participants',
    ]),
    ...mapGetters('$_canvas', [
      'game', 'gamaData',
    ]),
  },
  methods: {
    ...mapActions('$_canvas', [
      'commitSetGameInviteActive', 'commitSetGamesActive', 'commitSetGameData',
      'commitSetScoreBoardGTCActive',
    ]),
    backToMenu() {
      this.commitSetGameInviteActive();
      this.commitSetGamesActive();
    },
    sendInvites() {
      // TODO: send out invites to everyone in this.checkedAlternatives object.
      // TODO: this will also open the GameInvitation.vue card for the participants invited
      const gameDat = {
        game: this.game, // should have variable here
        creatorId: window.mingly.board.me.uuid,
        invitedPlayers: this.checkedAlternatives,
        players: [{
          userId: window.mingly.board.me.uuid,
          name: window.mingly.board.me.name,
          score: 0,
        }],
      };
      this.commitSetGameData(gameDat);
      sendGameInvite(gameDat);
      this.commitSetGameInviteActive();
      this.commitSetScoreBoardGTCActive();
    },
  },
  data: () => ({
    checkedAlternatives: [],
  }),
};
</script>

<style scoped>
  .padding {
    padding: 2vh;
  }
</style>
