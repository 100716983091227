const username = (state) => state.username;
const userID = (state) => state.userID;
const host = (state) => state.host;
const room = (state) => state.room;
const menuItems = (state) => state.menuItems;
const profilePicture = (state) => state.profilePicture;
const videovar = (state) => state.videovar;
const hoover = (state) => state.hoover;
const participants = (state) => state.participants;
const messages = (state) => state.messages;
const totalUnreadMessages = (state) => state.totalUnreadMessages;
const chatActive = (state) => state.chatActive;
const pollData = (state) => state.pollData;
const pollResults = (state) => state.pollResults;
const backgroundImageUrl = (state) => state.backgroundImageUrl;

export default {
  username,
  userID,
  host,
  room,
  menuItems,
  profilePicture,
  videovar,
  hoover,
  participants,
  messages,
  totalUnreadMessages,
  chatActive,
  pollData,
  pollResults,
  backgroundImageUrl,
};
