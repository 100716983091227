<template>
  <div>
    <v-card class="card-style">
      <div class="box-style">
        {{ text }}
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'TextBox',
  props: {
    text: String,
  },
};
</script>

<style scoped>
  .box-style {
    padding: 1vh;
  }
  .card-style {
    /* background-color: rgba(255,255,255,.6);
    color: #000; */
    background-color:#203e5fff;
    color: #eac252;
  }
</style>
