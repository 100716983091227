var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"padding-row"},[_c('v-col',{staticClass:"padding-col"},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","dark":""},on:{"click":function($event){return _vm.activateSideCanvas()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-earth-box ")])],1)]}}])},[_c('span',[_vm._v("Overview Map")])])],1),_c('v-col',{staticClass:"padding-col"},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","dark":""},on:{"click":function($event){return _vm.zoomIn()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-plus ")])],1)]}}])},[_c('span',[_vm._v("Zoom in")])])],1),_c('v-col',{staticClass:"padding-col"},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","dark":""},on:{"click":function($event){return _vm.zoomReset()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-arrow-expand ")])],1)]}}])},[_c('span',[_vm._v("Reset Zoom")])])],1)],1),_c('v-row',{staticClass:"padding-row"},[_c('v-col',{staticClass:"padding-col"},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","dark":""},on:{"click":function($event){return _vm.cursorMode()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-cursor-default ")])],1)]}}])},[_c('span',[_vm._v("Show cursor")])])],1),_c('v-col',{staticClass:"padding-col"},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","dark":""},on:{"click":function($event){return _vm.zoomOut()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-minus ")])],1)]}}])},[_c('span',[_vm._v("Zoom out")])])],1),_c('v-col',{staticClass:"padding-col"},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","dark":""},on:{"click":function($event){return _vm.zoomToMe()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-image-filter-center-focus ")])],1)]}}])},[_c('span',[_vm._v("Zoom to your position")])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }