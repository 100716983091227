var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c('v-row',[_c('v-col',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(!_vm.isHostMenuActive)?_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":""},on:{"click":function($event){return _vm.makeHostMenuActive()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-account-hard-hat ")])],1):_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":""},on:{"click":function($event){return _vm.makeHostMenuActive()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"orange lighten-1"}},[_vm._v(" mdi-account-hard-hat ")])],1)]}}])},[_c('span',[_vm._v("Host menu")])])],1),(this.isHostMenuActive)?_c('v-col',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(!_vm.isHostSettingsActive)?_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":""},on:{"click":function($event){return _vm.hostSettings()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-menu ")])],1):_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":""},on:{"click":function($event){return _vm.hostSettings()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"orange lighten-1"}},[_vm._v(" mdi-menu ")])],1)]}}],null,false,1281860909)},[_c('span',[_vm._v("Host settings")])])],1):_vm._e(),(this.isHostMenuActive)?_c('v-col',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(!_vm.isLockPositionActive)?_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":""},on:{"click":function($event){return _vm.placeAndLock()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-account-switch ")])],1):_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":""},on:{"click":function($event){return _vm.placeAndLock()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"orange lighten-1"}},[_vm._v(" mdi-account-switch ")])],1)]}}],null,false,4248787258)},[_c('span',[_vm._v("Place and lock participants to position")])])],1):_vm._e(),(this.isHostMenuActive)?_c('v-col',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(!_vm.isMuteAllActive)?_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":""},on:{"click":function($event){return _vm.muteAll()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-volume-mute ")])],1):_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":""},on:{"click":function($event){return _vm.muteAll()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"orange lighten-1"}},[_vm._v(" mdi-volume-mute ")])],1)]}}],null,false,3543500416)},[_c('span',[_vm._v("Mute all")])])],1):_vm._e(),(this.isHostMenuActive)?_c('v-col',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(!_vm.isNoVideoAllActive)?_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":""},on:{"click":function($event){return _vm.turnOffVideoAll()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-video-off ")])],1):_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":""},on:{"click":function($event){return _vm.turnOffVideoAll()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"orange lighten-1"}},[_vm._v(" mdi-video-off ")])],1)]}}],null,false,3440533209)},[_c('span',[_vm._v("Turn everyones video off")])])],1):_vm._e(),(this.isHostMenuActive)?_c('v-col',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(!_vm.isBroadCastMeActive)?_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":""},on:{"click":function($event){return _vm.broadCast()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-account-box-outline ")])],1):_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":""},on:{"click":function($event){return _vm.broadCast()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"orange lighten-1"}},[_vm._v(" mdi-account-box-outline ")])],1)]}}],null,false,1794141087)},[_c('span',[_vm._v("Broadcast your video and audio")])])],1):_vm._e(),(this.isHostMenuActive)?_c('v-col',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(!_vm.isFollowMyViewActive)?_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":""},on:{"click":function($event){return _vm.followMyView()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-glasses ")])],1):_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":""},on:{"click":function($event){return _vm.followMyView()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"orange lighten-1"}},[_vm._v(" mdi-glasses ")])],1)]}}],null,false,282795072)},[_c('span',[_vm._v("Follow my view")])])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }