<template>
  <div>
    <v-card class="mx-auto pa-4 microphone_card" max-width="500">
      <v-select :items="microphones" v-model="microphone" return-object
                v-on:change="changeMicrophone"
                label="Select microphone"
                class="dropdown_width mx-auto padding" />
      <h2 class="text-center padding padding_bot">
        Continue when you see the bar moving when you talk.
      </h2>
      <v-progress-linear rounded v-model="meterValue" height="10" color="red" />
      <v-card-actions class="justify-center padding_bot padding">
        <v-btn dark elevation="8" large class="btn_width" v-on:click="saveMicrophoneSelection">
          Continue
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'MicrophoneCard',
  data: () => ({
    audioConstraints: {
      audio: {
        optional: [{
          sourceId: '',
        }],
      },
      video: false,
    },
    microphone: {
      text: localStorage.getItem('audio_input_name'),
      value: localStorage.getItem('audio_input_id'),
    },
    microphones: [],
    meterValue: 0,
  }),
  mounted() {
    console.log(localStorage.getItem('audio_input_name'));
    this.getMicrophones();
    this.changeMicrophone({ text: this.microphone.text, value: this.microphone.value });
  },
  methods: {
    getMicrophones() {
      if (navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices.enumerateDevices()
          .then((devices) => {
            devices.forEach((device) => {
              if (device.kind === 'audioinput') {
                this.microphones.push({ text: device.label, value: device.deviceId });
              }
            });
          })
          .catch((err) => {
            console.error(`error: ${err.name}: ${err.message}`);
          });
      } else {
        // todo: alert the user that we didn't find any microphones?
      }
    },
    changeMicrophone(microphoneSelection) {
      this.setMicrophone(microphoneSelection.value);
    },
    saveMicrophoneSelection() {
      window.mingly.audioSource = this.microphone.value;
      localStorage.setItem('audio_input_name', this.microphone.text);
      localStorage.setItem('audio_input_id', this.microphone.value);
      this.$router.push({ name: 'SelectSpeaker' });
    },
    setMicrophone(deviceId) {
      this.audioConstraints.audio.optional.sourceId = deviceId;

      // todo: probably does the trick to do this once?
      window.AudioContext = window.AudioContext || window.webkitAudioContext;

      const audioContext = new AudioContext();

      navigator.mediaDevices.getUserMedia(this.audioConstraints)
        .then((stream) => {
          const mediaStreamSource = audioContext.createMediaStreamSource(stream);
          const meter = this.createAudioMeter(audioContext);
          mediaStreamSource.connect(meter);
        })
        .catch((err) => {
          console.error(`${err.name}: ${err.message}`);
        });
    },
    createAudioMeter(audioContext, clipLevel, averaging, clipLag) {
      // todo: we're using deprecated functions!
      // https://developer.mozilla.org/en-US/docs/Web/API/BaseAudioContext/createScriptProcessor
      const processor = audioContext.createScriptProcessor(512);
      const self = this; // eslint-disable-line no-unused-vars

      processor.onaudioprocess = function (event) {
        const buf = event.inputBuffer.getChannelData(0);
        const bufLength = buf.length;
        let sum = 0;
        let x;

        // Do a root-mean-square on the samples: sum up the squares...
        // todo: alter this code to make eslint happy
        /* eslint no-plusplus: ["error", { "allowForLoopAfterthoughts": true }] */
        for (let i = 0; i < bufLength; i++) {
          x = buf[i];
          if (Math.abs(x) >= this.clipLevel) {
            this.clipping = true;
            this.lastClip = window.performance.now();
          }
          sum += x * x;
        }

        // ... then take the square root of the sum.
        const rms = Math.sqrt(sum / bufLength);

        // Now smooth this out with the averaging factor applied
        // to the previous sample - take the max here because we
        // want "fast attack, slow release."
        this.volume = Math.max(rms, this.volume * this.averaging);
        // meterComponent = this.volume * 100;
        // console.log(Math.trunc(this.volume * 100));
        self.setMeter(Math.trunc(this.volume * 100));
      };

      processor.clipping = false;
      processor.lastClip = 0;
      processor.volume = 0;
      processor.clipLevel = clipLevel || 0.98;
      processor.averaging = averaging || 0.95;
      processor.clipLag = clipLag || 750;

      // this will have no effect, since we don't copy the input to the output,
      // but works around a current Chrome bug.
      processor.connect(audioContext.destination);

      processor.checkClipping = function () {
        if (!this.clipping) {
          return false;
        }

        if ((this.lastClip + this.clipLag) < window.performance.now()) {
          this.clipping = false;
        }

        return this.clipping;
      };

      processor.shutdown = function () {
        this.disconnect();
        this.onaudioprocess = null;
      };

      return processor;
    },
    setMeter(value) {
      // change to times 5 not sure good but
      this.meterValue = value * 5;
    },
  },
};
</script>

<style scoped>
.microphone_card {
  margin-top: 33vh;
}
.dropdown_width {
  width: 80%;
}
.btn_width {
  width: 60%;
}
.padding {
  padding-top: 2vh;
}
.padding_bot {
  padding-bottom: 2vh;
}
</style>
