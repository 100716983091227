<template>
  <div class="background">
    <v-row>
      <v-col>
        <PictureCard />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import PictureCard from './_components/PictureCard.vue';

export default {
  name: 'index',
  components: { PictureCard },
};
</script>

<style scoped>
.background {
  background-image: url('../../assets/login_background.jpg');
  background-size: cover;
  height: 101%
}
</style>
