import {
  SET_USERNAME,
  SET_USER_ID,
  SET_HOST,
  SET_ROOM,
  SET_PROFILE_PICTURE,
  SET_VIDEOVAR,
  SET_HOOVER,
  ADD_PARTICIPANT,
  REMOVE_PARTICIPANT,
  ADD_MESSAGE,
  SET_CHAT_ACTIVE,
  SET_NEWNAME_PARTICIPANT,
  SET_NEWPROFILEPIC_PARTICIPANT,
  SET_CHAT_BOX_ACTIVE,
  SET_TOTAL_MESSAGE_COUNT,
  SET_POLL,
  SET_POLL_RESULTS,
  SET_MENU_ITEMS,
  SET_BACKGROUND_IMAGE,
} from './mutations';

export const commitSetUsername = 'commitSetUsername';
export const commitSetUserID = 'commitSetUserID';
export const commitSetHost = 'commitSetHost';
export const commitSetRoom = 'commitSetRoom';
export const commitProfilePicture = 'commitProfilePicture';
export const commitVideoVar = 'commitVideoVar';
export const commitHoover = 'commitHoover';
export const commitAddParticipant = 'commitAddParticipant';
export const commitRemoveParticipant = 'commitRemoveParticipant';
export const commitAddMessage = 'commitAddMessage';
export const commitSetChatActive = 'commitSetChatActive';
export const commitChangeNameParticipant = 'commitChangeNameParticipant';
export const commitChangeProfilePicture = 'commitChangeProfilePicture';
export const commitSetChatBox = 'commitSetChatBox';
export const commitSetTotalMessageCount = 'commitSetTotalMessageCount';
export const commitSetPoll = 'commitSetPoll';
export const commitPollResults = 'commitPollResults';
export const commitMenuItems = 'commitMenuItems';
export const commitSetBackgroundImage = 'commitSetBackgroundImage';
export const commitAddRaisedHand = 'commitAddRaisedHand';
export const commitRemoveRaisedHand = 'commitRemoveRaisedHand';

export default {
  [commitSetChatBox](context) {
    context.commit(SET_CHAT_BOX_ACTIVE);
  },
  [commitSetUsername](context, username) {
    context.commit(SET_USERNAME, username);
  },
  [commitSetUserID](context, userID) {
    context.commit(SET_USER_ID, userID);
  },
  [commitSetHost](context) {
    context.commit(SET_HOST);
  },
  [commitSetRoom](context, room) {
    context.commit(SET_ROOM, room);
  },
  [commitProfilePicture](context, profilePicture) {
    context.commit(SET_PROFILE_PICTURE, profilePicture);
  },
  [commitVideoVar](context, data) {
    context.commit(SET_VIDEOVAR, data);
  },
  [commitHoover](context, data) {
    context.commit(SET_HOOVER, data);
  },
  [commitAddParticipant](context, data) {
    context.commit(ADD_PARTICIPANT, data);
  },
  [commitRemoveParticipant](context, id) {
    context.commit(REMOVE_PARTICIPANT, id);
  },
  [commitAddMessage](context, payload) {
    context.commit(ADD_MESSAGE, payload);
  },
  [commitSetChatActive](context, id) {
    context.commit(SET_CHAT_ACTIVE, id);
  },
  [commitChangeNameParticipant](context, data) {
    context.commit(SET_NEWNAME_PARTICIPANT, data);
  },
  [commitChangeProfilePicture](context, data) {
    context.commit(SET_NEWPROFILEPIC_PARTICIPANT, data);
  },
  [commitSetTotalMessageCount](context, count) {
    context.commit(SET_TOTAL_MESSAGE_COUNT, count);
  },
  [commitSetPoll](context, data) {
    context.commit(SET_POLL, data);
  },
  [commitPollResults](context, data) {
    context.commit(SET_POLL_RESULTS, data);
  },
  [commitMenuItems](context, data) {
    context.commit(SET_MENU_ITEMS, data);
  },
  [commitSetBackgroundImage](context, data) {
    context.commit(SET_BACKGROUND_IMAGE, data);
  },
};
