<template>
  <div>
    <vue-drag-resize :isActive="this.isScreenActive"
                     :isResizable="false"
                     :w="this.width" :h="this.height"
                    :x="this.left" :y="this.top"
                    v-on:deactivated="onDeactivated"
                   v-on:activated="onActivated">
    <v-card dark class="box" :max-height="this.height" :max-width="this.width">
      <CloseButton :method="closeParticipantBox" class="close_button" />
      <v-list>
        <v-list-item>
          <v-avatar size="36" class="avatar">
            <v-img :src="this.profilePicture.src" />
          </v-avatar>
          <div class="own_color">
            {{ username }}
          </div>
        </v-list-item>
        <v-list-item v-for="(person, index) in participants.getUsersAndPictures()" :key="index">
          <v-avatar size="36" class="avatar">
            <v-img :src="person.picture.src" />
          </v-avatar>
          <div
               @mouseover="mouseOver(person.userID)"
               @mouseleave="mouseLeave(person.userID)"
               class="other_part"
               v-on:click="separateScreen(person.userID)">
          {{ person.username }}
          </div>
        </v-list-item>
      </v-list>
    </v-card>
  </vue-drag-resize>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import VueDragResize from 'vue-drag-resize';
import CloseButton from './CloseButton.vue';

export default {
  name: 'ParticipantBox',
  data() {
    return {
      top: 0.15 * window.innerHeight,
      left: 0.02 * window.innerWidth,
      width: 0.17 * window.innerWidth,
      height: 0.3 * window.innerHeight,
      isScreenActive: false,
    };
  },
  components: {
    CloseButton, VueDragResize,
  },
  methods: {
    ...mapActions('$_canvas', [
      'commitSetShowParticipantVideoActive',
      'commitSetParticipantActive',
    ]),
    closeParticipantBox() {
      this.commitSetParticipantActive();
    },
    mouseOver(id) {
      window.mingly.board.participants[id].hoover = true;
    },
    mouseLeave(id) {
      window.mingly.board.participants[id].hoover = false;
    },
    onDeactivated() {
      this.isScreenActive = false;
    },
    onActivated() {
      const that = this;
      that.isScreenActive = true;
      window.mingly.board.move_keyboard = false;
      setTimeout(() => {
        that.isScreenActive = false;
      }, 2000);
    },
    separateScreen(id) {
      this.commitSetShowParticipantVideoActive({
        userID: id,
        videovar: window.mingly.board.participants[id].videovar,
      });
      console.log(`separate screen ${id}`);
    },
  },
  computed: {
    ...mapGetters('$_userdata', ['participants', 'username', 'profilePicture', 'videovar', 'hoover'], '$_canvas', [
      'isShowParticipantVideoActive', 'user']),
  },
};
</script>

<style scoped>
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background: #b9b9b9;
  }
  .box {
    overflow: auto;
    white-space: nowrap;
  }
  .avatar {
    margin-right: 1vh;
  }
  .own_color {
    color: green;
  }
  .other_part:hover {
    color: red;
  }
  .close_button {
    z-index: 1;
    position: absolute;
    right: 0;
    top: 0;
  }
</style>
