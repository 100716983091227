// disable some linting, for readability (or just being lazy)
/* eslint-disable operator-linebreak, padded-blocks */
export default function roomCustomPartySetup(roomName, board) {
  if (roomName === 'Party' || roomName === 'PartyTURN') {

    board.addColor('r', 'rgba(255,50,10,0.8)');
    board.addColor('y', 'rgba(220,200,10,0.8)');
    board.addColor('w', 'white');

    const boardImage =
      '                                ' +
      '                                ' +
      '            r   r r r rrr r rrr ' +
      '            rr rr r r r   r r   ' +
      '            r r r r r rrr r r   ' +
      '            r   r r r   r r r   ' +
      '            r   r rrr rrr r rrr ' +
      '                                ' +
      '                                ' +
      '                                ' +
      '                                ' +
      '                                ' +
      '                                ' +
      '                                ' +
      '                                ' +
      '                                ' +
      '                                ' +
      '                                ' +
      '                                ' +
      '                                ' +
      '   yyyyyyy                      ' +
      '  yyyyyyyyy                     ' +
      ' yywywywywyy                    ' +
      'yyyywyyywyyyy                   ' +
      'yyywywywywyyy                   ' +
      'yyyyyyyyyyyyy                   ' +
      'yyyyyyyyyyyyy                   ' +
      'yyywyyyyywyyy                   ' +
      ' yywwwwwwwyy                    ' +
      '  yyyyyyyyy                     ' +
      '   yyyyyyy                      ' +
      '                                ';

    board.drawImage(boardImage);
  }
}
