<template>
  <div>
    <v-card class="mx-auto camera_box pa-4" max-width="600">
      <v-card-actions v-if="!pictureTaken" class="justify-center padding">
        <CameraVideo ref="CameraVideo" />
      </v-card-actions>
      <div v-else class="padding">
        <v-img :src="image" width="240" height="240" class="center-image" />
      </div>
      <v-select :items="cameras" v-model="camera" return-object v-on:change="changeCamera"
                label="Select Camera" class="dropdown_width mx-auto" />
      <v-card-actions class="justify-center padding_bot">
        <v-btn v-if="!pictureTaken" dark large elevation="8" v-on:click="capturePhoto">
          take photo
        </v-btn>
        <v-btn v-else dark elevation="8" large v-on:click="retakePhoto">
          retake photo
        </v-btn>
        <v-btn dark elevation="8" large v-on:click="saveCameraSelection">
          Continue with selected camera
        </v-btn>
        <!-- v-btn dark elevation="8" large class="btn_width" v-on:click="capturePhoto">
          Capture photo
          <v-icon right>
            mdi-camera
          </v-icon>
        </v-btn -->
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
// import adapter from 'webrtc-adapter';
import CameraVideo from './CameraVideo.vue';
import { takeProfilePic, getBase64Image } from '../../../assets/js/webrtc';

export default {
  name: 'CameraSelection',
  components: { CameraVideo },
  data() {
    return {
      pictureTaken: false,
      image: {},
      basePic: {},
      camera: {
        text: localStorage.getItem('camera_name') || 'default',
        value: localStorage.getItem('camera_id') || 'default',
      },
      cameras: [],
    };
  },
  mounted() {
    this.basePic = new Image();
    this.basePic.src = this.iconUrl;
    this.getCameras();
  },
  computed: {
    iconUrl() {
      // eslint-disable-next-line global-require
      // return require('../../../assets/TempProfile.png');
      // eslint-disable-next-line global-require
      return require('../../../assets/SquareLogo.png');
      // eslint-disable-next-line global-require
      // return require('../../../assets/temporary_profile_img.jpg');
    },
  },
  methods: {
    ...mapActions('$_userdata', ['commitProfilePicture']),
    getCameras() {
      if (navigator.mediaDevices.getUserMedia) {
        // console.log(adapter.browserDetails.browser);
        navigator.mediaDevices.enumerateDevices()
          .then((devices) => {
            devices.forEach((device) => {
              if (device.kind === 'videoinput') {
                this.cameras.push({
                  text: device.label,
                  value: device.deviceId,
                });
              }
            });

            this.changeCamera(this.camera);
          })
          .catch((err) => {
            // eslint-disable-next-line no-console
            console.log(`error: ${err.name}: ${err.message}`);
          });
      } else {
        // todo: alert the user that we didn't find any cameras?
      }
    },
    changeCamera(cameraSelection) {
      this.$refs.CameraVideo.setCamera(cameraSelection.value);
    },
    retakePhoto() {
      this.pictureTaken = false;
      this.image.src = null;
      this.getCameras();
    },
    capturePhoto() {
      if (this.$refs.CameraVideo.checkReady()) {
        const profilePicCanvas = document.createElement('Canvas');
        this.image.src = takeProfilePic(
          profilePicCanvas,
          document.querySelector('video'),
          240,
        ).src;
        // stopping the media stream
        // otherwise some cameras and browser will use the old constraints
        // with the mediaserver
        const video = document.querySelector('video');
        const mediaStream = video.srcObject;
        const tracks = mediaStream.getTracks();
        tracks.forEach((track) => track.stop());
        // end of stopping tracks
        const imgData = getBase64Image(profilePicCanvas);
        localStorage.setItem('imgData', imgData);
        this.pictureTaken = true;
        this.commitProfilePicture(this.image);
      } else {
        console.log('Camera not ready');
      }
    },
    saveCameraSelection() {
      if (this.pictureTaken) {
        window.mingly.videoSource = this.camera.value;
        localStorage.setItem('camera_name', this.camera.text);
        localStorage.setItem('camera_id', this.camera.value);
        this.$router.push({ name: 'Canvas' });
      } else {
        console.log('picture not taken');
        if (this.$refs.CameraVideo.checkReady()) {
          window.mingly.videoSource = this.camera.value;
          if (!this.image.src) {
            console.log('no profile pic taken');
            console.log(this.basePic);
            console.log(this.basePic.width);
            console.log(this.basePic.height);
            // const img = new Image();
            // img.src = this.iconUrl;
            // img.onerror = function (error) {
            //   console.log('there was an error loading the image', error);
            //   console.log(img);
            // };
            // img.onload = function () {
            const profilePicCanvas = document.createElement('Canvas');
            profilePicCanvas.width = 240;
            profilePicCanvas.height = 240;
            const context = profilePicCanvas.getContext('2d');
            context.arc(120,
              120,
              120,
              0,
              Math.PI * 2,
              true);
            context.clip();
            context.drawImage(this.basePic, 0, 0, 240, 240);
            const imgData = getBase64Image(profilePicCanvas);
            localStorage.setItem('imgData', imgData);
            this.image.src = profilePicCanvas.toDataURL('image/png');
            this.commitProfilePicture(this.image);
            localStorage.setItem('camera_name', this.camera.text);
            localStorage.setItem('camera_id', this.camera.value);
            // stopping the media stream
            // otherwise some cameras and browser will use the old constraints
            // with the mediaserver
            const video = document.querySelector('video');
            const mediaStream = video.srcObject;
            const tracks = mediaStream.getTracks();
            tracks.forEach((track) => track.stop());
            // end of stopping tracks
            this.$router.push({ name: 'Canvas' });
            // };
          }
        } else {
          console.log('Camera or picture not ready');
        }
      }
    },
  },
};
</script>

<style scoped>
  .camera_box {
    margin-top: 23vh;
  }
  .dropdown_width {
    width: 80%;
  }
  .padding_bot {
    padding-bottom: 2vh;
  }
  .padding {
    padding-top: 2vh;
  }
  .center-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }
</style>
