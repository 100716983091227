// Temporary disables - we need to enable these further down the cleanup road:
/* eslint-disable no-param-reassign */

// todo: get rid of Math.pow?
/* eslint-disable no-restricted-properties */

// Magnus' preferences, up for discussion:
/* eslint-disable brace-style,padded-blocks,operator-linebreak,function-paren-newline */
/* eslint-disable no-lonely-if */

// we need extensions for this to work in the legacy Mingly setup
import Board from './board.js'; // eslint-disable-line import/extensions
import {
  createUUID,
  answerPoll,
  answerQuiz,
  callParticipant,
  closeMSbroadCast,
} from './webrtc.js'; // eslint-disable-line import/extensions
import CanvasObject from './canvas_objects.js'; // eslint-disable-line import/extensions

// the Chessboard class extends, or basically overrides, definitions from the Board base class
export default class Canvasboard extends Board {

  // override functions from super class
  constructor(parentContainer) {
    super(parentContainer);

    // override properties from super class
    this.avatarSize = 40;
    this.avatarRadius = this.avatarSize / 2;
    this.maxSpeed = this.avatarSize;
    this.scalingFactor = this.avatarSize;

    // Canvasboard's own properties
    this.width = 1400;
    this.height = 800;
    this.minZoom = 0.6; // how far you can zoom out
    this.maxZoom = 100; // how far you can zoom in
    this.mouseTimer = null;
    this.minMouseJump = 40; // jumps the last minMouseJump pixels
    this.dragged = false;
    this.dragStart = null;
    this.lastX = this.width / 2;
    this.lastY = this.height / 2;
    this.scaleFactor = 1.05;
    this.focusFactor = 5;
    // eslint-disable-next-line max-len
    this.fullscreenMode = false; // this allows for fullscreen, but does not indicate if you are in fullscreen
    this.hoverMode = true;
    // eslint-disable-next-line max-len
    this.fullscreenNow = false; // indicates that you are currently viewing fullscreen of a participant
    this.keyZoomFactor = 5;
    this.opacityLevel = 0.7;
    // we used to have a videoUpdateDelay of 10. Note sure anymore why?
    this.videoUpdateDelay = 10; // a delay after modifying the canvas through zoom and drag
    this.periodicResetVideo = 60000; // reset the requestanimationframe
    this.dragMe = false;
    this.dragMeStartx = null;
    this.dragMeStarty = null;
    this.stretchSize = 20;
    // the next tree should be grouped I think
    this.videoBorderWidth = 0;
    this.doBackground = false;
    this.videoBackGroundStyle = null;

    this.doIntroLocation = true;
    this.ongoingTouches = []; // for touchscreen
    this.moreTouch = false;
    this.initialDistTouch = 0;
    this.displayVideo = false;
    this.backgroundVideo = {};
    // to place object on the canvas
    this.placeingObject = {
      isPlacing: false,
      placingFunction: null,
    };
    // for quiz modes (games and polls on canvas)
    this.quiz = {
      inQuizMode: false,
      alternatives: null,
      data: {},
      hasAnswered: false,
    };

    // some mathematical constants
    this.onePointTwo = 1.2;

    // events
    this.onUpdateConnections = null; // function(bool moveMe)
    this.onMuteDistantParticipants = null; // function()
    this.onHoover = null; // triggered when hoover over a participant
    this.onHooverClick = null; // triggered when hoover over a participant and click
    // other passed on functions
    this.onLeadingView = null; // for leading view
    this.onAskToFollowView = null; // for asking view

    // This is the canvas where the action is
    this.canvas = document.createElement('canvas');
    this.canvas.setAttribute('width', String(window.innerWidth));
    this.canvas.setAttribute('height', String(window.innerHeight));
    this.canvas.setAttribute('id', 'mainCanvas');
    this.canvas.setAttribute('style', 'background-color: transparent; position: absolute; touch-action: none;');

    // This is the underlying canvas that contains the picture etc
    this.canvas0 = document.createElement('canvas');
    this.canvas0.setAttribute('width', String(window.innerWidth));
    this.canvas0.setAttribute('height', String(window.innerHeight));
    this.canvas0.setAttribute('id', 'backGroundCanvas');
    this.canvas0.setAttribute('style', 'background-color: transparent; position: absolute; touch-action: none;');

    // This is the foreground canvas that contains mouse cursor etc
    this.canvas1 = document.createElement('canvas');
    this.canvas1.setAttribute('width', String(window.innerWidth));
    this.canvas1.setAttribute('height', String(window.innerHeight));
    this.canvas1.setAttribute('id', 'foreGroundCanvas');
    this.canvas1.setAttribute('style', 'background-color: transparent; position: absolute; touch-action: none;');

    // this.canvasBackGround = document.createElement("stretchCanvas");
    this.canvasBackGround = document.createElement('canvas');
    this.canvasBackGround.setAttribute('width', this.stretchSize);
    this.canvasBackGround.setAttribute('height', this.stretchSize);
    this.canvasBackGround.setAttribute('id', 'stretchCanvas');
    this.canvasBackGround.setAttribute('style', 'position: absolute; top: 0; bottom: 0; left: 0; right: 0; width: 100%; height: 100%;');

    // this is the background pic side canvas
    this.canvasSide0 = document.createElement('canvas');
    this.canvasSide0.setAttribute('width', this.width / 4);
    this.canvasSide0.setAttribute('height', this.height / 4);
    this.canvasSide0.setAttribute('id', 'sideCanvas0');
    // need to consider placement
    this.canvasSide0.setAttribute('style', 'background-color: transparent; position: absolute; top: 0; bottom: 0; left: 0; right: 0;');

    // need to consider placement
    // This is the canvas where the action is on the sidecanvas
    this.canvasSide = document.createElement('canvas');
    this.canvasSide.setAttribute('width', this.width / 4);
    this.canvasSide.setAttribute('height', this.height / 4);
    this.canvasSide.setAttribute('id', 'sideCanvas');
    // need to consider placement
    this.canvasSide.setAttribute('style', 'background-color: transparent; position: absolute; top: 0; bottom: 0; left: 0; right: 0;');
    this.sideCanvasActive = false;
    this.sideCanvasNameSize = 3 * this.avatarSize;

    this.ctx0 = this.canvas0.getContext('2d');
    this.ctx = this.canvas.getContext('2d');
    this.ctx1 = this.canvas1.getContext('2d');
    this.ctxBackGround = this.canvasBackGround.getContext('2d');
    this.ctxSide0 = this.canvasSide0.getContext('2d');
    this.ctxSide = this.canvasSide.getContext('2d');

    this.parentContainer.appendChild(this.canvasBackGround);
    this.parentContainer.appendChild(this.canvas0);
    this.parentContainer.appendChild(this.canvas);
    this.parentContainer.appendChild(this.canvas1);
    // this.parentContainer.appendChild(this.canvasBackGround);

    this.videoContainer = document.createElement('div');
    this.videoContainer.hidden = true;
    this.videoContainer.id = 'videoContainerArea';

    this.videos = document.createElement('div');
    this.videos.id = 'videos';
    this.videos.setAttribute('class', 'videos');
    this.videoContainer.appendChild(this.videos);

    this.localVideoContainer = document.createElement('div');
    this.localVideoContainer.id = 'localVideoContainer';
    this.localVideoContainer.setAttribute('class', 'videoContainer');
    this.videoContainer.appendChild(this.localVideoContainer);

    this.localVideo = document.createElement('video');
    this.localVideo.id = 'localVideo';
    this.localVideo.setAttribute('autoplay', '');
    this.localVideo.setAttribute('playsinline', '');
    this.localVideo.setAttribute('muted', '');
    // this.localVideo.setAttribute('playsinline','');

    // Edge (and others?) do not honor "muted" if set with .setAttribute
    this.localVideo.muted = true;
    // this.localVideo.setAttribute('playsinline', '');
    this.localVideoContainer.appendChild(this.localVideo);

    this.myDisplayName = document.createElement('div');
    this.myDisplayName.id = 'myDisplayName';
    this.myDisplayName.setAttribute('class', 'videoLabelMe');
    this.localVideoContainer.appendChild(this.myDisplayName);

    this.parentContainer.appendChild(this.videoContainer);

    this.audioContainer = document.createElement('div');
    this.audioContainer.id = 'audios';
    this.audioContainer.setAttribute('class', 'audios');

    this.parentContainer.appendChild(this.audioContainer);

    // event listeners
    // this.localVideo.addEventListener('play', this.onLocalPlay.bind(this), false);

    // this.canvas.addEventListener('mousedown', this.onMouseDown.bind(this));
    // this.canvas.addEventListener('mouseup', this.onMouseUp.bind(this));
    // this.canvas.addEventListener('mousemove', this.onMouseMove.bind(this), false);

    // pointer* captures events for mouse, as well as pen and touch
    this.canvas1.addEventListener('pointerdown', this.onMouseDown.bind(this));
    this.canvas1.addEventListener('pointerup', this.onMouseUp.bind(this));
    this.canvas1.addEventListener('pointermove', this.onMouseMove.bind(this), false);

    this.canvas1.addEventListener('mousewheel', this.onMouseScroll.bind(this), false);
    this.canvas1.addEventListener('DOMMouseScroll', this.onMouseScroll.bind(this), false);

    // icons
    // Some pre code elements
    this.mutedIcon = new Image();
    // eslint-disable-next-line max-len
    this.mutedIcon.src = 'data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20width%3D%221em%22%20height%3D%221em%22%20preserveAspectRatio%3D%22xMidYMid%20meet%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20d%3D%22M19%2011c0%201.19-.34%202.3-.9%203.28l-1.23-1.23c.27-.62.43-1.31.43-2.05H19m-4%20.16L9%205.18V5a3%203%200%200%201%203-3a3%203%200%200%201%203%203v6.16M4.27%203L21%2019.73L19.73%2021l-4.19-4.19c-.77.46-1.63.77-2.54.91V21h-2v-3.28c-3.28-.49-6-3.31-6-6.72h1.7c0%203%202.54%205.1%205.3%205.1c.81%200%201.6-.19%202.31-.52l-1.66-1.66L12%2014a3%203%200%200%201-3-3v-.72L3%204.27L4.27%203z%22%20fill%3D%22red%22%2F%3E%3C%2Fsvg%3E';
    this.cursorIcon = new Image();
    // eslint-disable-next-line max-len
    this.cursorIcon.src = 'data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20width%3D%221em%22%20height%3D%221em%22%20preserveAspectRatio%3D%22xMidYMid%20meet%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20d%3D%22M10.07%2014.27a.997.997%200%200%201%201.33.48l2.3%204.99l1.8-.85l-2.31-4.98c-.24-.5-.02-1.1.48-1.33l.28-.08l2.3-.45L8%205.12V15.9l1.82-1.47l.25-.16m3.57%207.7a.99.99%200%200%201-1.33-.47l-2.18-4.74l-2.51%202.02c-.17.14-.38.22-.62.22a1%201%200%200%201-1-1V3a1%201%200%200%201%201-1c.24%200%20.47.09.64.23l.01-.01l11.49%209.64a1.001%201.001%200%200%201-.44%201.75l-3.16.62l2.2%204.73c.26.5.02%201.09-.48%201.32l-3.62%201.69z%22%20fill%3D%22currentColor%22%2F%3E%3C%2Fsvg%3E';
    this.sleepIcon = new Image();
    // eslint-disable-next-line max-len
    this.sleepIcon.src = 'data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20width%3D%221em%22%20height%3D%221em%22%20preserveAspectRatio%3D%22xMidYMid%20meet%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20d%3D%22M23%2012h-6v-2l3.39-4H17V4h6v2l-3.38%204H23v2m-8%204H9v-2l3.39-4H9V8h6v2l-3.38%204H15v2m-8%204H1v-2l3.39-4H1v-2h6v2l-3.38%204H7v2z%22%20fill%3D%22red%22%2F%3E%3C%2Fsvg%3E';
    // canvas transforms for zooming
    this.svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');

    // THIS IS NOT THE WAY IT SHOULD BE DONE BUT DID IT ANYWAY

    // MAIN CANVAS
    this.xform = this.svg.createSVGMatrix();
    this.ctx.getTransform = (function getTransform() { return this.xform; }).bind(this);

    this.savedTransforms = [];
    this.save = this.ctx.save;

    this.ctx.save = (function save() {
      this.savedTransforms.push(this.xform.translate(0, 0));
      return this.save.call(this.ctx);
    }).bind(this);

    this.restore = this.ctx.restore;
    this.ctx.restore = (function restore() {
      this.xform = this.savedTransforms.pop();
      return this.restore.call(this.ctx);
    }).bind(this);

    this.scale = this.ctx.scale;
    this.ctx.scale = (function scale(sx, sy) {
      this.xform = this.xform.scaleNonUniform(sx, sy);
      return this.scale.call(this.ctx, sx, sy);
    }).bind(this);

    this.rotate = this.ctx.rotate;
    this.ctx.rotate = (function rotate(radians) {
      this.xform = this.xform.rotate((radians * 180) / Math.PI);
      return this.rotate.call(this.ctx, radians);
    }).bind(this);

    this.translate = this.ctx.translate;
    this.ctx.translate = (function translate(dx, dy) {
      this.xform = this.xform.translate(dx, dy);
      return this.translate.call(this.ctx, dx, dy);
    }).bind(this);

    this.transform = this.ctx.transform;
    this.ctx.transform = (function transform(a, b, c, d, e, f) {
      const m2 = this.svg.createSVGMatrix();
      m2.a = a; m2.b = b; m2.c = c; m2.d = d; m2.e = e; m2.f = f;
      this.xform = this.xform.multiply(m2);
      return this.transform.call(this.ctx, a, b, c, d, e, f);
    }).bind(this);

    this.setTransform = this.ctx.setTransform;
    this.ctx.setTransform = (function setTransform(a, b, c, d, e, f) {
      this.xform.a = a;
      this.xform.b = b;
      this.xform.c = c;
      this.xform.d = d;
      this.xform.e = e;
      this.xform.f = f;
      return this.setTransform.call(this.ctx, a, b, c, d, e, f);
    }).bind(this);

    this.pt = this.svg.createSVGPoint();
    this.ctx.transformedPoint = (function transformedPoint(x, y) {
      this.pt.x = x; this.pt.y = y;
      return this.pt.matrixTransform(this.xform.inverse());
    }).bind(this);

    // BACKGROUND CANVAS
    // todo: clean up variable names
    this.xform0 = this.svg.createSVGMatrix();
    this.ctx0.getTransform = (function getTransform() { return this.xform0; }).bind(this);

    this.savedTransforms0 = [];
    this.save0 = this.ctx0.save;

    this.ctx0.save = (function save() {
      this.savedTransforms0.push(this.xform0.translate(0, 0));
      return this.save0.call(this.ctx0);
    }).bind(this);

    this.restore0 = this.ctx0.restore;
    this.ctx0.restore = (function restore() {
      this.xform0 = this.savedTransforms0.pop();
      return this.restore0.call(this.ctx0);
    }).bind(this);

    this.scale0 = this.ctx0.scale;
    this.ctx0.scale = (function scale(sx, sy) {
      this.xform0 = this.xform0.scaleNonUniform(sx, sy);
      return this.scale0.call(this.ctx0, sx, sy);
    }).bind(this);

    this.rotate0 = this.ctx0.rotate;
    this.ctx0.rotate = (function rotate(radians) {
      this.xform0 = this.xform0.rotate((radians * 180) / Math.PI);
      return this.rotate0.call(this.ctx0, radians);
    }).bind(this);

    this.translate0 = this.ctx0.translate;
    this.ctx0.translate = (function translate(dx, dy) {
      this.xform0 = this.xform0.translate(dx, dy);
      return this.translate0.call(this.ctx0, dx, dy);
    }).bind(this);

    this.transform0 = this.ctx0.transform;
    this.ctx0.transform = (function transform(a, b, c, d, e, f) {
      const m2 = this.svg.createSVGMatrix();
      m2.a = a; m2.b = b; m2.c = c; m2.d = d; m2.e = e; m2.f = f;
      this.xform0 = this.xform0.multiply(m2);
      return this.transform0.call(this.ctx0, a, b, c, d, e, f);
    }).bind(this);

    this.setTransform0 = this.ctx0.setTransform;
    this.ctx0.setTransform = (function setTransform(a, b, c, d, e, f) {
      this.xform0.a = a;
      this.xform0.b = b;
      this.xform0.c = c;
      this.xform0.d = d;
      this.xform0.e = e;
      this.xform0.f = f;
      return this.setTransform.call(this.ctx0, a, b, c, d, e, f);
    }).bind(this);

    this.pt0 = this.svg.createSVGPoint();
    this.ctx0.transformedPoint = (function transformedPoint(x, y) {
      this.pt.x = x; this.pt.y = y;
      return this.pt0.matrixTransform(this.xform0.inverse());
    }).bind(this);

    // this is the foreground canvas
    // todo: clean up variable names
    this.xform1 = this.svg.createSVGMatrix();
    this.ctx1.getTransform = (function getTransform() { return this.xform1; }).bind(this);

    this.savedTransforms1 = [];
    this.save1 = this.ctx1.save;

    this.ctx1.save = (function save() {
      this.savedTransforms1.push(this.xform1.translate(0, 0));
      return this.save1.call(this.ctx1);
    }).bind(this);

    this.restore1 = this.ctx1.restore;
    this.ctx1.restore = (function restore() {
      this.xform1 = this.savedTransforms1.pop();
      return this.restore1.call(this.ctx1);
    }).bind(this);

    this.scale1 = this.ctx1.scale;
    this.ctx1.scale = (function scale(sx, sy) {
      this.xform1 = this.xform1.scaleNonUniform(sx, sy);
      return this.scale1.call(this.ctx1, sx, sy);
    }).bind(this);

    this.rotate1 = this.ctx1.rotate;
    this.ctx1.rotate = (function rotate(radians) {
      this.xform1 = this.xform1.rotate((radians * 180) / Math.PI);
      return this.rotate1.call(this.ctx1, radians);
    }).bind(this);

    this.translate1 = this.ctx1.translate;
    this.ctx1.translate = (function translate(dx, dy) {
      this.xform1 = this.xform1.translate(dx, dy);
      return this.translate1.call(this.ctx1, dx, dy);
    }).bind(this);

    this.transform1 = this.ctx1.transform;
    this.ctx1.transform = (function transform(a, b, c, d, e, f) {
      const m2 = this.svg.createSVGMatrix();
      m2.a = a; m2.b = b; m2.c = c; m2.d = d; m2.e = e; m2.f = f;
      this.xform1 = this.xform1.multiply(m2);
      return this.transform1.call(this.ctx1, a, b, c, d, e, f);
    }).bind(this);

    this.setTransform1 = this.ctx1.setTransform;
    this.ctx1.setTransform = (function setTransform(a, b, c, d, e, f) {
      this.xform1.a = a;
      this.xform1.b = b;
      this.xform1.c = c;
      this.xform1.d = d;
      this.xform1.e = e;
      this.xform1.f = f;
      return this.setTransform.call(this.ctx1, a, b, c, d, e, f);
    }).bind(this);

    this.pt1 = this.svg.createSVGPoint();
    this.ctx1.transformedPoint = (function transformedPoint(x, y) {
      this.pt.x = x; this.pt.y = y;
      return this.pt1.matrixTransform(this.xform1.inverse());
    }).bind(this);
    // Side Canvas0
    // todo: clean up variable names
    this.xformSide0 = this.svg.createSVGMatrix();
    this.ctxSide0.getTransform = (function getTransform() { return this.xformSide0; }).bind(this);

    this.savedTransformsSide0 = [];
    this.saveSide0 = this.ctxSide0.save;

    this.ctxSide0.save = (function save() {
      this.savedTransformsSide0.push(this.xformSide0.translate(1, 1));
      return this.saveSide0.call(this.ctxSide);
    }).bind(this);

    this.restoreSide0 = this.ctxSide0.restore;
    this.ctxSide0.restore = (function restore() {
      this.xformSide0 = this.savedTransformsSide0.pop();
      return this.restoreSide0.call(this.ctxSide0);
    }).bind(this);

    this.scaleSide0 = this.ctxSide0.scale;
    this.ctxSide0.scale = (function scale(sx, sy) {
      this.xformSide0 = this.xformSide0.scaleNonUniform(sx, sy);
      return this.scaleSide0.call(this.ctxSide0, sx, sy);
    }).bind(this);

    this.rotateSide0 = this.ctxSide0.rotate;
    this.ctxSide0.rotate = (function rotate(radians) {
      this.xformSide0 = this.xformSide0.rotate((radians * 180) / Math.PI);
      return this.rotateSide0.call(this.ctxSide0, radians);
    }).bind(this);

    this.translateSide0 = this.ctxSide0.translate;
    this.ctxSide0.translate = (function translate(dx, dy) {
      this.xformSide0 = this.xformSide0.translate(dx, dy);
      return this.translateSide0.call(this.ctxSide0, dx, dy);
    }).bind(this);

    this.transformSide0 = this.ctxSide0.transform;
    this.ctxSide0.transform = (function transform(a, b, c, d, e, f) {
      const m2 = this.svg.createSVGMatrix();
      m2.a = a; m2.b = b; m2.c = c; m2.d = d; m2.e = e; m2.f = f;
      this.xformSide0 = this.xformSide0.multiply(m2);
      return this.transformSide0.call(this.ctxSide0, a, b, c, d, e, f);
    }).bind(this);

    this.setTransformSide0 = this.ctxSide0.setTransform;
    this.ctxSide0.setTransform = (function setTransform(a, b, c, d, e, f) {
      this.xformSide0.a = a;
      this.xformSide0.b = b;
      this.xformSide0.c = c;
      this.xformSide0.d = d;
      this.xformSide0.e = e;
      this.xformSide0.f = f;
      return this.setTransform0.call(this.ctxSide0, a, b, c, d, e, f);
    }).bind(this);

    this.ptSide0 = this.svg.createSVGPoint();
    this.ctxSide0.transformedPoint = (function transformedPoint(x, y) {
      this.pt.x = x; this.pt.y = y;
      return this.ptSide0.matrixTransform(this.xformSide0.inverse());
    }).bind(this);
    // Side Canvas
    // todo: clean up variable names
    this.xformSide = this.svg.createSVGMatrix();
    this.ctxSide.getTransform = (function getTransform() { return this.xformSide; }).bind(this);

    this.savedTransformsSide = [];
    this.saveSide = this.ctxSide.save;

    this.ctxSide.save = (function save() {
      this.savedTransformsSide.push(this.xformSide.translate(1, 1));
      return this.saveSide.call(this.ctxSide);
    }).bind(this);

    this.restoreSide = this.ctxSide.restore;
    this.ctxSide.restore = (function restore() {
      this.xformSide = this.savedTransformsSide.pop();
      return this.restoreSide.call(this.ctxSide);
    }).bind(this);

    this.scaleSide = this.ctxSide.scale;
    this.ctxSide.scale = (function scale(sx, sy) {
      this.xformSide = this.xformSide.scaleNonUniform(sx, sy);
      return this.scaleSide.call(this.ctxSide, sx, sy);
    }).bind(this);

    this.rotateSide = this.ctxSide.rotate;
    this.ctxSide.rotate = (function rotate(radians) {
      this.xformSide = this.xformSide.rotate((radians * 180) / Math.PI);
      return this.rotateSide.call(this.ctxSide, radians);
    }).bind(this);

    this.translateSide = this.ctxSide.translate;
    this.ctxSide.translate = (function translate(dx, dy) {
      this.xformSide = this.xformSide.translate(dx, dy);
      return this.translateSide.call(this.ctxSide, dx, dy);
    }).bind(this);

    this.transformSide = this.ctxSide.transform;
    this.ctxSide.transform = (function transform(a, b, c, d, e, f) {
      const m2 = this.svg.createSVGMatrix();
      m2.a = a; m2.b = b; m2.c = c; m2.d = d; m2.e = e; m2.f = f;
      this.xformSide = this.xformSide.multiply(m2);
      return this.transformSide.call(this.ctxSide, a, b, c, d, e, f);
    }).bind(this);

    this.setTransformSide = this.ctxSide.setTransform;
    this.ctxSide.setTransform = (function setTransform(a, b, c, d, e, f) {
      this.xformSide.a = a;
      this.xformSide.b = b;
      this.xformSide.c = c;
      this.xformSide.d = d;
      this.xformSide.e = e;
      this.xformSide.f = f;
      return this.setTransform.call(this.ctxSide, a, b, c, d, e, f);
    }).bind(this);

    this.ptSide = this.svg.createSVGPoint();
    this.ctxSide.transformedPoint = (function transformedPoint(x, y) {
      this.pt.x = x; this.pt.y = y;
      return this.ptSide.matrixTransform(this.xformSide.inverse());
    }).bind(this);

  }

  // Canvas functions. Should move them into a class at some point
  createFittedTextBox(text, canvasNumber, x, y, textSize = 10, allignment = 'center', scaling = false, font = 'Arial', textColor = 'white', boxColor = 'black', opaquenessBox = 0.5, clear = false) {
    let ctx;
    // should be an extension of canvas class so no need for this in the end
    // eslint-disable-next-line radix
    const canvasType = parseInt(canvasNumber);

    switch (canvasType) {
      case 0:
        ctx = this.canvas0.getContext('2d');
        break;
      case 1:
        ctx = this.canvas.getContext('2d');
        break;
      case 2:
        ctx = this.canvas1.getContext('2d');
        break;
      case 3:
        ctx = this.canvasSide.getContext('2d');
        break;
      default:
        ctx = this.canvas.getContext('2d');
        break;
    }
    // setting up the text
    let size = textSize;
    if (scaling) {
      size = textSize / Math.sqrt(this.ctx.getTransform().a);
    }
    const textSpec = `${size}px ${font}`;
    ctx.font = textSpec;
    const textMetrics = ctx.measureText(text);
    // somewhat experimental
    // eslint-disable-next-line radix
    const height = parseInt(ctx.font) * 1.5;
    // setting up the box
    ctx.fillStyle = boxColor;
    const adjSize = 1.5;
    if (clear) {
      ctx.clearRect(x - 0.5 * textMetrics.width * adjSize, y - height * (3 / 4),
        textMetrics.width * adjSize, height);
    } else {
      ctx.globalAlpha = opaquenessBox;
      ctx.fillRect(x - 0.5 * textMetrics.width * adjSize, y - height * (3 / 4),
        textMetrics.width * adjSize, height);
      // resetting the opaqueness
      ctx.globalAlpha = 1;
      // writing the text in the box.
      ctx.fillStyle = textColor;
      ctx.textAlign = allignment;
      ctx.fillText(text, x, y);
    }
    const dat = {
      x: x - 0.5 * textMetrics.width * adjSize,
      y: y - height * (3 / 4),
      width: textMetrics.width * adjSize,
      height,
    };
    return dat;
  }

  highlightParticipant(participant) {
    // draw a circle around if avatar or square if video
    if (!participant.videovar || participant.noVideo || this.me.doNotPlayAnyVideosOnCanvas ||
      this.me.playNone || !this.hasInteracted ||
      participant.speedX !== 0 || participant.speedY !== 0) {
      // window.mingly.log('Correct Place if Avatar and hoover');
      this.ctx.fillStyle = 'red';
      this.ctx.beginPath();
      this.ctx.arc(participant.x, participant.y,
        (this.avatarSize / 2) - (this.videoBorderWidth / 2),
        0, 2 * Math.PI);
      this.ctx.stroke();
      this.inspectParticipant(participant);
    }
    else {
      // window.mingly.log('WRONG Place if Avatar and hoover');
      // this.ctx.globalAlpha = 0.5;
      // this.ctx.strokeStyle = 'red';
      // this.ctx.fillRect(participant.x - this.avatarRadius, participant.y - this.avatarRadius,
      //   this.avatarSize, this.avatarSize);

      this.ctx.strokeRect(participant.x - this.avatarRadius,
        participant.y - this.avatarRadius, this.avatarSize, this.avatarSize);
      // this.ctx.globalAlpha = 1;
    }
  }

  isSpotAvailable(x, y) {
    // todo: checkout eslint's suggestions for "for in"
    // eslint-disable-next-line no-restricted-syntax,guard-for-in
    for (const uuid in this.participants) {
      const participant = this.participants[uuid];
      window.mingly.log(`Canvasboard.isSpotAvailable: the peer check: ${participant.x}`);
      // this.me.isGhost is not computationally optimal as you don't want to loop
      // I am also a bit unsure about the logic here...
      if (participant.isGhost || this.me.isGhost) {
        window.mingly.log('Canvasboard.isSpotAvailable: returning true');
        return true;
      }
      if ((participant.x - this.avatarSize) < x && x < (participant.x + this.avatarSize) &&
          (participant.y - this.avatarSize) < y && y < (participant.y + this.avatarSize)) {
        window.mingly.log('Canvasboard.isSpotAvailable: returning false');
        return false;
      }
    }
    window.mingly.log('Canvasboard.isSpotAvailable: returning true');
    return true;
  }

  resetVideo() {
    this.me.playNone = true; // need a more clever way of finding out the delay
    this.updateAll();
    setTimeout(() => { this.startTimerCanvas(); }, 0);
  }

  clearAvatar(participant) {
    if (!participant.hasValidPosition()) {
      return false;
    }

    if (this.room.brutalUpdate) {
      this.update();
    }
    else {
      window.mingly.log(`Canvasboard.clearAvatar: clearing picture at x=${participant.x}, y= ${participant.y}`);
      if (participant.adjustSizeAvatar.adjust) {
        const rad = participant.adjustSizeAvatar.adjustSize;
        this.ctx.clearRect(
          participant.x - rad,
          participant.y - rad,
          2 * rad,
          2 * rad);
      } else {
        this.ctx.clearRect(
          participant.x - this.avatarRadius,
          participant.y - this.avatarRadius,
          this.avatarSize,
          this.avatarSize);
      }
    }

    if (participant.isLocal) {
      // this.localVideo.pause();
      cancelAnimationFrame(participant.reqAnimation);
    }
    else {
      // eslint-disable-next-line no-lonely-if
      if (participant.videoElement) {
        window.mingly.log('Canvasboard.clearAvatar: pausing video');

        // participant.videoElement.pause();
        cancelAnimationFrame(participant.reqAnimation);
        if (this.room.brutalUpdate) {
          this.update();
        }
        else {
          this.ctx.clearRect(
            participant.x - this.avatarRadius,
            participant.y - this.avatarRadius,
            this.avatarSize,
            this.avatarSize);
        }
      }
    }

    return true;
  }

  inspectParticipant(participant) {
    if (participant.uuid === this.me.uuid) {
      this.ctx1.fillStyle = 'blue';
    } else {
      this.ctx1.fillStyle = 'black';
    }
    this.createFittedTextBox(participant.name, 1, participant.x, participant.y,
      this.avatarSize / 2, 'center', true,
      'Arial', 'white', 'black', 1);
  }

  drawCursor(participant) {
    if (!participant.cursor.active) {
      return false;
    }
    const { size } = participant.cursor;
    const { x } = participant.cursor;
    const { y } = participant.cursor;
    this.ctx1.drawImage(this.cursorIcon, x, y, size, size);
    this.createFittedTextBox(participant.name, 2, x + 0.5 * size, y + size * this.onePointTwo, size / 5, 'center', false, 'Arial', 'white', 'black', 0.5);
    return true;
  }

  clearCursor(participant, x, y) {
    const { size } = participant.cursor;
    this.ctx1.clearRect(x, y, size, size);
    this.createFittedTextBox(participant.name, 2, x + 0.5 * size, y + size * this.onePointTwo, size / 3, 'center', false, 'Arial', 'white', 'black', 0.5, true);
    return true;
  }

  drawSideCanvas(participant) {
    if (!this.sideCanvasActive) {
      return false;
    }
    // the size of the canvas relativ to the actual room
    const ratio = this.canvasSide.width / this.width; // sufficient with one side as it scales
    const x = participant.x * ratio;
    const y = participant.y * ratio;
    const size = this.sideCanvasNameSize * ratio;
    this.createFittedTextBox(participant.name, 3, x, y, size / 5, 'center', false, 'Arial', 'white', 'black', 0.5, false);
    return true;
  }

  clearSideCanvas(participant) {
    if (!this.sideCanvasActive) {
      return false;
    }
    // the size of the canvas relativ to the actual room
    const ratio = this.canvasSide.width / this.width; // sufficient with one side as it scales
    const x = participant.x * ratio;
    const y = participant.y * ratio;
    const size = this.sideCanvasNameSize * ratio;
    this.createFittedTextBox(participant.name, 3, x, y, size / 3, 'center', false, 'Arial', 'white', 'black', 0.5, true);
    return true;
  }

  drawAvatar(participant) {
    if (!participant.hasValidPosition() || participant.isGhost) {
      return false;
    }
    let videoFontSize;
    if (participant.name.length <= 12) {
      videoFontSize = Math.round(this.avatarSize / 15);
    } else if (participant.name.length <= 17 && participant.name.length > 12) {
      videoFontSize = Math.round(this.avatarSize / 20);
    } else {
      videoFontSize = Math.round(this.avatarSize / 30);
    }
    // const sizeF = (Math.round(this.avatarSize * 0.65));
    for (let i = 0; i < participant.connectedObjects.length; i += 1) {
      const uuid = participant.connectedObjects[i];
      if (this.canvasObjects[uuid].isActive) {
        this.canvasObjects[uuid].clear();
        this.canvasObjects[uuid].drawObject();
      }
    }
    if (!participant.videovar || participant.noVideo || this.me.playNone ||
      this.me.doNotPlayAnyVideosOnCanvas || !this.hasInteracted) {
      // this.drawAvatarBackground(participant);
      // if(this.room.mediaserver) {
      //   cancelAnimationFrame(participant.reqAnimation);
      // }
      if (!participant.usepicture) {
        this.drawAvatarBackground(participant);
        if (participant.hoover) {
          // this.ctx.strokeStyle = 'red';
          // this.ctx.strokeRect(participant.x - this.avatarRadius,
          //   participant.y - this.avatarRadius, this.avatarSize, this.avatarSize);
          this.highlightParticipant(participant);
        }
        // const font = `${sizeF}px Arial`;
        // this.ctx.font = font;

        // const avatarcode = String.fromCodePoint(participant.avatar);
        // this.ctx.fillText(avatarcode, participant.x - Math.round(sizeF / 1.5),
        //   participant.y + sizeF / 3);
      }
      else {
        window.mingly.log('Canvasboard.drawAvatar: drawing picture');
        this.drawAvatarBackground(participant);
        if (participant.hoover) {
          // this.ctx.strokeStyle = 'red';
          // this.ctx.strokeRect(participant.x - this.avatarRadius,
          //   participant.y - this.avatarRadius,
          //   this.avatarSize, this.avatarSize);
          this.highlightParticipant(participant);
        }
        this.ctx.drawImage(participant.pic,
          participant.x - this.avatarRadius + this.videoBorderWidth,
          participant.y - this.avatarRadius + this.videoBorderWidth,
          this.avatarSize - (2 * this.videoBorderWidth),
          this.avatarSize - (2 * this.videoBorderWidth));
        let boxColor = 'black';
        if (participant.uuid === this.me.uuid) {
          boxColor = 'blue';
        }
        this.createFittedTextBox(participant.name, 1, participant.x,
          (participant.y + this.avatarRadius - this.avatarRadius / 3),
          videoFontSize, 'center', false, 'Arial',
          'white', boxColor, 0.5, false);
        if (participant.muted && !participant.screenShare && !participant.isSleeping) {
          this.ctx.drawImage(this.mutedIcon, participant.x + this.avatarRadius / 2,
            participant.y - this.avatarRadius / 2, this.avatarRadius / 2.5,
            this.avatarRadius / 2.5);
        }
        if (participant.isSleeping) {
          this.ctx.drawImage(this.sleepIcon, participant.x - 0.5 * this.avatarRadius,
            participant.y - 0.5 * this.avatarRadius, this.avatarRadius,
            this.avatarRadius);
        }
      }
    }
    else {
      // eslint-disable-next-line no-lonely-if
      if (participant.movingUsingKeyboardNow) {
        window.mingly.log('Keyboard move');
        cancelAnimationFrame(participant.reqAnimation); // very important line!
        // this.drawAvatarBackground(participant);
        this.drawAvatarBackground(participant);
        this.drawVideo(participant);
      } else {
        if (participant.speedX === 0 && participant.speedY === 0) {
          // console.log("Canvasboard.drawAvatar: standing still");
          cancelAnimationFrame(participant.reqAnimation); // very important line!
          // this.drawAvatarBackground(participant);
          this.drawAvatarBackground(participant);
          this.drawVideo(participant);
        }
        else {
          window.mingly.log('Canvasboard.drawAvatar: pausing due to moving');
          if (participant.videoElement) {
            cancelAnimationFrame(participant.reqAnimation);
          }
          // this.drawAvatarBackground(participant);
          if (!participant.usepicture) {

            // const font = `${sizeF}px Arial`;
            // this.ctx.font = font;

            // const avatarcode = String.fromCodePoint(participant.avatar);
            window.mingly.log('Canvasboard.drawAvatar: Smile to the camera!');
            this.drawAvatarBackground(participant);
            if (participant.hoover) {
              this.highlightParticipant(participant);
            }
            // this.ctx.fillText(avatarcode, participant.x - Math.round(sizeF / 1.4),
            //   participant.y + sizeF / 3);
          }
          else {
            window.mingly.log('Drawing picture 2');
            this.drawAvatarBackground(participant);
            if (participant.hoover) {
              this.highlightParticipant(participant);
            }
            this.ctx.drawImage(participant.pic,
              participant.x - this.avatarRadius + this.videoBorderWidth,
              participant.y - this.avatarRadius + this.videoBorderWidth,
              this.avatarSize - (2 * this.videoBorderWidth),
              this.avatarSize - (2 * this.videoBorderWidth));
            let boxColor = 'black';
            if (participant.uuid === this.me.uuid) {
              boxColor = 'blue';
            }
            this.createFittedTextBox(participant.name, 1, participant.x,
              (participant.y + this.avatarRadius - this.avatarRadius / 3),
              videoFontSize, 'center', false, 'Arial',
              'white', boxColor, 0.5, false);
          }
          if (participant.muted && !participant.screenShare && !participant.isSleeping) {
            this.ctx.drawImage(this.mutedIcon, participant.x + this.avatarRadius / 2,
              participant.y - this.avatarRadius / 2, this.avatarRadius / 2.5,
              this.avatarRadius / 2.5);
          }
          if (participant.isSleeping) {
            this.ctx.drawImage(this.sleepIcon, participant.x - 0.5 * this.avatarRadius,
              participant.y - 0.5 * this.avatarRadius, this.avatarRadius,
              this.avatarRadius);
          }
        }
      }
    }
    return true; // be consistent with previous returns
  }

  // function that takes a transform matrix and sets it correctly for the rest
  setView(mat1, mat2) {
    // mat 1 is the transformation matrix for
    // the background the video ara
    // mat 2 is the transformation matrix for
    // the foreground (mouse etc) canvas
    // I am a bit surprised that mat1 is different from mat2
    // console.log('I am here!');
    this.ctx0.setTransform(mat1.a,
      mat1.b,
      mat1.c,
      mat1.d,
      mat1.e,
      mat1.f);
    this.ctx.setTransform(mat1.a,
      mat1.b,
      mat1.c,
      mat1.d,
      mat1.e,
      mat1.f);
    this.ctx1.setTransform(mat2.a,
      mat2.b,
      mat2.c,
      mat2.d,
      mat2.e,
      mat2.f);
    this.updateAll();
  }

  setLeadingView() {
    if (this.onLeadingView) {
      try {
        this.onLeadingView();
      }
      catch {
        window.mingly.log('Could not do leading view');
      }
    }
  }

  setScalingFactor(size) {
    this.avatarSize = size;
    this.avatarRadius = this.avatarSize / 2;
    this.maxSpeed = this.avatarSize;
    this.scalingFactor = this.avatarSize;
  }

  drawBackgroundPicture(c, w, h) {
    const hPic = this.room.backgroundPicture.height;
    const wPic = this.room.backgroundPicture.width;
    const aRcanvas = w / h; // aspect ratio canvas
    const aRpic = wPic / hPic; // aspect ratio picture
    // this.ctx0.drawImage(this.room.backgroundPicture, 500, 500, w, h, 0, 0, w, h);
    if (aRpic > aRcanvas) {
      c.drawImage(this.room.backgroundPicture, 0.5 * (wPic - hPic * aRcanvas), 0,
        hPic * aRcanvas, hPic, 0, 0, w, h);
    } else if (aRpic < aRcanvas) {
      c.drawImage(this.room.backgroundPicture, 0, 0.5 * (hPic - wPic / aRcanvas),
        wPic, wPic / aRcanvas, 0, 0, w, h);
    } else {
      c.drawImage(this.room.backgroundPicture, 0, 0, w, h);
    }
  }

  // functions local to Canvasboard

  clear() {
    this.ctx.save();
    this.ctx.globalCompositeOperation = 'copy';
    this.ctx.strokeStyle = 'transparent';
    this.ctx.beginPath();
    this.ctx.lineTo(0, 0);
    this.ctx.stroke();
    this.ctx.restore();

    this.ctx1.save();
    this.ctx1.globalCompositeOperation = 'copy';
    this.ctx1.strokeStyle = 'transparent';
    this.ctx1.beginPath();
    this.ctx1.lineTo(0, 0);
    this.ctx1.stroke();
    this.ctx1.restore();
  }

  clearAll() {
    this.ctx.save();
    this.ctx.globalCompositeOperation = 'copy';
    this.ctx.strokeStyle = 'transparent';
    this.ctx.beginPath();
    this.ctx.lineTo(0, 0);
    this.ctx.stroke();
    this.ctx.restore();

    this.ctx0.save();
    this.ctx0.globalCompositeOperation = 'copy';
    this.ctx0.strokeStyle = 'transparent';
    this.ctx0.beginPath();
    this.ctx0.lineTo(0, 0);
    this.ctx0.stroke();
    this.ctx0.restore();

    this.ctx1.save();
    this.ctx1.globalCompositeOperation = 'copy';
    this.ctx1.strokeStyle = 'transparent';
    this.ctx1.beginPath();
    this.ctx1.lineTo(0, 0);
    this.ctx1.stroke();
    this.ctx1.restore();
  }

  update() {
    this.clear();
    // this.ctx.beginPath();
    // this.ctx.rect(0, 0, board.width,board.height);
    // this.ctx.strokeStyle ="#FF0000";

    // this.ctx.globalAlpha = 0.3;
    // this.ctx.lineWidth = 3;
    // this.ctx.strokeStyle ="#33BEFF";

    // this.ctx.stroke();
    // this.ctx.globalAlpha = 1;
    Object.values(this.canvasObjects).forEach((object) => {
      // if (object.type === 'picture') {
      // should have a swtich here to choose correct canvas
      if (object.isActive) {
        if (object.type === 'canvasVideo') {
          cancelAnimationFrame(object.reqAnimation);
          object.drawVideo(this.source);
        } else {
          object.drawObject();
        }
      }
      // }
    });
    Object.values(this.participants).forEach((participant) => {
      participant.drawAvatar();
      if (participant.cursor.active) {
        participant.drawCursor();
      }
      if (participant.hoover) {
        this.highlightParticipant(participant);
      }
    });
    if (!this.me.adjustSizeAvatar.adjust) {
      this.me.drawAvatar();
      if (this.me.cursor.active) {
        this.me.drawCursor();
      }
    }
    if (this.placeingObject.isPlacing) {
      const pt = this.ctx.transformedPoint(this.lastX, this.lastY);
      // eslint-disable-next-line prefer-destructuring
      const size = window.mingly.msBroadCastOnCanvasData.size;
      // eslint-disable-next-line prefer-destructuring
      const aspectRatio = window.mingly.msBroadCastOnCanvasData.aspectRatio;
      const adj = 0.5 * size;
      const xnew = pt.x - adj;
      const ynew = pt.y - adj / aspectRatio;
      this.ctx1.globalAlpha = 0.2;
      this.ctx1.fillStyle = 'red';
      this.ctx1.fillRect(xnew, ynew, size, size / aspectRatio);
      this.ctx1.globalAlpha = 1;
    }
  }

  updateSideCanvas() {
    this.ctxSide0.save();
    this.ctxSide0.globalCompositeOperation = 'copy';
    this.ctxSide0.strokeStyle = 'transparent';
    this.ctxSide0.beginPath();
    this.ctxSide0.lineTo(0, 0);
    this.ctxSide0.stroke();
    this.ctxSide0.restore();

    this.ctxSide.save();
    this.ctxSide.globalCompositeOperation = 'copy';
    this.ctxSide.strokeStyle = 'transparent';
    this.ctxSide.beginPath();
    this.ctxSide.lineTo(0, 0);
    this.ctxSide.stroke();
    this.ctxSide.restore();

    if (this.room.backgroundPicture && this.room.backgroundPicture.complete) {
      this.ctxSide0.drawImage(this.room.backgroundPicture, 0, 0,
        this.canvasSide0.width, this.canvasSide0.height);
    }
    Object.values(this.participants).forEach((participant) => {
      this.drawSideCanvas(participant);
    });
  }

  updateAll() {
    this.setLeadingView();
    this.clearAll();
    // this.ctx.beginPath();
    // this.ctx.rect(0, 0, board.width,board.height);

    // don't try to draw a non-existant or partially loaded/"broken" image
    // todo: hmm. still get Uncaught DOMException: Failed to execute 'drawImage' on
    //       'CanvasRenderingContext2D': The HTMLImageElement provided is in the 'broken' state.
    if (this.room.backgroundPicture && this.room.backgroundPicture.complete) {
      // this.ctx0.drawImage(this.room.backgroundPicture, 0, 0, this.width, this.height);
      this.drawBackgroundPicture(this.ctx0, this.width, this.height);
    }

    if (this.displayVideo) {
      cancelAnimationFrame(this.backgroundVideo.reqAnimation);
      this.drawExternalVideo(this.backgroundVideo);
    }
    // draw the quiz mode
    if (this.quiz.inQuizMode) {
      this.drawQuizAlternatives();
    }

    // this.ctx.globalAlpha = 0.3;
    // this.ctx.lineWidth = 3;
    // this.ctx.strokeStyle ="#33BEFF";

    // this.ctx.stroke();
    // this.ctx.globalAlpha = 1;
    Object.values(this.canvasObjects).forEach((object) => {
      // if (object.type === 'picture') {
      // should have a swtich here to choose correct canvas
      if (object.isActive) {
        if (object.type === 'canvasVideo') {
          cancelAnimationFrame(object.reqAnimation);
          object.drawVideo(this.source);
        } else {
          object.drawObject();
        }
      }
      // }
    });
    Object.values(this.participants).forEach((participant) => {
      participant.drawAvatar();
      if (participant.cursor.active) {
        participant.drawCursor();
      }
      if (participant.hoover) {
        this.highlightParticipant(participant);
      }
    });
    // we should make this a canvas object instead
    if (this.placeingObject.isPlacing) {
      const pt = this.ctx.transformedPoint(this.lastX, this.lastY);
      // eslint-disable-next-line prefer-destructuring
      const size = window.mingly.msBroadCastOnCanvasData.size;
      // eslint-disable-next-line prefer-destructuring
      const aspectRatio = window.mingly.msBroadCastOnCanvasData.aspectRatio;
      const adj = 0.5 * size;
      const xnew = pt.x - adj;
      const ynew = pt.y - adj / aspectRatio;
      this.ctx1.globalAlpha = 0.2;
      this.ctx1.fillStyle = 'red';
      this.ctx1.fillRect(xnew, ynew, size, size / aspectRatio);
      this.ctx1.globalAlpha = 1;
    }
    if (!this.me.adjustSizeAvatar.adjust) {
      this.me.drawAvatar();
      if (this.me.cursor.active) {
        this.me.drawCursor();
      }
    }
  }

  changeVideoOnCanvasPars(vidsize, x, y) {
    this.backgroundVideo.x = x;
    this.backgroundVideo.y = y;
    this.backgroundVideo.size = vidsize;
    this.updateAll();
  }

  addVideoToCanvas(vidsource, vidsize, x, y, muted = false) {
    if (this.backgroundVideo.reqAnimation) {
      this.backgroundVideo.video.pause();
      cancelAnimationFrame(this.backgroundVideo.reqAnimation);
      this.updateAll();
    }
    this.backgroundVideo.x = x;
    this.backgroundVideo.y = y;
    this.backgroundVideo.size = vidsize;
    this.backgroundVideo.video = document.createElement('video');
    this.backgroundVideo.video.setAttribute('autoplay', '');
    if (window.mingly.msBroadCastType === 'ms_canvas_screen'
    || window.mingly.msBroadCastType === 'ms_canvas_screen_and_screenaudio') {
      this.backgroundVideo.videosource = 'ms_canvas_screen';
      this.backgroundVideo.video = vidsource;
      this.drawExternalVideo(this.backgroundVideo);
    } else {
      this.backgroundVideo.videosource = vidsource;
      this.backgroundVideo.video.src = vidsource;
      this.backgroundVideo.video.addEventListener('canplay', () => {
        this.drawExternalVideo(this.backgroundVideo);
        if (muted) {
          this.backgroundVideo.video.muted = true;
        }
        window.mingly.log('Video can start, but not sure it will play through.');
      });
    }
  }

  drawQuizAlternatives() {
    // this can probably be automated in a better way
    const alt = this.quiz.alternatives;
    const strQuestion = this.quiz.data.question;
    const fontHeight = 20;
    this.createFittedTextBox(strQuestion, 0, this.width / 2, 50, fontHeight,
      'center', false, 'Arial', 'white', 'black', 0.5, false);
    // const font = 'Arial';
    // this.ctx0.globalAlpha = 0.2;
    // this.ctx0.fillRect(this.width / 3, this.width * (2 / 3),
    //   fontHeight + 10, this.width - this.width / 2 + 10);
    // this.ctx0.globalAlpha = 1;
    // this.ctx0.font = `${fontHeight}px ${font}`;
    // this.ctx0.fillStyle = 'white';
    // this.ctx0.textAlign = 'center';
    // this.ctx0.fillText(strQuestion, this.width / 2, fontHeight + 5,
    //   this.width - this.width / 2);
    const answers = this.quiz.data.alternatives;
    let dx;
    let dy;
    switch (alt) {
      case 2:
        dy = this.height / 4;
        dx = this.width / 4;
        this.drawAnswerBox(dx / 2, dy, dx, 2 * dy, answers[0].data);
        this.drawAnswerBox(5 * (dx / 2), dy, dx, 2 * dy, answers[1].data);
        break;
      case 3:
        dy = this.height / 4;
        dx = this.width / 4;
        this.drawAnswerBox(dx / 2, dy / 2, dx, dy, answers[0].data);
        this.drawAnswerBox(5 * (dx / 2), dy / 2, dx, dy, answers[1].data);
        this.drawAnswerBox(dx / 2, 5 * (dy / 2), dx, dy, answers[2].data);
        break;
      case 4:
        dy = this.height / 4;
        dx = this.width / 4;
        this.drawAnswerBox(dx / 2, dy / 2, dx, dy, answers[0].data);
        this.drawAnswerBox(5 * (dx / 2), dy / 2, dx, dy, answers[1].data);
        this.drawAnswerBox(dx / 2, 5 * (dy / 2), dx, dy, answers[2].data);
        this.drawAnswerBox(5 * (dx / 2), 5 * (dy / 2), dx, dy, answers[3].data);
        break;
      case 5:
        dy = this.height / 4;
        dx = this.width / 6;
        this.drawAnswerBox(dx / 2, dy / 2, dx, dy, answers[0].data);
        this.drawAnswerBox(5 * (dx / 2), dy / 2, dx, dy, answers[1].data);
        this.drawAnswerBox(9 * (dx / 2), dy / 2, dx, dy, answers[2].data);
        this.drawAnswerBox(dx / 2, 5 * (dy / 2), dx, dy, answers[3].data);
        this.drawAnswerBox(5 * (dx / 2), 5 * (dy / 2), dx, dy, answers[4].data);
        break;
      case 6:
        dy = this.height / 4;
        dx = this.width / 6;
        this.drawAnswerBox(dx / 2, dy / 2, dx, dy, answers[0].data);
        this.drawAnswerBox(5 * (dx / 2), dy / 2, dx, dy, answers[1].data);
        this.drawAnswerBox(9 * (dx / 2), dy / 2, dx, dy, answers[2].data);
        this.drawAnswerBox(dx / 2, 5 * (dy / 2), dx, dy, answers[3].data);
        this.drawAnswerBox(5 * (dx / 2), 5 * (dy / 2), dx, dy, answers[4].data);
        this.drawAnswerBox(9 * (dx / 2), 5 * (dy / 2), dx, dy, answers[5].data);
        break;
      default:
        window.mingly.log('Not a valid alternative number for quiz');
        break;
    }
  }

  drawAnswerBox(x, y, width, height, string) {
    const fontHeight = 20;
    const font = 'Arial';
    this.ctx0.beginPath();
    this.ctx0.lineWidth = '3';
    this.ctx0.strokeStyle = 'white';
    this.ctx0.rect(x, y, width, height);
    this.ctx0.stroke();
    this.ctx0.globalAlpha = 0.5;
    this.ctx0.fillStyle = 'black';
    this.ctx0.fillRect(x, y, width, height);
    this.ctx0.globalAlpha = 1;
    this.ctx0.font = `${fontHeight}px ${font}`;
    this.ctx0.fillStyle = 'white';
    this.ctx0.textAlign = 'center';
    this.ctx0.fillText(string, x + width / 2, y + height / 2, width - width / 5);
  }

  checkIfAnsweredQuiz() {
    // this function checks if you answered
    if (!this.quiz.hasAnswered) {
      const alt = this.quiz.alternatives;
      window.mingly.log('check if answered');
      let dx;
      let dy;
      const checkedAnswer = [];
      switch (alt) {
        case 2:
          dy = this.height / 4;
          dx = this.width / 4;
          if (this.me.x < dx / 2 + dx + this.avatarRadius &&
            this.me.x > dx / 2 - this.avatarRadius &&
            this.me.y < dy + 2 * dy + this.avatarRadius &&
            this.me.y > dy - this.avatarRadius) {
            // You have answered alternative 1
            checkedAnswer.push(0);
          }
          if (this.me.x < 5 * (dx / 2) + dx + this.avatarRadius &&
            this.me.x > 5 * (dx / 2) - this.avatarRadius &&
            this.me.y < dy + 2 * dy + this.avatarRadius &&
            this.me.y > dy - this.avatarRadius) {
            // You have answered alternative 2
            checkedAnswer.push(1);
          }
          break;
        case 3:
          dy = this.height / 4;
          dx = this.width / 4;
          if (this.me.x < dx / 2 + dx + this.avatarRadius &&
            this.me.x > dx / 2 - this.avatarRadius &&
            this.me.y < dy / 2 + dy + this.avatarRadius &&
            this.me.y > dy / 2 - this.avatarRadius) {
            // You have answered alternative 1
            checkedAnswer.push(0);
          }
          if (this.me.x < 5 * (dx / 2) + dx + this.avatarRadius &&
            this.me.x > 5 * (dx / 2) - this.avatarRadius &&
            this.me.y < dy / 2 + dy + this.avatarRadius &&
            this.me.y > dy / 2 - this.avatarRadius) {
            // You have answered alternative 1
            checkedAnswer.push(1);
          }
          if (this.me.x < dx / 2 + dx + this.avatarRadius &&
            this.me.x > dx / 2 - this.avatarRadius &&
            this.me.y < 5 * (dy / 2) + dy + this.avatarRadius &&
            this.me.y > 5 * (dy / 2) - this.avatarRadius) {
            // You have answered alternative 1
            checkedAnswer.push(2);
          }
          break;
        case 4:
          dy = this.height / 4;
          dx = this.width / 4;
          if (this.me.x < dx / 2 + dx + this.avatarRadius &&
            this.me.x > dx / 2 - this.avatarRadius &&
            this.me.y < dy / 2 + dy + this.avatarRadius &&
            this.me.y > dy / 2 - this.avatarRadius) {
            // You have answered alternative 1
            checkedAnswer.push(0);
          }
          if (this.me.x < 5 * (dx / 2) + dx + this.avatarRadius &&
            this.me.x > 5 * (dx / 2) - this.avatarRadius &&
            this.me.y < dy / 2 + dy + this.avatarRadius &&
            this.me.y > dy / 2 - this.avatarRadius) {
            // You have answered alternative 1
            checkedAnswer.push(1);
          }
          if (this.me.x < dx / 2 + dx + this.avatarRadius &&
            this.me.x > dx / 2 - this.avatarRadius &&
            this.me.y < 5 * (dy / 2) + dy + this.avatarRadius &&
            this.me.y > 5 * (dy / 2) - this.avatarRadius) {
            // You have answered alternative 1
            checkedAnswer.push(2);
          }
          if (this.me.x < 5 * (dx / 2) + dx + this.avatarRadius &&
            this.me.x > 5 * (dx / 2) - this.avatarRadius &&
            this.me.y < 5 * (dy / 2) + dy + this.avatarRadius &&
            this.me.y > 5 * (dy / 2) - this.avatarRadius) {
            // You have answered alternative 1
            checkedAnswer.push(3);
          }
          break;
        case 5:
          dy = this.height / 4;
          dx = this.width / 6;
          if (this.me.x < dx / 2 + dx + this.avatarRadius &&
            this.me.x > dx / 2 - this.avatarRadius &&
            this.me.y < dy / 2 + dy + this.avatarRadius &&
            this.me.y > dy / 2 - this.avatarRadius) {
            // You have answered alternative 1
            checkedAnswer.push(0);
          }
          if (this.me.x < 5 * (dx / 2) + dx + this.avatarRadius &&
            this.me.x > 5 * (dx / 2) - this.avatarRadius &&
            this.me.y < dy / 2 + dy + this.avatarRadius &&
            this.me.y > dy / 2 - this.avatarRadius) {
            // You have answered alternative 1
            checkedAnswer.push(1);
          }
          if (this.me.x < 9 * (dx / 2) + dx + this.avatarRadius &&
            this.me.x > 9 * (dx / 2) - this.avatarRadius &&
            this.me.y < dy / 2 + dy + this.avatarRadius &&
            this.me.y > dy / 2 - this.avatarRadius) {
            // You have answered alternative 1
            checkedAnswer.push(2);
          }
          if (this.me.x < dx / 2 + dx + this.avatarRadius &&
            this.me.x > dx / 2 - this.avatarRadius &&
            this.me.y < 5 * (dy / 2) + dy + this.avatarRadius &&
            this.me.y > 5 * (dy / 2) - this.avatarRadius) {
            // You have answered alternative 1
            checkedAnswer.push(3);
          }
          if (this.me.x < 5 * (dx / 2) + dx + this.avatarRadius &&
            this.me.x > 5 * (dx / 2) - this.avatarRadius &&
            this.me.y < 5 * (dy / 2) + dy + this.avatarRadius &&
            this.me.y > 5 * (dy / 2) - this.avatarRadius) {
            // You have answered alternative 1
            checkedAnswer.push(4);
          }
          break;
        case 6:
          dy = this.height / 4;
          dx = this.width / 6;
          if (this.me.x < dx / 2 + dx + this.avatarRadius &&
            this.me.x > dx / 2 - this.avatarRadius &&
            this.me.y < dy / 2 + dy + this.avatarRadius &&
            this.me.y > dy / 2 - this.avatarRadius) {
            // You have answered alternative 1
            checkedAnswer.push(0);
          }
          if (this.me.x < 5 * (dx / 2) + dx + this.avatarRadius &&
            this.me.x > 5 * (dx / 2) - this.avatarRadius &&
            this.me.y < dy / 2 + dy + this.avatarRadius &&
            this.me.y > dy / 2 - this.avatarRadius) {
            // You have answered alternative 1
            checkedAnswer.push(1);
          }
          if (this.me.x < 9 * (dx / 2) + dx + this.avatarRadius &&
            this.me.x > 9 * (dx / 2) - this.avatarRadius &&
            this.me.y < dy / 2 + dy + this.avatarRadius &&
            this.me.y > dy / 2 - this.avatarRadius) {
            // You have answered alternative 1
            checkedAnswer.push(2);
          }
          if (this.me.x < dx / 2 + dx + this.avatarRadius &&
            this.me.x > dx / 2 - this.avatarRadius &&
            this.me.y < 5 * (dy / 2) + dy + this.avatarRadius &&
            this.me.y > 5 * (dy / 2) - this.avatarRadius) {
            // You have answered alternative 1
            checkedAnswer.push(3);
          }
          if (this.me.x < 5 * (dx / 2) + dx + this.avatarRadius &&
            this.me.x > 5 * (dx / 2) - this.avatarRadius &&
            this.me.y < 5 * (dy / 2) + dy + this.avatarRadius &&
            this.me.y > 5 * (dy / 2) - this.avatarRadius) {
            // You have answered alternative 1
          }
          if (this.me.x < 9 * (dx / 2) + dx + this.avatarRadius &&
            this.me.x > 9 * (dx / 2) - this.avatarRadius &&
            this.me.y < 5 * (dy / 2) + dy + this.avatarRadius &&
            this.me.y > 5 * (dy / 2) - this.avatarRadius) {
            // You have answered alternative 1
            checkedAnswer.push(5);
          }
          break;
        default:
          window.mingly.log('Not a valid alternative number for quiz');
          break;
      }
      if (this.quiz.data.quiz === true) {
        // measure time
        const timeStart = this.me.quizTime;
        this.me.quizTime = performance.now() - timeStart;
        // end measure time
        const quizData = {
          checkedAlternatives: checkedAnswer,
          creatorId: this.quiz.data.creatorId,
          time: this.me.quizTime,
          correctAnswer: false,
        };
        // mingly.log(this.me.quizTime);
        answerQuiz(quizData);
        this.me.quizTime = null;
      } else {
        const pollAnswerData = {
          checkedAlternatives: checkedAnswer,
          creatorId: this.quiz.data.creatorId,
          isAnonymous: false,
        };
        answerPoll(pollAnswerData);
      }
      this.quiz.hasAnswered = true;
    }
  }

  drawExternalVideo(backGroundVideo) {
    const v = backGroundVideo.video;
    if (v.paused || v.ended || v == null) {
      window.mingly.log('backgrounvideo stopped');
      if (this.displayVideo) {
        this.displayVideo = false;
      }
      this.displayVideo = false;
      cancelAnimationFrame(this.backgroundVideo.reqAnimation);
      this.updateAll();
      if (window.mingly.msBroadCastOnCanvasData.active) {
        closeMSbroadCast();
        window.mingly.msBroadCastOnCanvasData = {
          id: null,
          x: null,
          y: null,
          size: null,
          aspectRatio: null,
          active: false,
        };
      }
      return false;
    }

    if (!this.displayVideo) {
      this.displayVideo = true;
    }
    const w = backGroundVideo.size;
    const h = (v.videoHeight / v.videoWidth) * w;

    this.ctx0.drawImage(v, backGroundVideo.x, backGroundVideo.y, w, h);
    backGroundVideo.reqAnimation = requestAnimationFrame(
      this.drawExternalVideo.bind(this, backGroundVideo));
    return true; // be consistent with previous returns
  }

  drawVideo(participant) {
    if (participant.videoElement == null || this.me.playNone ||
       this.me.doNotPlayAnyVideosOnCanvas ||
       participant.noVideo) return false;
    const v = participant.videoElement;
    if (v.paused || v.ended || v == null) return false;

    // if(v.paused || v.ended) return false;
    let x;
    let y;
    let w;
    let h;
    if (participant.adjustSizeAvatar.adjust) {
      const rad = participant.adjustSizeAvatar.adjustSize;
      x = participant.adjustSizeAvatar.adjustX - rad + this.videoBorderWidth;
      y = participant.adjustSizeAvatar.adjustY - rad + this.videoBorderWidth;
      w = 2 * rad - (2 * this.videoBorderWidth);
      h = 2 * rad - (2 * this.videoBorderWidth);
    } else {
      x = participant.x - this.avatarRadius + this.videoBorderWidth;
      y = participant.y - this.avatarRadius + this.videoBorderWidth;
      w = this.avatarSize - (2 * this.videoBorderWidth);
      h = this.avatarSize - (2 * this.videoBorderWidth);
    }
    let videoFontSize;
    if (participant.name.length <= 12) {
      videoFontSize = Math.round(this.avatarSize / 15);
    } else if (participant.name.length <= 17 && participant.name.length > 12) {
      videoFontSize = Math.round(this.avatarSize / 20);
    } else {
      videoFontSize = Math.round(this.avatarSize / 30);
    }
    const aspRat = v.videoWidth / v.videoHeight;
    if (participant.isDJ || participant.screenShare) {
      if (aspRat > 1) {
        this.ctx.drawImage(v, x, y + w * 0.5 * (1 - 1 / aspRat), w, w / aspRat);
      } else if (aspRat < 1) {
        this.ctx.drawImage(v, x + w * 0.5 * (1 - aspRat), y, w * aspRat, w);
      }
      else {
        this.ctx.drawImage(v, x, y, w, w);
      }
    }
    else {
      if (aspRat > 1) {
        const viddiff = v.videoWidth - v.videoHeight;
        this.drawImageManipulated(v, Math.round(viddiff / 2), 0, v.videoHeight,
          v.videoHeight, x, y, w, h, this.ctx, participant.flipVideo);
        // to flip video do this:
        // this.ctx.save();
        // this.ctx.scale(-1, 1);
        // this.ctx.drawImage(v, Math.round(viddiff / 2), 0, v.videoHeight,
        //   v.videoHeight, -x - w, y, w, h);
        // this.ctx.restore();
        // this.ctx.setTransform(1, 0, 0, 1, 0, 0);
      }
      else if (aspRat < 1) {
        const viddiff = v.videoHeight - v.videoWidth;
        this.drawImageManipulated(v, 0, Math.round(viddiff / 2),
          v.videoWidth, v.videoWidth, x, y, w, h, this.ctx, participant.flipVideo);
      }
      else {
        // this.ctx.drawImage(v, x, y, w, h);
        this.drawImageManipulated(v, 0, 0, v.videoWidth, v.videoHeight,
          x, y, w, h, this.ctx, participant.flipVideo);
      }
      // this.ctx.globalAlpha = 0.5;
      // if (participant.uuid === this.me.uuid) {
      //   this.ctx.fillStyle = 'blue';
      // } else {
      //   this.ctx.fillStyle = 'black';
      // }
      // this.ctx.fillRect(x + (this.avatarRadius - this.videoBorderWidth) / 5,
      //   y + w - h / 9.1,
      //   (this.avatarRadius - this.videoBorderWidth) * (8 / 5), this.avatarRadius / 5.7);
      // this.ctx.globalAlpha = 1;
      // this.ctx.fillStyle = 'white';
      // const font = `${videoFontSize}px Arial`;
      // this.ctx.font = font;
      // // this.ctx.textAlign = 'left';
      // this.ctx.textAlign = 'center';
      // // this.ctx.fillText(participant.name, x + (h / 20), (y + w - ((h + 4)) / 30));
      // this.ctx.fillText(participant.name, x + this.avatarRadius - this.videoBorderWidth,
      //   (y + w - ((h + 4)) / 30));
      // this.ctx.globalAlpha = 1;
      let color = 'black';
      if (participant.uuid === this.me.uuid) {
        color = 'blue';
      }
      this.createFittedTextBox(participant.name, 1, x + this.avatarRadius - this.videoBorderWidth,
        (y + w - ((h + 4)) / 30), videoFontSize,
        'center', false, 'Arial', 'white', color, 0.5, false);
    }
    if (participant.muted && !participant.screenShare) {
      this.ctx.drawImage(this.mutedIcon, x + w * (4 / 5), y, w / 5, h / 5);
    }
    /*
    Blue background
    this.ctx.globalAlpha = 0.5;
    this.ctx.fillStyle = '#33BEFF';
    this.ctx.fillRect(x + (3 / 10), y + w - ((h + 2)/ 10), (w - (6 / 10)), h / 10);
    */

    if (participant.hoover) {
      this.highlightParticipant(participant);
      // this.ctx.strokeStyle = 'red';
      // this.ctx.strokeRect(participant.x - this.avatarRadius, participant.y - this.avatarRadius,
      //   this.avatarSize, this.avatarSize);
    }

    participant.reqAnimation = requestAnimationFrame(this.drawVideo.bind(this, participant));
    return true; // be consistent with previous returns
  }

  drawImageManipulated(image, sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight, ctx, flipped) {
    if (flipped) {
      ctx.save();
      ctx.scale(-1, 1);
      dx = -dx - dWidth;
    }
    this.ctx.drawImage(image, sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight);
    if (flipped) {
      ctx.restore();
    }
  }

  zoom(clicks, keyboard = false) {
    // this.me.playNone = true; // turning it on makes you enter avatar mode
    let pt;
    // let pt0;
    if (keyboard === true) {
      pt = this.ctx.transformedPoint(
        window.innerWidth / 2,
        window.innerHeight / 2,
      );
    }
    else {
      pt = this.ctx.transformedPoint(this.lastX, this.lastY);
    }

    if (this.ctx.getTransform(this.width, this.height).a > this.maxZoom) {
      if (clicks < 0) {
        this.ctx.translate(pt.x, pt.y);
        this.ctx0.translate(pt.x, pt.y);
        this.ctx1.translate(pt.x, pt.y);
        const factor = Math.pow(this.scaleFactor, clicks);
        this.ctx.scale(factor, factor);
        this.ctx0.scale(factor, factor);
        this.ctx1.scale(factor, factor);
        this.ctx.translate(-pt.x, -pt.y);
        this.ctx0.translate(-pt.x, -pt.y);
        this.ctx1.translate(-pt.x, -pt.y);
        this.updateAll();
      }
    }
    else if (this.ctx.getTransform(this.width, this.height).a < this.minZoom) {
      if (clicks > 0) {
        this.ctx.translate(pt.x, pt.y);
        this.ctx0.translate(pt.x, pt.y);
        this.ctx1.translate(pt.x, pt.y);
        const factor = Math.pow(this.scaleFactor, clicks);
        this.ctx.scale(factor, factor);
        this.ctx0.scale(factor, factor);
        this.ctx1.scale(factor, factor);
        this.ctx.translate(-pt.x, -pt.y);
        this.ctx0.translate(-pt.x, -pt.y);
        this.ctx1.translate(-pt.x, -pt.y);
        this.updateAll();
      }
    }
    else {
      this.ctx.translate(pt.x, pt.y);
      this.ctx0.translate(pt.x, pt.y);
      this.ctx1.translate(pt.x, pt.y);
      const factor = Math.pow(this.scaleFactor, clicks);
      this.ctx.scale(factor, factor);
      this.ctx0.scale(factor, factor);
      this.ctx1.scale(factor, factor);
      this.ctx.translate(-pt.x, -pt.y);
      this.ctx0.translate(-pt.x, -pt.y);
      this.ctx1.translate(-pt.x, -pt.y);
      this.updateAll();
    }
    setTimeout(() => { this.startTimerCanvas(); }, this.videoUpdateDelay);
  }

  startTimerCanvas() {
    this.me.playNone = false;
    this.updateAll();
  }

  resetZoom() {
    this.ctx.setTransform(1, 0, 0, 1, (window.innerWidth - this.width) / 2,
      (window.innerHeight - this.height) / 2);
    this.ctx0.setTransform(1, 0, 0, 1, (window.innerWidth - this.width) / 2,
      (window.innerHeight - this.height) / 2);
    this.ctx1.setTransform(1, 0, 0, 1, (window.innerWidth - this.width) / 2,
      (window.innerHeight - this.height) / 2);
    this.updateAll();
  }

  zoomtoParticipant(participant) {
    const xDir = -participant.x * this.focusFactor + window.innerWidth / 2;
    const yDir = -participant.y * this.focusFactor + window.innerHeight / 2;

    this.ctx.setTransform(this.focusFactor, 0, 0, this.focusFactor, xDir, yDir);
    this.ctx0.setTransform(this.focusFactor, 0, 0, this.focusFactor, xDir, yDir);
    this.ctx1.setTransform(this.focusFactor, 0, 0, this.focusFactor, xDir, yDir);
    this.updateAll();
  }

  resizeCanvas() {
    window.mingly.log('Canvasboard.resizeCanvas: resizing canvas');
    this.canvas1.setAttribute('width', String(window.innerWidth));
    this.canvas1.setAttribute('height', String(window.innerHeight));
    this.canvas.setAttribute('width', String(window.innerWidth));
    this.canvas.setAttribute('height', String(window.innerHeight));
    this.canvas0.setAttribute('width', String(window.innerWidth));
    this.canvas0.setAttribute('height', String(window.innerHeight));
    this.ctx.setTransform(1, 0, 0, 1, (window.innerWidth - this.width) / 2,
      (window.innerHeight - this.height) / 2);
    this.ctx0.setTransform(1, 0, 0, 1, (window.innerWidth - this.width) / 2,
      (window.innerHeight - this.height) / 2);
    this.ctx1.setTransform(1, 0, 0, 1, (window.innerWidth - this.width) / 2,
      (window.innerHeight - this.height) / 2);
    this.updateAll();
  }

  moveTowards(x, y) {
    let ratio = 10000;
    const xdir = Math.sign(x - this.me.x);
    const ydir = Math.sign(y - this.me.y);
    let newposX;
    let newposY;

    cancelAnimationFrame(this.me.reqAnimation);

    if (this.me.x !== x) {
      ratio = Math.abs((y - this.me.y) / (x - this.me.x));
    }

    if (ratio > 1) {
      this.me.setSpeed(Math.round(this.maxSpeed / ratio), this.maxSpeed);
    }
    else {
      this.me.setSpeed(this.maxSpeed, Math.round(this.maxSpeed * ratio));
    }

    // eslint-disable-next-line max-len
    if (Math.abs(x - this.me.x) < this.minMouseJump && Math.abs(y - this.me.y) < this.minMouseJump) {
      newposX = x;
      newposY = y;
    }
    else {
      newposX = this.me.x + (xdir * this.me.speedX);
      newposY = this.me.y + (ydir * this.me.speedY);
    }
    this.me.moveTo(newposX, newposY);
  }

  // event handlers
  onLocalPlay() {
    this.localVideo.srcObject.muted = true;
    this.drawAvatarBackground(this.me);
    this.drawVideo(this.me);
  }

  showMenu() {
    if (this.menuOverlay) {
      const chatbox = document.getElementById('chatbox');
      const buttonsMenu = document.getElementById('taskbox');

      if (document.getElementById('locationArea')) {
        document.getElementById('locationArea').setAttribute('id', 'locationAreaCanvas');
      }
      const locArea = document.getElementById('locationAreaCanvas');
      locArea.hidden = true;

      chatbox.hidden = true;
      buttonsMenu.hidden = true;
      this.menuOverlay = false;
    }
    else {
      const chatbox = document.getElementById('chatbox');
      const buttonsMenu = document.getElementById('taskbox');
      if (document.getElementById('locationArea')) {
        document.getElementById('locationArea').setAttribute('id', 'locationAreaCanvas');
      }
      const locArea = document.getElementById('locationAreaCanvas');
      document.getElementById('chessboardContainer').hidden = true;
      document.getElementById('RoomName').hidden = true;
      document.getElementById('PlaceStart').hidden = true;
      buttonsMenu.style.opacity = this.opacityLevel;
      locArea.style.opacity = this.opacityLevel;
      chatbox.style.opacity = this.opacityLevel;
      locArea.hidden = false;
      chatbox.hidden = false;
      buttonsMenu.hidden = false;
      this.menuOverlay = true;
    }
  }

  ongoingTouchIndexById(e) {
    for (let i = 0; i < this.ongoingTouches.length; i += 1) {
      const id = this.ongoingTouches[i].pointerId;

      if (id === e.pointerId) {
        return i;
      }
    }
    return -1; // not found
  }

  handleCancel() {
    // this is the old way that should remove just the one the is canceled
    // (but there are sometime issues):
    // const idx = this.ongoingTouchIndexById(e);
    // this.ongoingTouches.splice(idx, 1); // remove it; we're done

    // instead we go for the brute force of emptying it all
    // (not sure how often you want to keep one finger on the screen after two)
    this.ongoingTouches = [];
  }

  onMouseDown(e) {
    if (this.hasWaited) {
      this.hasInteracted = true;
    }

    if (this.placeingObject.isPlacing) {
      if (this.placeingObject) {
        const pt = this.ctx.transformedPoint(this.lastX, this.lastY);
        const clickX = pt.x;
        const clickY = pt.y;
        this.placeingObject.placingFunction(clickX, clickY);
        // set last click
        this.lastX = e.offsetX || (e.pageX - this.canvas1.offsetLeft);
        this.lastY = e.offsetY || (e.pageY - this.canvas1.offsetTop);
      }
      this.placeingObject.placeingObject = null;
      this.placeingObject.isPlacing = false;
      return;
    }
    if (this.me.isGhost && e.altKey) {
      const pt = this.ctx.transformedPoint(this.lastX, this.lastY);
      const clickX = pt.x;
      const clickY = pt.y;
      this.me.moveTo(clickX, clickY);
      // this.lastX = e.offsetX || (e.pageX - this.canvas1.offsetLeft);
      // this.lastY = e.offsetY || (e.pageY - this.canvas1.offsetTop);
      return;
    }
    // some multitouch
    this.ongoingTouches.push(e);
    if ((this.ongoingTouches.length > 1) && this.isTouch) {
      this.moreTouch = true;
      const Ntouch = this.ongoingTouches.length;

      const e1 = this.ongoingTouches[0];
      const e2 = this.ongoingTouches[Ntouch - 1]; // most recent touch
      // const e2 = this.ongoingTouches[1]; //2nd touch
      const lastX1 = e1.offsetX || (e1.pageX - this.canvas1.offsetLeft);
      const lastY1 = e1.offsetY || (e1.pageY - this.canvas1.offsetTop);
      const lastX2 = e2.offsetX || (e2.pageX - this.canvas1.offsetLeft);
      const lastY2 = e2.offsetY || (e2.pageY - this.canvas1.offsetTop);
      // let pt1 = this.ctx.transformedPoint(this.lastX1, this.lastY1);
      // et pt2 = this.ctx.transformedPoint(this.lastX2, this.lastY2);

      const diff1 = lastX1 - lastX2;
      const diff2 = lastY1 - lastY2;
      // this.initialDistTouch = Math.round(Math.sqrt(Math.pow(diff1,2)+Math.pow(diff2,2)));

      // let diff1 = pt1.x-pt2.x;
      // let diff2 = pt1.y-pt2.y;
      this.initialDistTouch = Math.round(Math.sqrt(Math.pow(diff1, 2) + Math.pow(diff2, 2)));
      window.mingly.log('Canvasboard.onMouseDown: This is multi start. '
                + `lastX1: ${lastX1}, lastX2: ${lastX2}, client x: ${e1.clientX}`);
    }

    if (!this.move_keyboard) {
      this.move_keyboard = true;
    } else {
      e.preventDefault();
    }

    if (e.shiftKey) {
      this.resetZoom();
      return;
    }
    // THIS IS AN OLD WAY OF MOVING
    // if (e.metaKey || e.ctrlKey) {
    //   if (this.mouseTimer) {
    //     clearInterval(this.mouseTimer);
    //   }

    //   const pt = this.ctx.transformedPoint(this.lastX, this.lastY);
    //   const clickX = pt.x;
    //   const clickY = pt.y;
    //   this.mouseTimer = setInterval(this.moveTowards.bind(this), 30, clickX, clickY);
    //   return;
    // }
    Object.values(this.canvasObjects).forEach((object) => {
      if (object.isActive) {
        if (object.onClick) {
          // this is working ok, but still hanging a bit with drag after popOut videos
          this.dragStart = null;
          object.mouseCursorOnObjectForClick(this.lastX, this.lastY);
        }
      }
    });
    if (this.fullscreenMode) {
      Object.values(this.participants).forEach((participant) => {
        window.mingly.log(`Canvasboard.onMouseDown: fullscreen, hoover = ${participant.hoover}`);
        if (participant.hoover) {
          if (participant.isSleeping) {
            callParticipant(participant);
          } else {
            this.activateParticipantMenu(participant);
          }
        }
      });
    }
    this.lastX = e.offsetX || (e.pageX - this.canvas1.offsetLeft);
    this.lastY = e.offsetY || (e.pageY - this.canvas1.offsetTop);
    // check if hoover over myself
    const transformMat = this.ctx.getTransform();
    // dropping the effect of rotating as we don't allow it now
    const leftC  = (transformMat.a * (this.me.x - this.avatarRadius)) + transformMat.e; // eslint-disable-line no-multi-spaces,max-len
    const rightC = (transformMat.a * (this.me.x + this.avatarRadius)) + transformMat.e; // eslint-disable-line no-multi-spaces,max-len
    const uppC   = (transformMat.d * (this.me.y - this.avatarRadius)) + transformMat.f; // eslint-disable-line no-multi-spaces,max-len
    const lowC   = (transformMat.d * (this.me.y + this.avatarRadius)) + transformMat.f; // eslint-disable-line no-multi-spaces,max-len

    // eslint-disable-next-line max-len,space-in-parens
    const testConditionDragMe = (window.mingly.hostStates.lockedMove && !this.me.isHost);
    if ((this.lastX < rightC) && (leftC < this.lastX) && (this.lastY < lowC) && (uppC < this.lastY)
        && !testConditionDragMe) {
      this.dragMe = true;
      this.dragMeStartx = this.me.x;
      this.dragMeStarty = this.me.y;
      cancelAnimationFrame(this.me.reqAnimation);
      this.me.speedX = this.maxSpeed;
      this.me.speedY = this.maxSpeed;
    } else {
      this.dragged = false;
    }
    this.dragStart = this.ctx.transformedPoint(this.lastX, this.lastY);

  }

  onMouseUp(e) {
    e.preventDefault();
    this.handleCancel(e); // Moved it first now
    if (this.ongoingTouches.length < 2) {
      window.mingly.log('cancel moreTouch');
      this.moreTouch = false;
    }

    if (this.mouseTimer) {
      clearInterval(this.mouseTimer);
      if (this.onUpdateConnections) {
        this.onUpdateConnections(false); // be careful with this one
      }
    }

    if (this.dragMe) {
      this.dragMe = false;
      this.me.speedX = 0;
      this.me.speedY = 0;
      if (this.quiz.inQuizMode) {
        window.mingly.log('Check if answered should be called');
        this.checkIfAnsweredQuiz();
      }
      // this.drawAvatarBackground(this.me)
      // this.drawVideo(this.me);
      this.me.videovar = 1;
      this.drawAvatar(this.me);
      if (this.onMuteDistantParticipants) {
        this.onMuteDistantParticipants(); // inefficient
      }

      if (this.onUpdateConnections) {
        this.onUpdateConnections(true); // be careful with this one
      }

    }
    // this.drawAvatarBackground(this.me);
    this.drawVideo(this.me);
    this.dragStart = null;

    // not sure what this does now, so disable it
    // if (!this.dragged) {
    //   this.zoom(e.shiftKey ? -1 : 1);
    // }

  }

  drawAvatarBackground(participant) {

    if (!this.doBackground || this.isGhost) { return; }

    // eslint-disable-next-line max-len
    // this.ctx.fillRect(participant.x-this.avatarRadius,participant.y-this.avatarRadius,this.avatarSize,this.avatarSize);
    // eslint-disable-next-line max-len
    // this.ctx.roundedRectangle(participant.x-this.avatarRadius,participant.y-this.avatarRadius,this.avatarSize,this.avatarSize,this.avatarSize/5); // eslint-disable-line max-len
    // this.ctx.fill();
    // this.ctx.stroke();
    const style = this.videoBackGroundStyle;

    // todo: switch?
    if (style === 1) {
      const x = participant.x - this.avatarRadius + 1;
      const y = participant.y - this.avatarRadius + 1;
      const height = this.avatarSize - 2;
      const width = this.avatarSize - 2;
      const rounded = Math.round(this.avatarSize / 5);

      // const radiansInCircle = 2 * Math.PI;
      const halfRadians = (2 * Math.PI) / 2;
      const quarterRadians = (2 * Math.PI) / 4;
      // eslint-disable-next-line max-len
      // let grd1 = this.ctx.createRadialGradient(participant.x,participant.y,participant.x, participant.x, participant.y,2);
      // const grd1 = this.ctx.createLinearGradient(x, y, participant.x, y);
      // grd1.addColorStop(0, 'black');
      // grd1.addColorStop(0.2, 'gray');
      // grd1.addColorStop(1, 'black');

      // eslint-disable-next-line max-len
      // let grd2 = this.ctx.createLinearGradient(participant.x, y, participant.x+0.5*this.avatarSize, y);
      // grd2.addColorStop(0, "gray");
      // grd2.addColorStop(1, "black");

      // this.ctx.globalAlpha = 0.5;
      this.ctx.beginPath();

      // rounded rect/square
      this.ctx.fillStyle = 'gray'; // grd1;
      // this.ctx.fillStyle = grd1;
      this.ctx.arc(rounded + x, rounded + y, rounded, -quarterRadians, halfRadians, true);
      // this.ctx.fill();

      // this.ctx.fillStyle = "black";

      // line from top left to bottom left
      this.ctx.lineTo(x, y + height - rounded);

      // bottom left arc
      this.ctx.arc(rounded + x, height - rounded + y, rounded, halfRadians, quarterRadians, true);

      // line from bottom left to bottom right
      this.ctx.lineTo(x + width - rounded, y + height);

      // line from top right to top left

      // bottom right arc
      this.ctx.arc(x + width - rounded, y + height - rounded, rounded, quarterRadians, 0, true);

      // line from bottom right to top right
      this.ctx.lineTo(x + width, y + rounded);

      // top right arc
      this.ctx.arc(x + width - rounded, y + rounded, rounded, 0, -quarterRadians, true);
      // line from top right to top left
      // this.ctx.lineTo(x - rounded+0.5*width, y);
      // this.ctx.fill();
      // line from top right to top left
      this.ctx.lineTo(x + rounded, y);

      this.ctx.fill();
      // this.ctx.globalAlpha = 1.0;
    }
    else if (style === 2) {
      this.ctx.lineWidth = 1;
      const x = participant.x - this.avatarRadius + this.videoBorderWidth;
      const y = participant.y - this.avatarRadius + this.videoBorderWidth;
      const height = this.avatarSize - (2 * this.videoBorderWidth);
      const width = this.avatarSize - (2 * this.videoBorderWidth);
      // const scale = this.ctx.getTransform(this.width, this.height).a;
      if (participant.movingUsingKeyboardNow) {
        this.ctx.beginPath();
        this.ctx.rect(
          x - (this.videoBorderWidth / 2),
          y - (this.videoBorderWidth / 2),
          height + this.videoBorderWidth,
          width + this.videoBorderWidth);
        if (participant.uuid === this.me.uuid) {
          this.ctx.fillStyle = 'blue';
        } else {
          this.ctx.fillStyle = participant.color;
        }
        this.ctx.fill();
      } else {
        if (!participant.videovar || participant.noVideo || this.me.playNone ||
          this.me.doNotPlayAnyVideosOnCanvas || participant.speedX !== 0 ||
          participant.speedY !== 0) {
          this.ctx.beginPath();
          this.ctx.arc(participant.x, participant.y,
            (this.avatarSize / 2) - (this.videoBorderWidth / 2),
            0, 2 * Math.PI);
          if (participant.uuid === this.me.uuid) {
            this.ctx.fillStyle = 'blue';
          } else {
            this.ctx.fillStyle = participant.color;
          }
          this.ctx.fill();
        }
        else {
          this.ctx.beginPath();
          this.ctx.rect(
            x - (this.videoBorderWidth / 2),
            y - (this.videoBorderWidth / 2),
            height + this.videoBorderWidth,
            width + this.videoBorderWidth);
          if (participant.uuid === this.me.uuid) {
            this.ctx.fillStyle = 'blue';
          } else {
            this.ctx.fillStyle = participant.color;
          }
          this.ctx.fill();
        }
      }
    }
    else {
      const x = participant.x - this.avatarRadius + 1;
      const y = participant.y - this.avatarRadius + 1;
      const height = this.avatarSize - 2;
      const width = this.avatarSize - 2;
      const videoLength = this.avatarSize - (2 * this.videoBorderWidth);
      // const rounded = Math.round(this.avatarSize / 5);
      const rounded = Math.round(this.videoBorderWidth - 2);

      // const radiansInCircle = 2 * Math.PI;
      const halfRadians = (2 * Math.PI) / 2;
      const quarterRadians = (2 * Math.PI) / 4;
      // eslint-disable-next-line max-len
      // let grd1 = this.ctx.createRadialGradient(participant.x,participant.y,participant.x, participant.x, participant.y,2);
      // const grd1 = this.ctx.createLinearGradient(x, y, participant.x, y);
      // const grd2 = this.ctx.createLinearGradient(x, y, participant.x, y);
      // const grd3 = this.ctx.createLinearGradient(x, y, participant.x, y);

      // grd1.addColorStop(0, "gray");
      // grd1.addColorStop(1, "black");

      // grd2.addColorStop(0, 'black');
      // grd2.addColorStop(0.2, 'gray');
      // grd2.addColorStop(1, 'black');

      // grd3.addColorStop(0, "black");
      // grd3.addColorStop(1, "gray");

      // eslint-disable-next-line max-len
      // let grd2 = this.ctx.createLinearGradient(participant.x, y, participant.x+0.5*this.avatarSize, y);

      // Upper left corner
      // const grd1 = this.ctx.createLinearGradient(participant.x, participant.y,x,y);
      const grad = this.ctx.createRadialGradient(
        participant.x,
        participant.y,
        videoLength,
        participant.x,
        participant.y,
        this.avatarSize / 3);

      grad.addColorStop(0, 'black');
      grad.addColorStop(1, 'white');
      this.ctx.beginPath();
      this.ctx.fillStyle = grad;
      this.ctx.arc(rounded + x, rounded + y, rounded, -quarterRadians, halfRadians, true);
      this.ctx.lineTo(x, y + rounded);
      this.ctx.lineTo(x + rounded, y + rounded);
      this.ctx.fill();

      // Upper rectangle
      this.ctx.moveTo(x + rounded, y);
      // const grd2 = this.ctx.createLinearGradient(x, y,x,y+rounded);
      // const grd2 = this.ctx.createLinearGradient(participant.x, participant.y,x+width/2,y);
      // grd2.addColorStop(0, "black");
      // grd2.addColorStop(1, "gray");
      this.ctx.beginPath();
      this.ctx.fillStyle = grad;
      this.ctx.lineTo(x + width - rounded, y);
      this.ctx.lineTo(x + width - rounded, y + rounded);
      this.ctx.lineTo(x + rounded, y + rounded);
      this.ctx.lineTo(x + rounded, y);
      this.ctx.fill();

      // Upper right corner
      this.ctx.moveTo(x + rounded, y);
      // const grd3 = this.ctx.createLinearGradient(participant.x, participant.y,x + width,y);
      // grd3.addColorStop(0, "black");
      // grd3.addColorStop(1, "gray");
      this.ctx.beginPath();
      this.ctx.fillStyle = grad;
      this.ctx.arc(x + width - rounded, y + rounded, rounded, 0, -quarterRadians, true);
      this.ctx.lineTo(x + width - rounded, y + rounded);
      this.ctx.lineTo(x + width, y + rounded);
      this.ctx.fill();

      // Right rectangle
      this.ctx.moveTo(x + width, y + rounded);
      // const grd4 = this.ctx.createLinearGradient(x + width - rounded, y + rounded,x + width,y);
      // grd4.addColorStop(0, "black");
      // grd4.addColorStop(1, "gray");
      this.ctx.beginPath();
      this.ctx.fillStyle = grad;
      this.ctx.lineTo(x + width, y + width - rounded);
      this.ctx.lineTo(x + width - rounded, y + width - rounded);
      this.ctx.lineTo(x + width - rounded, y + rounded);
      this.ctx.lineTo(x + width, y + rounded);
      this.ctx.fill();

      // Lower right Corner
      this.ctx.moveTo(x + width, y + rounded);
      // const grd5 = this.ctx.createLinearGradient(x + width - rounded, y + rounded,x + width,y);
      // grd5.addColorStop(0, "black");
      // grd5.addColorStop(1, "gray");
      this.ctx.beginPath();
      this.ctx.fillStyle = grad;
      this.ctx.arc(x + width - rounded, y + height - rounded, rounded, quarterRadians, 0, true);
      this.ctx.lineTo(x + width - rounded, y + width - rounded);
      this.ctx.lineTo(x + width - rounded, y + width);
      this.ctx.fill();

      // Lower rectangle
      this.ctx.moveTo(x + width, y + width);
      // const grd6 = this.ctx.createLinearGradient(x + width - rounded, y + rounded,x + width,y);
      // grd6.addColorStop(0, "black");
      // grd6.addColorStop(1, "gray");
      this.ctx.beginPath();
      this.ctx.fillStyle = grad;
      this.ctx.lineTo(x + rounded, y + width);
      this.ctx.lineTo(x + rounded, y + width - rounded);
      this.ctx.lineTo(x + width - rounded, y + width - rounded);
      this.ctx.lineTo(x + width - rounded, y + width);
      this.ctx.fill();

      // Lower left  corner
      // const grd7 = this.ctx.createLinearGradient(x + width - rounded, y + rounded,x + width,y);
      // grd7.addColorStop(0, "black");
      // grd7.addColorStop(1, "gray");
      this.ctx.beginPath();
      this.ctx.fillStyle = grad;
      this.ctx.arc(rounded + x, height - rounded + y, rounded, halfRadians, quarterRadians, true);
      this.ctx.lineTo(x + rounded, y + width - rounded);
      this.ctx.lineTo(x, y + width - rounded);
      this.ctx.fill();

      // Left rectangle
      this.ctx.moveTo(x + width, y + width - rounded);
      // const grd8 = this.ctx.createLinearGradient(x + width - rounded, y + rounded,x + width,y);
      // grd8.addColorStop(0, "black");
      // grd8.addColorStop(1, "gray");
      this.ctx.beginPath();
      this.ctx.fillStyle = grad;
      this.ctx.lineTo(x, y + width - rounded);
      this.ctx.lineTo(x, y + rounded);
      this.ctx.lineTo(x + rounded, y + rounded);
      this.ctx.lineTo(x + rounded, y + width - rounded);
      this.ctx.fill();

      // inner box
      this.ctx.moveTo(x + width, y + rounded);
      this.ctx.beginPath();
      this.ctx.fillStyle = grad;
      this.ctx.rect(x + rounded, y + rounded, width - (2 * rounded), width - (2 * rounded));
      // this.ctx.lineTo(x+width-rounded, y+rounded);
      // this.ctx.lineTo(x+width-rounded, y+width-rounded);
      // this.ctx.lineTo(x+rounded, y+width-rounded);
      // this.ctx.lineTo(x+ width,y+rounded);
      this.ctx.fill();

    }

  }

  doIntroAnimation() {
    // const { board } = window.mingly;
    // const { me } = board;
    // set opacity

    // this.canvas0.setAttribute('style', 'background-color:
    // transparent; position: absolute; opacity:0.5;');
    // this.canvasBackGround.setAttribute('style', 'position: absolute; top: 0;
    // bottom: 0; left: 0; right: 0; width: 100%; height: 100%; opacity:0.5;');
    // zoom in
    // var factor = this.focusFactor/10000;
    // for(let k = 0; k <10000; k++){
    //   let step = factor*(1+k);
    //   console.log("step: " + step);
    //   this.ctx.setTransform(step, 0, 0, step,
    //     -(this.me.x - this.canvas.offsetTop) * this.focusFactor + 0.5 *
    //      (this.width - this.canvas.offsetTop) + this.avatarSize, // eslint-disable-line max-len
    //     -(this.me.y - this.canvas.offsetTop) * this.focusFactor + 0.5 *
    //      (this.height - this.canvas.offsetTop) - this.avatarSize, // eslint-disable-line max-len
    //   );
    //   this.ctx0.setTransform(step, 0, 0, step,
    //     -(this.me.x - this.canvas.offsetTop) * this.focusFactor + 0.5 *
    //      (this.width - this.canvas.offsetTop) + this.avatarSize, // eslint-disable-line max-len
    //     -(this.me.y - this.canvas.offsetTop) * this.focusFactor + 0.5 *
    //      (this.height - this.canvas.offsetTop) - this.avatarSize, // eslint-disable-line max-len
    //   );
    //   this.updateAll();
    // }

    // this.ctx.beginPath();
    // this.ctx.rect(
    //   this.me.x - this.avatarRadius,
    //   this.me.y - this.avatarRadius,
    //   this.avatarSize,
    //   this.avatarSize);
    // this.ctx.lineWidth = '3';
    // this.ctx.strokeStyle = 'red';
    // this.ctx.stroke();
    // set the adjust to true
    this.me.adjustSizeAvatar.adjust = true;
    this.me.adjustSizeAvatar.adjustSize = 200;
    // start in center
    this.me.adjustSizeAvatar.adjustX = this.width / 2;
    this.me.adjustSizeAvatar.adjustY = this.height / 2;
    const stepX = (this.me.x - this.me.adjustSizeAvatar.adjustX)
    / (this.me.adjustSizeAvatar.adjustSize - this.avatarRadius);
    const stepY = (this.me.y - this.me.adjustSizeAvatar.adjustY)
    / (this.me.adjustSizeAvatar.adjustSize - this.avatarRadius);
    const myTimer = setInterval(() => {
      if (this.me.adjustSizeAvatar.adjustSize > this.avatarRadius) {
        this.me.adjustSizeAvatar.adjustSize -= 1;
        this.me.adjustSizeAvatar.adjustX += stepX;
        this.me.adjustSizeAvatar.adjustY += stepY;
      }
      else {
        this.me.adjustSizeAvatar.adjust = false;
        this.me.adjustSizeAvatar.adjustSize = this.avatarRadius;
        clearInterval(myTimer);
        this.drawAvatarBackground(this.me);
        if (!this.me.noVideo) {
          this.drawVideo(this.me);
        }
        else {
          this.drawAvatar(this.me);
        }
      }
      this.updateAll();
    }, 10);
    // clearInterval(myTimer);
    // setTimeout(() => {
    //   this.canvas0.setAttribute('style', 'background-color: transparent;
    // position: absolute; opacity:1;');
    //   this.canvasBackGround.setAttribute('style', 'position: absolute; top: 0;
    //  bottom: 0; left: 0; right: 0; width: 100%; height: 100%; opacity:1;');
    //   window.mingly.log('update Opacity');
    //   // this.updateAll();
    // }, 2000);

  }

  onMouseMove(e) {

    // if more touches do zoom
    if (this.ongoingTouches.length > 1 && this.isTouch) {

      for (let i = 0; i < this.ongoingTouches.length; i += 1) {
        if (e.pointerId === this.ongoingTouches[i].pointerId) {
          this.ongoingTouches[i] = e;
          break;
        }
      }

      const Ntouch = this.ongoingTouches.length;
      const e1 = this.ongoingTouches[0];
      const e2 = this.ongoingTouches[Ntouch - 1];
      const lastX1 = e1.offsetX || (e1.pageX - this.canvas1.offsetLeft);
      const lastY1 = e1.offsetY || (e1.pageY - this.canvas1.offsetTop);
      const lastX2 = e2.offsetX || (e2.pageX - this.canvas1.offsetLeft);
      const lastY2 = e2.offsetY || (e2.pageY - this.canvas1.offsetTop);

      // let pt1 = board.ctx.transformedPoint(this.lastX1, this.lastY1);
      // let pt2 = board.ctx.transformedPoint(this.lastX2, this.lastY2);

      const diff1 = lastX1 - lastX2;
      const diff2 = lastY1 - lastY2;
      // this.initialDistTouch = Math.round(Math.sqrt(Math.pow(diff1,2)+Math.pow(diff2,2)));

      // let diff1 = pt1.x-pt2.x;
      // let diff2 = pt1.y-pt2.y;

      // let diff1 = e1.clientX-e2.clientX;
      // let diff2 = e1.clientY-e2.clientY;
      const dist = Math.round(Math.sqrt(Math.pow(diff1, 2) + Math.pow(diff2, 2)));
      const delta = (dist - this.initialDistTouch) / 150;

      this.lastX = 0.5 * (lastX1 + lastX2);
      this.lastY = 0.5 * (lastY1 + lastY2);
      // if(Math.abs(delta)>10){

      window.mingly.log('Canvasboard.onMouseMove: double scroll. '
                + `Delta ${delta}, the other: ${this.initialDistTouch} `
                + `the first: ${dist} lastX1 ${lastX1} lastX2 ${lastX2} `
                + `client x1 ${e1.clientX}, client x2: ${e2.clientX}`);
      // this.initialDistTouch = dist;
      this.zoom(delta);
      // }
      // let pinchDist = Math.round()

    }
    else {
      // deleting it here but this should instead be an object
      if (this.placeingObject.isPlacing) {
        const pt = this.ctx.transformedPoint(this.lastX, this.lastY);
        // eslint-disable-next-line prefer-destructuring
        const size = window.mingly.msBroadCastOnCanvasData.size;
        // eslint-disable-next-line prefer-destructuring
        const aspectRatio = window.mingly.msBroadCastOnCanvasData.aspectRatio;
        const adj = 0.5 * size;
        const xnew = pt.x - adj;
        const ynew = pt.y - adj / aspectRatio;
        const extra = size * 1.5;
        this.ctx1.clearRect(xnew - 0.5 * extra, ynew - 0.5 * extra,
          size * extra, (size / aspectRatio) * extra);
      }
      this.lastX = e.offsetX || (e.pageX - this.canvas1.offsetLeft);
      this.lastY = e.offsetY || (e.pageY - this.canvas1.offsetTop);

      // if placing the share screen
      if (this.placeingObject.isPlacing) {
        const pt = this.ctx.transformedPoint(this.lastX, this.lastY);
        // eslint-disable-next-line prefer-destructuring
        const size = window.mingly.msBroadCastOnCanvasData.size;
        // eslint-disable-next-line prefer-destructuring
        const aspectRatio = window.mingly.msBroadCastOnCanvasData.aspectRatio;
        const adj = 0.5 * size;
        const xnew = pt.x - adj;
        const ynew = pt.y - adj / aspectRatio;
        this.ctx1.globalAlpha = 0.2;
        this.ctx1.fillStyle = 'red';
        this.ctx1.fillRect(xnew, ynew, size, size / aspectRatio);
        this.ctx1.globalAlpha = 1;
      }
      // moving cursor
      if (this.me.cursor.active) {
        const pt = this.ctx.transformedPoint(this.lastX, this.lastY);
        const newX = pt.x;
        const newY = pt.y;
        this.me.moveCursorTo(newX, newY);
      }

      this.dragged = true;
      if (this.dragStart) {
        // this.me.playNone = true; //switch to avatar mode
        const pt = this.ctx.transformedPoint(this.lastX, this.lastY);
        if (this.dragMe === true) {
          // todo: when is this.me defined?
          this.me.playNone = true;
          // const transformMat = this.ctx.getTransform();
          // const rightC = transformMat.a * this.me.x + transformMat.e;
          // const uppC   = transformMat.d * this.me.y + transformMat.f;
          const newX = (pt.x - this.dragStart.x) + this.dragMeStartx;
          const newY = (pt.y - this.dragStart.y) + this.dragMeStarty;
          this.me.moveTo(newX, newY);
        }
        else {
          this.ctx.translate(pt.x - this.dragStart.x, pt.y - this.dragStart.y);
          this.ctx0.translate(pt.x - this.dragStart.x, pt.y - this.dragStart.y);
          this.ctx1.translate(pt.x - this.dragStart.x, pt.y - this.dragStart.y);
          this.updateAll();
        }

        setTimeout(() => { this.startTimerCanvas(); }, this.videoUpdateDelay);
      }
      Object.values(this.canvasObjects).forEach((object) => {
        // might want a better way of doing this as onHover might be null
        try {
          if (object.onHover.hover && object.isActive) {
            object.mouseCursorOnObjectForHover(this.lastX, this.lastY);
          }
        } catch {
          window.mingly.log('Hover object did not work');
        }
      });
      if (this.hoverMode) {
        const transformMat = this.ctx.getTransform();
        Object.values(this.participants).forEach((participant) => {
          // dropping the effect of rotating as we don't allow it now
          const leftC  = (transformMat.a * (participant.x - this.avatarRadius)) + transformMat.e; // eslint-disable-line no-multi-spaces,max-len
          const rightC = (transformMat.a * (participant.x + this.avatarRadius)) + transformMat.e; // eslint-disable-line no-multi-spaces,max-len
          const uppC   = (transformMat.d * (participant.y - this.avatarRadius)) + transformMat.f; // eslint-disable-line no-multi-spaces,max-len
          const lowC   = (transformMat.d * (participant.y + this.avatarRadius)) + transformMat.f; // eslint-disable-line no-multi-spaces,max-len

          // eslint-disable-next-line max-len
          if ( (this.lastX < rightC) && (leftC < this.lastX) && (this.lastY < lowC) && (uppC < this.lastY) ) { // eslint-disable-line space-in-parens
            if (!participant.isGhost) {
              participant.hoover = true;
            }
            if (this.onHoover) {
              try {
                this.onHoover(participant);
              }
              catch {
                window.mingly.log('Canvasboard.onMouseMove: call to hoover event listener failed');
              }
            }
            const elem = document.getElementById(`listusers_${participant.uuid}`);
            if (elem) {
              elem.style.backgroundColor = 'red';
            }
            if (!participant.isGhost) {
              this.highlightParticipant(participant);
            }
            // this.ctx.globalAlpha = 0.5;
            // this.ctx.strokeStyle = 'red';
            // this.ctx.fillRect(participant.x - this.avatarRadius,
            //   participant.y - this.avatarRadius,
            // this.avatarSize, this.avatarSize);
            // this.ctx.globalAlpha = 1;

          }
          else {
            if (participant.hoover) {
              participant.hoover = false;
              if (this.onHoover) {
                try {
                  this.onHoover(participant);
                }
                catch {
                  window.mingly.log('Canvasboard.onMouseMove: call to hoover event listener failed');
                }
              }
              const elem = document.getElementById(`listusers_${participant.uuid}`);
              if (elem) {
                elem.style.backgroundColor = '';
              }
              this.updateAll();
            }
          }
        });
      }
    }
  }

  onMouseScroll(e) {
    const detail = e.detail ? -e.detail : 0;
    const delta = e.wheelDelta ? e.wheelDelta / 40 : detail;

    if (delta) {
      this.zoom(delta);
    }

    // might still need this:
    // if (!this.me.videoElement.play()) {
    //   this.me.videoElement.play();
    // }

    return e.preventDefault() && false;
  }

  canvasObjectFittedBoxWrapperToParticipant(x, y, width, height, text, ctx, dat) {
    // the dat variable should have
    // textSize = 10
    //  allignment = 'center', scaling = false, font = 'Arial', textColor = 'white',
    // boxColor = 'black', opaquenessBox = 0.5, clear = false
    // note that this does not work on me (as this is not a participant in the object)
    const sizeBox = this.board.createFittedTextBox(text, dat.canvasnumber,
      this.board.participants[dat.participantuuid].x + dat.tiltX,
      this.board.participants[dat.participantuuid].y + dat.tiltY,
      dat.textSize,
      dat.allignment, dat.scaling, dat.font, dat.textColor,
      dat.boxColor, dat.opaquenessBox, dat.clear);
    this.x = sizeBox.x;
    this.y = sizeBox.y;
    this.width = sizeBox.width;
    this.height = sizeBox.height;
  }

  popOutVideo(participant) {
    if (this.isExternal) {
      window.mingly.log('Canvasboard.onMouseDown.click');
      if (this.onHooverClick) {
        try {
          this.onHooverClick(participant);
        }
        catch {
          window.mingly.log('Hoover and click not working');
        }
      }
      participant.hoover = false;
      if (this.onHoover) {
        try {
          this.onHoover(participant);
        }
        catch {
          window.mingly.log('CanvasBoard.onMouseDown: could not set hoover event listener');
        }
      }
    }
    else {
      window.mingly.log(`CanvasBoard.onMouseDown: hoover and click on ${participant.name}, VideoVar is ${participant.videovar}`);
      if (participant.videovar === 1) {
        window.mingly.log(`CanvasBoard.onMouseDown: hoover and click on ${participant.name}  AND`);
        document.getElementById('mainCanvas').hidden = true;
        document.getElementById('videoContainerArea').hidden = false;
        this.fullscreenNow = true;
        const vidContainer = document.getElementById(`remoteVideo_${participant.uuid}`);
        if (vidContainer.getAttribute('class') === 'videoContainer') {
          document.documentElement.style.setProperty('--vidArea', 'initial');
          document.documentElement.style.setProperty('--canArea', 'none');
          vidContainer.setAttribute('class', 'videoContainerBig');
          const nameTop = 5;
          const nameLeft = 285;
          document.documentElement.style.setProperty('--nameTop', `${nameTop.toString()}px`);
          document.documentElement.style.setProperty('--nameLeft', `${nameLeft.toString()}px`);
        }
        participant.hoover = false; // think we have to reset it here
        if (this.onHoover) {
          try {
            this.onHoover(participant);
          }
          catch {
            window.mingly.log('CanvasBoard.onMouseDown:could not set hoover event listener');
          }
        }
        const elem = document.getElementById(`listusers_${participant.uuid}`);
        if (elem) {
          elem.style.backgroundColor = '';
        }
      }
      // We don't want to handle anything else
      // eslint-disable-next-line no-useless-return
    }
  }

  activateParticipantMenu(participant) {
    // there is a error here showing up
    // in view on isMenuInitiated
    if (participant.isMenuInitiated) {
      participant.isMenuActive = !participant.isMenuActive;
      if (!participant.isMenuActive) {
        for (let i = 0; i < participant.connectedObjects.length; i += 1) {
          const uuid = participant.connectedObjects[i];
          this.canvasObjects[uuid].isActive = false;
          this.updateAll();
        }
      } else {
        for (let i = 0; i < participant.connectedObjects.length; i += 1) {
          const uuid = participant.connectedObjects[i];
          this.canvasObjects[uuid].isActive = true;
          this.updateAll();
        }
      }
    } else {
      this.participantMenu(participant);
    }
  }

  participantMenu(participant) {
    participant.isMenuActive = !participant.isMenuActive;
    participant.isMenuInitiated = true;
    const x = 0;
    const y = 0;
    const width = this.avatarSize; // need to see if this is ok
    const height = this.avatarSize / 10;
    // first menue
    const objectFollowView = new CanvasObject(x, y, width, height,
      this.canvasObjectFittedBoxWrapperToParticipant,
      this.canvas1, 'canvasDrawing', this);
    objectFollowView.text = 'Follow participant\'s view';
    objectFollowView.uuid = createUUID();
    objectFollowView.onClick = () => this.onAskToFollowView(participant);
    function hoverFunFollowView() {
      objectFollowView.dat.boxColor = 'red';
      this.update();
    }
    function noHoverFunFollowView() {
      objectFollowView.dat.boxColor = 'black';
      // it is a bit slow, so not sure how to improve
      // this.clear();
      // this.drawObject();
      this.update();
    }
    objectFollowView.onHover = {
      hover: true,
      fun: hoverFunFollowView,
      nofun: noHoverFunFollowView,
    };
    const canvasNumber = 2;
    objectFollowView.dat = {
      allignment: 'center',
      scaling: false,
      font: 'Arial',
      textColor: 'white',
      boxColor: 'black',
      opaquenessBox: 0.5,
      clear: false,
      textSize: 3,
      participantuuid: participant.uuid,
      tiltX: this.avatarSize * 1.4,
      tiltY: -this.avatarRadius,
      canvasnumber: canvasNumber,
    };
    this.canvasObjects[objectFollowView.uuid] = objectFollowView;
    participant.connectedObjects.push(objectFollowView.uuid);
    // Second menue
    const objectFollow = new CanvasObject(x, y, width, height,
      this.canvasObjectFittedBoxWrapperToParticipant,
      this.canvas1, 'canvasDrawing', this);
    objectFollow.text = 'Follow participant';
    objectFollow.uuid = createUUID();
    objectFollow.onClick = () => {
      if (this.me.followingMove.isFollowing) {
        this.me.followingMove.isFollowing = false;
        this.me.followingMove.following = '';
      } else {
        this.me.followingMove.isFollowing = true;
        this.me.followingMove.following = participant.uuid;
      }
    };
    function hoverFunFollow() {
      objectFollow.dat.boxColor = 'red';
      this.update();
    }
    function noHoverFunFollow() {
      objectFollow.dat.boxColor = 'black';
      this.update();
    }
    objectFollow.onHover = {
      hover: true,
      fun: hoverFunFollow,
      nofun: noHoverFunFollow,
    };
    objectFollow.dat = {
      allignment: 'center',
      scaling: false,
      font: 'Arial',
      textColor: 'white',
      boxColor: 'black',
      opaquenessBox: 0.5,
      clear: false,
      textSize: 3,
      participantuuid: participant.uuid,
      tiltX: this.avatarSize * 1.4,
      tiltY: 0,
      canvasnumber: canvasNumber,
    };
    this.canvasObjects[objectFollow.uuid] = objectFollow;
    participant.connectedObjects.push(objectFollow.uuid);
    // Second menue
    const objectPopOut = new CanvasObject(x, y, width, height,
      this.canvasObjectFittedBoxWrapperToParticipant,
      this.canvas1, 'canvasDrawing', this);
    objectPopOut.text = 'Popout Video';
    objectPopOut.uuid = createUUID();
    // objectPopOut.onClick = function () {
    //   console.log('clicking on popOut');
    // };
    objectPopOut.onClick = () => this.popOutVideo(participant);
    function hoverFunPopOut() {
      objectPopOut.dat.boxColor = 'red';
      this.update();
    }
    function noHoverFunPopOut() {
      objectPopOut.dat.boxColor = 'black';
      this.update();
    }
    objectPopOut.onHover = {
      hover: true,
      fun: hoverFunPopOut,
      nofun: noHoverFunPopOut,
    };
    objectPopOut.dat = {
      allignment: 'center',
      scaling: false,
      font: 'Arial',
      textColor: 'white',
      boxColor: 'black',
      opaquenessBox: 0.5,
      clear: false,
      textSize: 3,
      participantuuid: participant.uuid,
      tiltX: this.avatarSize * 1.4,
      tiltY: this.avatarRadius,
      canvasnumber: canvasNumber,
    };
    this.canvasObjects[objectPopOut.uuid] = objectPopOut;
    participant.connectedObjects.push(objectPopOut.uuid);
    this.updateAll();
  }
}
