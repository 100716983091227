<template>
  <div @click="checkActive()">
    <vue-drag-resize :isResizable="false"
                     :w="this.width" :h="this.height"
                     :x="this.left" :y="this.top">
      <v-card dark :height="this.height" :width="this.width">
        <CloseButton :method="closePoll" class="close_button" />
        <v-row class="pa-3">
          <v-col>
            <v-text-field
              v-model="question"
              class="text_field"
              style="padding-bottom: 1.5vh;"
              light
              rounded
              background-color="white" placeholder="Enter question here" hide-details />
            <div class="scrollbar">
              <div v-for="(entry, index) in alternatives" :key="index">
                <v-row>
                  <v-col class="pa-2">
                    <v-text-field
                      class="text_field"
                      light
                      v-model="entry.data"
                      rounded
                      background-color="white" placeholder="Enter alternative" hide-details />
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col style="text-align: right;">
            <v-btn v-on:click="increaseAlternatives()">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-col>
          <v-col>
            <v-btn v-on:click="decreaseAlternatives()">
              <v-icon>mdi-minus</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pa-2">
            <v-card-actions>
              <v-checkbox
                label="Mingly mode"
                v-on:click="onCheckedAlternatives()"
                class="ma-0" />
            </v-card-actions>
          </v-col>
        </v-row>
        <v-row>
          <v-col style="text-align: center;">
            <v-btn v-on:click="launchSurvey()">Launch</v-btn>
          </v-col>
        </v-row>
        <div v-if="this.pollData.isActive" style="color: red; text-align: center;">
          {{ this.errorMessage }}
        </div>
      </v-card>
    </vue-drag-resize>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import VueDragResize from 'vue-drag-resize';
import { sendPoll } from '../../../../assets/js/webrtc';
import CloseButton from '../CloseButton.vue';

export default {
  name: 'CreatePoll',
  components: {
    VueDragResize,
    CloseButton,
  },
  data: () => ({
    errorMessage: 'There is already a poll running, please wait.',
    top: 0.15 * window.innerHeight,
    left: 0.02 * window.innerWidth,
    width: 0.25 * window.innerWidth,
    height: 0.42 * window.innerHeight,
    question: '',
    canvasType: false,
    alternativesCount: 2,
    alternatives: [
      { data: '', index: 0 },
      { data: '', index: 1 },
    ],
  }),
  computed: {
    ...mapGetters('$_userdata', ['pollData']),
  },
  methods: {
    ...mapActions('$_canvas', [
      'commitSetPollActive',
      'commitSetStatCreatorPollActive',
    ]),
    ...mapActions('$_userdata', [
      'commitSetPoll',
    ]),
    closePoll() {
      this.commitSetPollActive();
    },
    onCheckedAlternatives() {
      this.canvasType = !this.canvasType;
    },
    launchSurvey() {
      // Todo: send to participants. this.question, this.alternatives
      // Todo: prompt result menu
      if (this.pollData.isActive) {
        console.log('Poll is already active');
      } else {
        const pollDat = {
          question: this.question,
          alternatives: this.alternatives,
          creatorId: window.mingly.board.me.uuid,
          isAnonymous: false,
          isActive: true,
          onCanvas: this.canvasType,
          quiz: false,
          timed: false,
          answerSelf: false,
          correctAnswerPos: null,
        };
        this.commitSetPoll(pollDat);
        sendPoll(pollDat);
        this.commitSetPollActive();
        this.commitSetStatCreatorPollActive();
      }
    },
    increaseAlternatives() {
      this.alternatives.push({ data: '', index: this.alternativesCount });
      this.alternativesCount += 1;
    },
    decreaseAlternatives() {
      if (this.alternativesCount >= 2) {
        this.alternativesCount -= 1;
        this.alternatives.pop();
      }
    },
    checkActive() {
      window.mingly.board.move_keyboard = false;
    },
  },
};
</script>

<style scoped>
  .close_button {
    position: absolute;
    right: 0;
  }
  .text_field {
    padding-right: 1vh;
    padding-left: 1vh;
    padding-top: 0;
  }
  .scrollbar {
    height: 15vh;
    overflow-y: auto;
    overflow-x: hidden;
  }
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background: #b9b9b9;
  }
</style>
