export default class Room {
  constructor(name, maxNumberVideos, moveLimitVideo, upperVideoCutoff, upperAudioCutoff,
    useXirsys, soundDecay, testing) {
    // todo: this.boardType
    this.name = name;
    this.roomURL = null;
    this.roomOwner = 'Mingly'; // set it to Mingly by default
    this.enabled = true;
    this.wsurl = null;
    this.maxNumberVideos = maxNumberVideos || 8;
    this.moveLimitVideo = moveLimitVideo || 1;
    this.upperVideoCutoff = upperVideoCutoff || 10;
    this.upperAudioCutoff = upperAudioCutoff || 1000;
    this.useXirsys = useXirsys || false;
    this.soundDecay = soundDecay || 1;
    this.testing = testing || false;
    this.editBandWidth = false; // need to make this nicer
    this.editVideoBandWidthOnly = false; // we should not have two separate variables for bandwidth
    this.audioBW = 60; // need to make this nicer
    this.videoBW = 300;
    this.nParticipants = 0; // number of particiants

    this.videoWidth = 320;
    this.videoHeight = 240;
    this.videoConfig = 'ideal'; // max | exact
    // this.frameRate = null; // could be  set later

    this.scalingFactor = null;
    this.brutalUpdate = false; // update the whole canvas instead of clearing avatar backgrounds
    this.smoothAudio = false; // room expects audio constraints to be tuned to perfection(tm)

    this.backgroundPicture = new Image(); // we might want to have an onload here
    this.backGroundPictureUpdated = false; // this we can change once on server for managing this
    this.currentBackGroundPicture = null; // just for now, once on server we can remove it
    this.youTubeVideoId = null;
    this.youTubePlaylist = null;

    // mediaserver variables
    this.mediaserver = false; // default is no mediaserver
  }

  setup(maxNumberVideos, moveLimitVideo, upperVideoCutoff, upperAudioCutoff, useXirsys) {
    this.maxNumberVideos = maxNumberVideos;
    this.moveLimitVideo = moveLimitVideo;
    this.upperVideoCutoff = upperVideoCutoff;
    this.upperAudioCutoff = upperAudioCutoff;
    this.useXirsys = useXirsys;
  }

  setupVideo(videoWidth, videoHeight, videoConfig, frameRate) {
    this.videoWidth = videoWidth;
    this.videoHeight = videoHeight;
    switch (videoConfig) {
      case 'max':
      case 'exact':
      case 'ideal':
        break;
      default:
        console.error(`Room.setupVideo: unhandled videoConfig: ${videoConfig}`);
    }
    this.frameRate = frameRate || null;
  }

  peerConnectionConfig() {
    if (this.useXirsys) {
      return {
        iceServers: [
          {
            urls: ['stun:eu-turn7.xirsys.com'],
          },
          {
            username: 'TtqoRCtM6cikkHvDfXtYdfPZJJFzUNzsIoqe6DYlopYrhB-VPHNXdS9JGGDHMTrNAAAAAF9Y6i1jaGhleWVyZGFobGxhcnNlbg==',
            credential: 'e433e0b4-f2aa-11ea-b7fe-0242ac140004',
            urls: [
              'turn:eu-turn7.xirsys.com:80?transport=udp',
              'turn:eu-turn7.xirsys.com:3478?transport=udp',
              'turn:eu-turn7.xirsys.com:80?transport=tcp',
              'turn:eu-turn7.xirsys.com:3478?transport=tcp',
              'turns:eu-turn7.xirsys.com:443?transport=tcp',
              'turns:eu-turn7.xirsys.com:5349?transport=tcp',
            ],
          }],
      };
    }

    return {
      iceServers: [
        { urls: 'stun:stun.stunprotocol.org:3478' },
        { urls: 'stun:stun.l.google.com:19302' },
      ],
    };
  }
}
