// todo: get rid of Math.pow?
/* eslint-disable no-restricted-properties */

// Magnus' preferences, up for discussion:
/* eslint-disable brace-style,padded-blocks,operator-linebreak,function-paren-newline */

export default class Participant {
  constructor(mingly, name, avatar, videovar, audiovar) {
    // variables
    this.mingly = mingly;
    this.isLocal = false; // set to true for local participants
    this.uuid = null;
    this.name = name;
    this.x = null;
    this.y = null;
    this.speedX = 0;
    this.speedY = 0;
    this.videovar = videovar;
    this.audiovar = audiovar;
    this.pic = null;
    this.color = 'black';
    this.usepicture = false;
    this.firstimePic = true;
    this.reqAnimation = false; // not sure false or true
    this.isPlayingVideo = false; // not sure false or true
    this.hasMoved = false;
    this.isThere = true;
    this.screenShare = false;
    this.failed = false;
    this.noVideo = false;
    this.muted = false;
    this.isSleeping = false;
    this.euclidean = true;
    this.doNotPlayAnyVideosOnCanvas = false;
    this.movingUsingKeyboardNow = false;
    this.movingUsingKeyboardUp = false;
    this.movingUsingKeyboardDown = false;
    this.movingUsingKeyboardLeft = false;
    this.movingUsingKeyboardRight = false;
    this.noSound = false; // if you want to mute everyone
    this.adjustSizeAvatar = {
      adjust: false,
      adjustSize: 20,
      adjustX: 0,
      adjustY: 0,
    };
    this.isGhost = false;
    this.isHost = false; // this is if you are host or cohost
    // when using cursor to show
    this.cursor = {
      active: false,
      size: 20,
      x: null,
      y: null,
    };
    this.setAvatar(avatar);
    this.hoover = false;
    this.uniformSound = false;
    this.isDJ = (name === 'DJMingly');
    this.peerConnection = null;
    this.peerStreams = null;
    // if we mirror the video on the board
    this.flipVideo = false;
    // following etc.
    this.following = {
      isLeading: false,
      participants: {}, // here we say all if to all or a specific id
      isFollowing: false,
    };
    this.followingMove = {
      isFollowing: false,
      following: '',
    };
    this.hasHandRaised = false;

    this.board = null;

    this.userListEntry = null;
    // Games
    this.quizTime = null;

    // DOM elements
    this.videoElement = null;

    // events
    this.onMove = null; // function (participant) {}
    this.onGetUniformSoundVolumeLevel = null; // function() { return floatVolume }
    this.onCursorMove = null; // function (participant)

    // media server
    this.nTransportsReady = 0; // 0, 1 , 2
    this.producerMS = [];
    this.consumerMS = [];
    this.msStream = null;
    this.msVideoElement = null;
    this.readyForMessages = false;
    // some broadcasting variables
    this.localBroadCast = false; // true is broadcasting in small view
    // for audio edit (ipad for now)
    this.audioCtx = null;
    this.trackForAudioEdit = null;
    this.gainNode = null;
    // objects
    this.connectedObjects = []; // array of uuid of connected objects that updates when moving
    // menu
    this.isMenuActive = false;
    this.isMenuInitiated = false;
  }

  // functions
  asJSON() {
    let result = {
      // quoted props makes it easier to distinguish strings from potential local variables
      // eslint-disable-next-line quote-props
      'position_x': this.x, 'position_y': this.y, 'speedX': this.speedX, 'speedY': this.speedY, 'displayName': this.name, 'avatar': this.avatar, 'uuid': this.uuid, 'uniform': this.uniformSound, 'firstimePic': this.firstimePic, 'color': this.color, 'hasMoved': this.hasMoved, 'screenShare': this.screenShare, 'noVideo': this.noVideo, 'movingUsingKeyboardNow': this.movingUsingKeyboardNow, 'mutedParticipant': this.muted, 'isSleeping': this.isSleeping, 'isGhost': this.isGhost, 'isHost': this.isHost,
    };

    if (this.board) {
      // eslint-disable-next-line quote-props
      result = Object.assign(result, { 'room': this.board.room.name });
    }

    return result;
  }

  notSet() {
    return (!this.x || !this.y);
  }

  clearAvatar() {
    this.board.clearAvatar(this);
    this.board.clearSideCanvas(this);
  }

  drawAvatar() {
    this.board.drawAvatar(this);
    this.board.drawSideCanvas(this);
  }

  clearCursor(x, y) {
    this.board.clearCursor(this, x, y);
  }

  drawCursor() {
    this.board.drawCursor(this);
  }

  isOutOfBounds(newX, newY) {
    let onObject = false;
    const notInBoard = ((newX - this.board.avatarRadius < 0) ||
    (newY - this.board.avatarRadius < 0) ||
    (newX + this.board.avatarRadius > this.board.width) ||
    (newY + this.board.avatarRadius > this.board.height));
    // loop over objects to check if some are not walkable, then check if on them
    Object.values(this.board.canvasObjects).forEach((object) => {
      if (!object.isWalkable && object.isActive) {
        const posPerson = {
          x1: newX - this.board.avatarRadius,
          x2: newX + this.board.avatarRadius,
          y1: newY - this.board.avatarRadius,
          y2: newY + this.board.avatarRadius,
        };
        const posObject = {
          x1: object.x,
          x2: object.x + object.width,
          y1: object.y,
          y2: object.y + object.height,
        };
        const test = ((posObject.x1 < posPerson.x1 &&
            posPerson.x1 < posObject.x2) ||
          (posObject.x1 < posPerson.x2 &&
          posPerson.x2 < posObject.x2)) &&
          ((posObject.y1 < posPerson.y1 &&
          posPerson.y1 < posObject.y2) ||
            (posObject.y1 < posPerson.y2 &&
            posPerson.y2 < posObject.y2));
        if (test) {
          onObject = true;
        }
      }
    });
    let condition = false;
    if (onObject || notInBoard) {
      condition = true;
    }
    return condition;
  }

  hasValidPosition() {
    return !this.isOutOfBounds(this.x, this.y);
  }

  activateCursor() {
    this.cursor.active = !this.cursor.active;
    if (this.cursor.active) {
      this.board.canvas1.style.cursor = 'none';
    } else {
      // this can be more targeted
      this.board.updateAll();
      // this.clearCursor(this.x, this.y);
      this.board.canvas1.style.cursor = 'auto';
    }
    if (this.isLocal) {
      if (this.onCursorMove) {
        this.onCursorMove(this);
      }
    }
  }

  moveCursorTo(newX, newY) {
    this.clearCursor(this.cursor.x, this.cursor.y);
    this.cursor.x = newX;
    this.cursor.y = newY;
    this.drawCursor();
    // make sure we don't broadcast new positions for remote participants => endless update loop
    if (this.isLocal) {
      if (this.onCursorMove) {
        this.onCursorMove(this);
      }
    }
  }

  moveTo(targetX, targetY) {
    if (!this.board) {
      console.error('Participant.moveTo: board not assigned');
      return;
    }

    // make sure we deal with numbers
    const newX = parseInt(targetX, 10);
    const newY = parseInt(targetY, 10);

    this.mingly.log(`Participant.moveTo: ${this.x}:${this.y} -> ${newX}:${newY}`);

    if (this.uniformSound) {
      this.mingly.log('Participant.moveTo: participant should not be visible. Clear avatar and exit.');
      this.clearAvatar();
      return;
    }

    if (this.isOutOfBounds(newX, newY)) {
      this.mingly.log('Participant.moveTo: new position out of bounds');
      return;
    }

    // isSpotAvailable will return false if I'm already positioned
    if ((newX === this.x) && (newY === this.y)) {
      this.mingly.log('Participant.moveTo: I\'m there already');
    }
    else {
      // eslint-disable-next-line no-lonely-if
      if (this.isLocal) {
        // also include lockMove as we want to override this if we are placing people around
        // the reason is that is can be done sequentially and if so,
        // the spot might not be availbable immediately
        if (this.board.isSpotAvailable(newX, newY) || this.mingly.hostStates.lockedMove) {
          this.mingly.log('Participant.moveTo: spot is available, moving');
        }
        else {
          this.mingly.log('Participant.moveTo: spot taken');
          return;
        }
      }
    }

    // Clear avatar from old position and draw at new position
    for (let i = 0; i < this.connectedObjects.length; i += 1) {
      const uuid = this.connectedObjects[i];
      console.log('testttt');
      console.log(uuid);
      this.board.canvasObjects[uuid].clear();
    }
    // Clear avatar from old position and draw at new position
    this.clearAvatar();
    this.x = newX;
    this.y = newY;
    this.hasMoved = true;
    this.drawAvatar();

    // make sure we don't broadcast new positions for remote participants => endless update loop
    if (this.isLocal) {
      if (this.onMove) {
        this.onMove(this);
      }
    }

  }

  moveToRandomPosition() {
    if (!this.board) {
      console.error('Participant.moveToRandomPosition: board not defined');
      return false;
    }

    // todo: perhaps exit this loop at some point? this is kinda dangerous...
    while (true) {
      const x0 = Math.max(Math.round(Math.random() * (this.board.width - this.board.avatarRadius)),
        this.board.avatarRadius);
      const y0 = Math.max(Math.round(Math.random() * (this.board.height - this.board.avatarRadius)),
        this.board.avatarRadius);
      if (this.board.isSpotAvailable(x0, y0) && !this.isOutOfBounds(x0, y0)) {
        this.moveTo(x0, y0);
        return true;
      }
    }
  }

  moveToClosesetAvailable(targetX, targetY) {
    if (this.board.isSpotAvailable(targetX, targetY) && !this.isOutOfBounds(targetX, targetY)) {
      this.moveTo(targetX, targetY);
    }
    // else {
    //   let radius = this.board.avatarRadius * 2;
    //   let counter = 0;
    //   let resets = 0;
    //   while (true) {
    //     const x0 = Math.max(Math.round(Math.random() * (radius - this.board.avatarRadius)),
    //       this.board.avatarRadius);
    //     const y0 = Math.max(Math.round(Math.random() * (radius - this.board.avatarRadius)),
    //       this.board.avatarRadius);
    //     if (this.board.isSpotAvailable(targetX + x0, targetY + y0)
    //       && !this.isOutOfBounds(targetX + x0, targetY + y0)) {
    //       this.moveTo(targetX + x0, targetY + y0);
    //     }
    //     counter += 1;
    //     if (counter > 5) {
    //       counter = 0;
    //       radius += this.board.avatarRadius * 2;
    //       resets += 1;
    //     }
    //     if (resets === 5) {
    //       break;
    //     }
    //   }
    // }
  }

  moveLeft() {
    const intervalLoop = setInterval(() => {
      if (this.movingUsingKeyboardLeft) {
        this.moveTo(this.x - 1, this.y);
      } else {
        clearInterval(intervalLoop);
      }
    }, 10);
    // setInterval(
    // if (this.speedX < this.board.maxSpeed) {
    //   this.speedX += 1;
    // }
    // this.moveTo(this.x - this.speedX, this.y);
  }

  moveRight() {
    const intervalLoop = setInterval(() => {
      if (this.movingUsingKeyboardRight) {
        this.moveTo(this.x + 1, this.y);
      } else {
        clearInterval(intervalLoop);
      }
    }, 10);
    // if (this.speedX < this.board.maxSpeed) {
    //   this.speedX += 1;
    // }
    // this.moveTo(this.x + this.speedX, this.y);
  }

  moveUp() {
    const intervalLoop = setInterval(() => {
      if (this.movingUsingKeyboardUp) {
        this.moveTo(this.x, this.y + 1);
      } else {
        clearInterval(intervalLoop);
      }
    }, 10);
    // if (this.speedY < this.board.maxSpeed) {
    //   this.speedY += 1;
    // }
    // this.moveTo(this.x, this.y + this.speedY);
  }

  moveDown() {
    const intervalLoop = setInterval(() => {
      if (this.movingUsingKeyboardDown) {
        this.moveTo(this.x, this.y - 1);
      } else {
        clearInterval(intervalLoop);
      }
    }, 10);
    // if (this.speedY < this.board.maxSpeed) {
    //   this.speedY += 1;
    // }
    // this.moveTo(this.x, this.y - this.speedY);
  }

  isAt(_x, _y) {
    return ((this.x === _x) && (this.y === _y));
  }

  setAvatar(avatar) {
    if (this.firstimePic) {
      this.setParticipantPicture(avatar);
      this.avatar = '0x1F603'; // resets avatar to make it less heavy
      this.firstimePic = false;
    }
  }

  setParticipantPicture(pic) {
    // eslint-disable-next-line no-useless-escape
    const base64regexp = /^(?:[A-Z0-9+\/]{4})*(?:[A-Z0-9+\/]{2}==|[A-Z0-9+\/]{3}=|[A-Z0-9+\/]{4})$/i;
    const isBase64Valid = base64regexp.test(pic); // signalAvatar is the base64 string
    if (isBase64Valid) {
      this.usepicture = true;
      this.mingly.log('It is base64');
      this.pic = new Image();
      this.pic.src = `data:image/png;base64,${pic}`;
    }
    else {
      this.usepicture = false;
    }
  }

  setupAudioCtx(track) {
    // const videoTracks = stream.getVideoTracks(); //find the video stream first
    this.mingly.log('setup Ipad sounds');
    this.audioCtx = new AudioContext();
    const mediaStreamPart = new MediaStream();
    mediaStreamPart.addTrack(track);
    // this.trackForAudioEdit = this.audioCtx.createMediaStreamTrackSource(track);
    this.trackForAudioEdit = this.audioCtx.createMediaStreamSource(mediaStreamPart);

    const mediaStreamDestination = this.audioCtx.createMediaStreamDestination();
    this.gainNode = this.audioCtx.createGain();
    this.trackForAudioEdit.connect(this.gainNode).connect(mediaStreamDestination);
    const newTracks = mediaStreamDestination.stream.getAudioTracks();
    this.peerStreams.addTrack(newTracks[0]);
  }

  distanceTo(aParticipant) {

    if (aParticipant.uniformSound) {
      this.mingly.log('Participant.distanceTo: the other participant is sending uniform sound');
      return -1;
    }

    if (this.localBroadCast) {
      return 1;
    }

    if (this.euclidean) {
      return Math.sqrt(Math.pow((aParticipant.x - this.x) / this.board.scalingFactor, 2)
           + Math.pow((aParticipant.y - this.y) / this.board.scalingFactor, 2));
    }
    else { // eslint-disable-line no-else-return
      return Math.abs((aParticipant.x - this.x) / this.board.scalingFactor)
           + Math.abs((aParticipant.y - this.y) / this.board.scalingFactor);
    }

  }

  adjustVolume(me) {
    if (!this.uuid) {
      console.error('Participant.adjustVolume called before uuid is set');
      return;
    }

    this.mingly.log(`Participant.adjustVolume: searching for streams for ${this.uuid}`);

    let stream;
    if (document.getElementById(`remoteVideo_${this.uuid}`)) {
      stream = document.getElementById(`remoteVideo_${this.uuid}`);
    }
    else {
      stream = document.getElementById(`remoteAudio_${this.uuid}`);
    }

    if (stream) {
      let distance;
      if (me.noSound) {
        if (this.board.isIpad && this.gainNode) {
          // todo: should have a cleaner selection.
          // Did && this.gainNode since it sometime does not work and therefore not allocated
          this.gainNode.gain.value = 0;
        }
        else {
          stream.childNodes[0].volume = 0;
        }
      } else {
        if (this.uniformSound) {
          distance = -1;
        }
        else {
          distance = this.distanceTo(me);
        }

        if (distance > 0) {
          if (this.board.isIpad && this.gainNode) {
            // todo: should have a cleaner selection.
            // Did && this.gainNode since it sometime does not work and therefore not allocated
            this.gainNode.gain.value = Math.pow(1 / distance, this.board.room.soundDecay);
          }
          else {
            stream.childNodes[0].volume = Math.pow(1 / distance, this.board.room.soundDecay);
          }

        }
        else {
          if (this.onGetUniformSoundVolumeLevel) { // eslint-disable-line no-lonely-if
            if (this.board.isIpad) {
              this.gainNode.gain.value = this.onGetUniformSoundVolumeLevel();
            }
            else {
              stream.childNodes[0].volume = this.onGetUniformSoundVolumeLevel();
            }
          }
          else {
            console.error('Participant.adjustVolume: onGetUniformSoundVolumeLevel not set');
          }
        }
      }
      this.mingly.log(`Participant.adjustVolume: vid.childNodes[0].volume: ${stream.childNodes[0].volume}`);

    }
  }

  focus(bInFocus = true) {
    if (!this.userListEntry) {
      console.error('Participant.focus: userListEntry missing');
    }

    if (bInFocus) {
      this.userListEntry.style.backgroundColor = 'red';
    }
    else {
      this.userListEntry.style.backgroundColor = '';
    }

    if (this.hasValidPosition()) {
      // Webpack and instanceOf are not friends
      if (this.board.constructor.name === 'Canvasboard') {
        if (bInFocus) {
          this.hoover = true;
        } else {
          this.hoover = false;
          this.clearAvatar();
          this.drawAvatar();
        }

      }
      else {
        const gridSpot = document.getElementById(`x${this.x}y${this.y}`);
        if (gridSpot) {
          if (bInFocus) {
            gridSpot.style.backgroundColor = 'red';
          } else {
            gridSpot.style.backgroundColor = '';
          }
        }
        else {
          console.error('Participant.focus: position was not found');
        }
      }
    }
  }

  setSpeed(speedX, speedY) {
    // make sure we deal with numbers
    this.speedX = parseInt(speedX, 10);
    this.speedY = parseInt(speedY, 10);
  }

  resumeVideo() {
    // if (participant.videoElement.paused && !participant.isPlayingVideo) {
    if (this.videoElement.paused) {
      this.videoElement.play();
    }
  }

  pauseVideo() {
    // if (!participant.videoElement.paused && participant.isPlayingVideo) {
    if (!this.videoElement.paused) {
      this.videoElement.pause();
    }
  }

}
