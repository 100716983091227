export const SET_CHAT_BOX_ACTIVE = 'SET_CHAT_BOX_ACTIVE';
export const SET_PARTICIPANT_ACTIVE = 'SET_PARTICIPANT_ACTIVE';
export const SET_SETTING_MENU_ACTIVE = 'SET_SETTING_MENU_ACTIVE';
export const SET_CANVAS_VIEW_ACTIVE = 'SET_CANVAS_VIEW_ACTIVE';
export const SET_VIDEO_SHARE_ACTIVE = 'SET_VIDEO_SHARE_ACTIVE';
export const SET_SCREEN_SHARE_ACTIVE = 'SET_SCREEN_SHARE_ACTIVE';
export const SET_MUTE_ACTIVE = 'SET_MUTE_ACTIVE';
export const SET_DEAFEN_ACTIVE = 'SET_DEAFEN_ACTIVE';
export const SET_SLEEP_ACTIVE = 'SET_SLEEP_ACTIVE';
export const SET_SHARE_MEDIA_ACTIVE = 'SET_SHARE_MEDIA_ACTIVE';
export const SET_SHOW_PARTICIPANT_VIDEO_ACTIVE = 'SET_SHOW_PARTICIPANT_VIDEO_ACTIVE';
export const SET_BROADCAST_ACTIVE = 'SET_BROADCAST_ACTIVE';
export const SET_POLL_ACTIVE = 'SET_POLL_ACTIVE';
export const SET_ANSWER_POLL_ACTIVE = 'SET_ANSWER_POLL_ACTIVE';
export const SET_STAT_CREATOR_POLL_ACTIVE = 'SET_STAT_CREATOR_POLL_ACTIVE';
export const SET_STAT_DISPLAY_POLL_ACTIVE = 'SET_STAT_DISPLAY_POLL_ACTIVE';
export const SET_GAMES_ACTIVE = 'SET_GAMES_ACTIVE';
export const SET_GAME_INVITE_ACTIVE = 'SET_GAME_INVITE_ACTIVE';
export const SET_GAME_INVITATION_ACTIVE = 'SET_GAME_INVITATION_ACTIVE';
export const SET_GAME_DATA = 'SET_GAME_DATA';
export const ADD_PLAYER_DATA = 'ADD_PLAYER_DATA';
export const SET_SCORE_BOARD_GTC_ACTIVE = 'SET_SCORE_BOARD_GTC_ACTIVE';
export const SET_ENTER_VIDEO_ACTIVE = 'SET_ENTER_VIDEO_ACTIVE';
export const SET_SIDE_CANVAS_ACTIVE = 'SET_SIDE_CANVAS_ACTIVE';
export const SET_BROADCAST_MENU_ACTIVE = 'SET_BROADCAST_MENU_ACTIVE';
export const SET_BROADCAST_MINGLY_MODE_ACTIVE = 'SET_BROADCAST_MINGLY_MODE_ACTIVE';
export const SET_CHAT_BEHAVIOR_ACTIVE = 'SET_CHAT_BEHAVIOR_ACTIVE';
export const SET_CHAT_DRAGGABLE_ACTIVE = 'SET_CHAT_DRAGGABLE_ACTIVE';
export const SET_FOCUS_VIEW_ACTIVE = 'SET_FOCUS_VIEW_ACTIVE';
export const SET_HOST_MENU_ACTIVE = 'SET_HOST_MENU_ACTIVE';
export const SET_HOST_SETTINGS_ACTIVE = 'SET_HOST_SETTINGS_ACTIVE';
export const SET_LOCK_POSITION_ACTIVE = 'SET_LOCK_POSITION_ACTIVE';
export const SET_MUTE_ALL_ACTIVE = 'SET_MUTE_ALL_ACTIVE';
export const SET_NO_VIDEO_ALL_ACTIVE = 'SET_NO_VIDEO_ALL_ACTIVE';
export const SET_BROADCAST_ME_ACTIVE = 'SET_BROADCAST_ME_ACTIVE';
export const SET_FOLLOW_MY_VIEW_ACTIVE = 'SET_FOLLOW_MY_VIEW_ACTIVE';
export const SET_NO_HOST_MENU_ACTIVE = 'SET_NO_HOST_MENU_ACTIVE';
export const SET_RAISE_HAND_ACTIVE = 'SET_RAISE_HAND_ACTIVE';
export const ADD_RAISED_HAND = 'ADD_RAISED_HAND';
export const REMOVE_RAISED_HAND = 'REMOVE_RAISED_HAND';
export const SET_RAISED_HAND_OVERVIEW_ACTIVE = 'SET_RAISED_HAND_OVERVIEW_ACTIVE';

export default {
  [SET_CHAT_BEHAVIOR_ACTIVE](state, data) {
    state.isChatBehaviorActive = data;
  },
  [SET_CHAT_DRAGGABLE_ACTIVE](state, data) {
    state.isChatDraggableActive = data;
  },
  [SET_CHAT_BOX_ACTIVE](state) {
    state.isChatBoxActive = !state.isChatBoxActive;
    if (state.isChatBoxActive) {
      window.mingly.board.move_keyboard = false;
    } else {
      window.mingly.board.move_keyboard = true;
    }
  },
  [SET_BROADCAST_MINGLY_MODE_ACTIVE](state) {
    state.isBroadcastMinglyModeActive = !state.isBroadcastMinglyModeActive;
  },
  [SET_BROADCAST_MENU_ACTIVE](state) {
    state.isBroadcastMenuActive = !state.isBroadcastMenuActive;
  },
  [SET_PARTICIPANT_ACTIVE](state) {
    state.isParticipantActive = !state.isParticipantActive;
  },
  [SET_SETTING_MENU_ACTIVE](state) {
    state.isSettingMenuActive = !state.isSettingMenuActive;
  },
  [SET_CANVAS_VIEW_ACTIVE](state) {
    state.isCanvasViewActive = !state.isCanvasViewActive;
  },
  [SET_VIDEO_SHARE_ACTIVE](state) {
    state.isVideoShareActive = !state.isVideoShareActive;
  },
  [SET_SCREEN_SHARE_ACTIVE](state) {
    state.isScreenShareActive = !state.isScreenShareActive;
  },
  [SET_MUTE_ACTIVE](state) {
    state.isMuteActive = !state.isMuteActive;
  },
  [SET_DEAFEN_ACTIVE](state) {
    state.isDeafenActive = !state.isDeafenActive;
  },
  [SET_SLEEP_ACTIVE](state) {
    state.isSleepActive = !state.isSleepActive;
  },
  [SET_SHARE_MEDIA_ACTIVE](state) {
    state.isShareMediaActive = !state.isShareMediaActive;
  },
  [SET_SHOW_PARTICIPANT_VIDEO_ACTIVE](state, data) {
    if (data.remove) {
      if (state.user.userID === data.userID) {
        state.isShowParticipantVideoActive = !state.isShowParticipantVideoActive;
        state.user.userID = data.userID;
        state.user.videovar = data.videovar;
      }
      return;
    }
    state.isShowParticipantVideoActive = !state.isShowParticipantVideoActive;
    state.user.userID = data.userID;
    state.user.videovar = data.videovar;
  },
  [SET_BROADCAST_ACTIVE](state, data) {
    // if (data.remove) {
    //   if (state.user.userID === data.userID) {
    //     state.isBroadCastActive = !state.isBroadCastActive;
    //     state.userBroadCast.userID = data.userID;
    //     state.userBroadCast.isMe = data.isMe;
    //   }
    //   return;
    // }
    state.isBroadCastActive = !state.isBroadCastActive;
    state.userBroadCast.userID = data.userID;
    state.userBroadCast.isMe = data.isMe;
  },
  [SET_POLL_ACTIVE](state) {
    state.isPollActive = !state.isPollActive;
  },
  [SET_ANSWER_POLL_ACTIVE](state) {
    state.isAnswerPollActive = !state.isAnswerPollActive;
  },
  [SET_STAT_CREATOR_POLL_ACTIVE](state) {
    state.isStatCreatorPollActive = !state.isStatCreatorPollActive;
  },
  [SET_STAT_DISPLAY_POLL_ACTIVE](state) {
    state.isStatDisplayPollActive = !state.isStatDisplayPollActive;
  },
  [SET_GAMES_ACTIVE](state) {
    state.isGamesActive = !state.isGamesActive;
  },
  [SET_GAME_INVITE_ACTIVE](state, game) {
    state.isGameInviteActive = !state.isGameInviteActive;
    state.game = game;
  },
  [SET_GAME_INVITATION_ACTIVE](state) {
    state.isGameInvitationActive = !state.isGameInvitationActive;
  },
  [SET_GAME_DATA](state, gameData) {
    state.gameData = gameData;
  },
  [ADD_PLAYER_DATA](state, payload) {
    let isPlayerNew = true;
    for (let i = 0; i < state.gameData.players.length; i += 1) {
      if (state.gameData.players[i].userId === payload.userId) {
        state.gameData.players[i].score = payload.score;
        isPlayerNew = false;
      }
    }
    if (isPlayerNew) {
      state.gameData.players.push(payload);
    }
  },
  [SET_SCORE_BOARD_GTC_ACTIVE](state) {
    state.isScoreboardGTCActive = !state.isScoreboardGTCActive;
  },
  [SET_ENTER_VIDEO_ACTIVE](state) {
    state.isEnterVideoActive = !state.isEnterVideoActive;
  },
  [SET_SIDE_CANVAS_ACTIVE](state) {
    state.isSideCanvasActive = !state.isSideCanvasActive;
  },
  [SET_FOCUS_VIEW_ACTIVE](state) {
    state.isFocusViewActive = !state.isFocusViewActive;
  },
  [SET_HOST_MENU_ACTIVE](state) {
    state.isHostMenuActive = !state.isHostMenuActive;
  },
  [SET_HOST_SETTINGS_ACTIVE](state) {
    state.isHostSettingsActive = !state.isHostSettingsActive;
  },
  [SET_LOCK_POSITION_ACTIVE](state) {
    state.isLockPositionActive = !state.isLockPositionActive;
  },
  [SET_MUTE_ALL_ACTIVE](state) {
    state.isMuteAllActive = !state.isMuteAllActive;
  },
  [SET_NO_VIDEO_ALL_ACTIVE](state) {
    state.isNoVideoAllActive = !state.isNoVideoAllActive;
  },
  [SET_BROADCAST_ME_ACTIVE](state) {
    state.isBroadCastMeActive = !state.isBroadCastMeActive;
  },
  [SET_FOLLOW_MY_VIEW_ACTIVE](state) {
    state.isFollowMyViewActive = !state.isFollowMyViewActive;
  },
  [SET_NO_HOST_MENU_ACTIVE](state) {
    state.isNoHostMenuActive = !state.isNoHostMenuActive;
  },
  [SET_RAISE_HAND_ACTIVE](state) {
    state.isRaiseHandActive = !state.isRaiseHandActive;
  },
  [ADD_RAISED_HAND](state, data) {
    state.raisedHands.push({
      id: data.id,
      name: data.name,
    });
  },
  [REMOVE_RAISED_HAND](state, data) {
    const part = [];
    for (let i = 0; i < state.raisedHands.length; i += 1) {
      if (state.raisedHands[i].id !== data.id) {
        part.push(state.raisedHands[i]);
      }
      state.raisedHands = part;
    }
  },
  [SET_RAISED_HAND_OVERVIEW_ACTIVE](state) {
    state.isRaisedHandOverviewActive = !state.isRaisedHandOverviewActive;
  },
};
