<template>
  <div>
    <v-card dark max-width="500" min-width="400" class="pa-4">
      <CloseButton :method="closeShareMediaComponent" class="close_button" />
      <h1 class="text-center pa-2">Share media</h1>
      <h3 class="pa-2">Change background</h3>
      <v-text-field v-model="backgroundImageUrl"
                    label="background image"
                    class=""
                    filled
                    rounded
                    hide-details
                    prepend-inner-icon="mdi-link-variant" />
      <v-card-actions class="justify-center">
        <v-btn dark elevation="8"
               large class="btn_width"
               v-on:click="changeBackground">Change Background</v-btn>
      </v-card-actions>
      <!--
      <h3 class="pa-2">Share video</h3>
      <v-text-field v-model="video.videoImageUrl"
                    label="(embedded?)video url"
                    filled
                    rounded
                    hide-details
                    prepend-inner-icon="mdi-link-variant" />
      <v-row class="pa-2">
        <v-col cols="2">
          <v-text-field v-model="video.x"
                        label="x"
                        hide-details />
        </v-col>
        <v-col cols="2">
          <v-text-field v-model="video.y"
                        label="y"
                        hide-details />
        </v-col>
        <v-col cols="2">
          <v-text-field v-model="video.size"
                        label="size"
                        hide-details />
        </v-col>
      </v-row>
      <v-card-actions class="justify-center">
        <v-btn dark elevation="8"
               large class="btn_width"
        v-on:click="shareVideo">Share video</v-btn>
      </v-card-actions>
      <h3 class="pa-2">Share audio</h3>
      <v-text-field v-model="audioUrl"
                    label="audio url"
                    filled
                    rounded
                    hide-details
                    prepend-inner-icon="mdi-link-variant" />
      <v-card-actions class="justify-center">
        <v-btn dark elevation="8"
               large class="btn_width"
               v-on:click="shareAudio">Share audio</v-btn>
      </v-card-actions>
      -->
    </v-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { HostChangeBackGroundPicture } from '../../../assets/js/webrtc';
import CloseButton from './CloseButton.vue';

export default {
  name: 'ShareMedia',
  components: {
    CloseButton,
  },
  data() {
    return {
      backgroundImageUrl: '',
      video: {
        videoImageUrl: '',
        x: 10,
        y: 10,
        size: 256,
        // volume; is volume something we need?
      },
      audioUrl: '', // if we need volume prop, change structure to audio.url, audio.volume
    };
  },
  methods: {
    ...mapActions('$_canvas', [
      'commitSetShareMediaActive',
    ]),
    changeBackground() {
      // todo: code for changing background. Data: this.backgroundImageUrl
      const img = new Image();
      const imgUrl = this.backgroundImageUrl;
      img.src = imgUrl;
      img.onload = function () {
        HostChangeBackGroundPicture(imgUrl);
      };
      // HostChangeBackGroundPicture(this.backgroundImageUrl);
    },
    shareVideo() {
      // todo: code for sharing video. Data: video.videoImageUrl, video.x, video.y, video.size
    },
    shareAudio() {
      // todo: code for sharing audio. Data: audioUrl
    },
    checkActive() {
      window.mingly.board.move_keyboard = false;
    },
    closeShareMediaComponent() {
      this.commitSetShareMediaActive();
    },
  },
};
</script>

<style scoped>
  .btn_width {
    width: 80%;
  }
  .close_button {
    position: absolute;
    right: 0.2vh;
    top: 0.2vh;
  }
</style>
