<template>
  <v-app>
    <v-main>
      <Header />
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import Header from './_components/Header.vue';

export default {
  name: 'index.vue',
  components: { Header },
};
</script>

<style scoped>

</style>
