<template>
  <div class="text-center">
    <v-row>
      <v-col>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn fab dark v-on="on" v-bind="attrs" v-on:click="changeSettingMenuActive">
              <v-icon>
                mdi-cog
              </v-icon>
            </v-btn>
          </template>
          <span>Settings</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-if="isVideoShareActive"
                   fab dark v-on="on" v-bind="attrs" v-on:click="turnVideoOff()">
              <v-icon>
                  mdi-video
              </v-icon>
            </v-btn>
            <v-btn v-else
                   fab dark v-on="on" v-bind="attrs" v-on:click="turnVideoOff()">
              <v-icon color="orange lighten-1">
                mdi-video-off
              </v-icon>
            </v-btn>
          </template>
          <span>Turn camera on/off</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-if="!isMuteActive"
                   fab dark v-on="on" v-bind="attrs" v-on:click="turnAudioOff()">
              <v-icon>
                mdi-microphone
              </v-icon>
            </v-btn>
            <v-btn v-else fab dark v-on="on" v-bind="attrs" v-on:click="turnAudioOff()">
              <v-icon color="orange lighten-1">
                mdi-microphone-off
              </v-icon>
            </v-btn>
          </template>
          <span>Mute</span>
        </v-tooltip>
     </v-col>
   </v-row>
    <v-row>
      <v-col>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-if="!isDeafenActive"
                   fab dark v-on="on" v-bind="attrs" v-on:click="deafen()">
              <v-icon>
                mdi-headphones
              </v-icon>
            </v-btn>
            <v-btn v-else fab dark v-on="on" v-bind="attrs" v-on:click="deafen()">
              <v-icon color="orange lighten-1">
                mdi-headphones-off
              </v-icon>
            </v-btn>
          </template>
          <span>Deafen</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-if="!isSleepActive"
                   fab dark v-on="on" v-bind="attrs" v-on:click="goToSleep()">
              <v-icon>
                mdi-sleep
              </v-icon>
            </v-btn>
            <v-btn v-else fab dark v-on="on" v-bind="attrs" v-on:click="goToSleep()">
             <v-icon color="orange lighten-1">
                mdi-sleep
              </v-icon>
            </v-btn>
          </template>
          <span>Sleep</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-if="isScreenShareActive"
                   fab dark v-on="on" v-bind="attrs" v-on:click="shareMyScreen()">
              <v-icon color="orange lighten-1">
                mdi-monitor
              </v-icon>
            </v-btn>
            <v-btn v-else fab dark v-on="on" v-bind="attrs" v-on:click="shareMyScreen()">
              <v-icon>
                mdi-monitor-off
              </v-icon>
            </v-btn>
          </template>
          <span>Share your screen</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn fab dark v-on="on" v-bind="attrs" v-on:click="changeParticipantActive">
              <v-icon>
                mdi-account-multiple
              </v-icon>
            </v-btn>
          </template>
          <span>Online Users</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div style="position: absolute; color: white; z-index: 1; font-size: 10px; right: 0;"
             v-if="this.totalUnreadMessages > 0">
          {{ this.totalUnreadMessages }}
          <v-icon size="12" dark>
            mdi-bell-ring
          </v-icon>
        </div>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn fab dark v-on="on" v-bind="attrs" v-on:click="changeChatActive">
              <v-icon>
                mdi-chat
              </v-icon>
            </v-btn>
          </template>
          <span>Chat</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <!-- <v-row>
      <v-col>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn fab dark v-on="on" v-bind="attrs" v-on:click="changeVideoView">
              <v-icon>
                mdi-video-switch
              </v-icon>
            </v-btn>
          </template>
          <span>Switch between "Canvas view" and "Classic view"</span>
        </v-tooltip>
      </v-col>
    </v-row> -->
    <v-row>
      <v-col>
        <v-btn btn v-if="!isFocusViewActive"
                   fab dark v-on="on" v-bind="attrs" v-on:click="hooverMode()">
          <v-icon>
            mdi-magnify
          </v-icon>
          </v-btn>
          <v-btn v-else fab dark v-on="on" v-bind="attrs" v-on:click="hooverMode()">
          <v-icon color="orange lighten-1">
            mdi-magnify
          </v-icon>
          </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="this.menuItems.broadCastMenu">
      <v-col>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn fab dark v-on="on" v-bind="attrs" v-on:click="broadcastMenu()">
              <v-icon>
                mdi-bullhorn-outline
              </v-icon>
            </v-btn>
          </template>
          <span>Broadcast</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row  v-if="this.menuItems.shareMediaMenu || this.username.match('(\\[Mingly Crew\\])')">
      <v-col>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn fab dark v-on="on" v-bind="attrs" v-on:click="changeShareMediaMenuActive()">
              <v-icon>
                <!-- mdi-play-box-outline -->
                mdi-image
              </v-icon>
            </v-btn>
          </template>
          <span>Change background image</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row v-if="this.menuItems.pollMenu">
      <v-col>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn fab dark v-on="on" v-bind="attrs"  v-on:click="createPoll()">
              <v-icon>
                mdi-poll
              </v-icon>
            </v-btn>
          </template>
          <span>Create new poll</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row v-if="this.menuItems.gameMenu">
      <v-col>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn fab dark v-on="on" v-bind="attrs" v-on:click="createGame()">
              <v-icon>
                mdi-controller
              </v-icon>
            </v-btn>
          </template>
          <span>Start new game</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn fab dark v-on="on" v-bind="attrs" v-on:click="leaveRoom()">
              <v-icon>
                mdi-logout
              </v-icon>
            </v-btn>
          </template>
          <span>Leave Room</span>
        </v-tooltip>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  // eslint-disable-next-line no-unused-vars
  muteAudio, noVideo, shareScreen, getSilence, sleep, broadCast, closeMSbroadCast,
} from '../../../assets/js/webrtc';

export default {
  name: 'CanvasMenu',
  computed: {
    ...mapGetters('$_canvas', [
      'isVideoShareActive',
      'isScreenShareActive',
      'isMuteActive',
      'isDeafenActive',
      'isSleepActive',
      'isFocusViewActive',
    ]),
    ...mapGetters('$_userdata', [
      'totalUnreadMessages',
      'messages',
      'menuItems',
      'username',
    ]),
  },
  methods: {
    ...mapActions('$_canvas', [
      'commitSetChatBoxActive',
      'commitSetParticipantActive',
      'commitSetSettingMenuActive',
      'commitSetCanvasViewActive',
      'commitSetVideoShareActive',
      'commitSetDeafenActive',
      'commitSetSleepActive',
      'commitSetMuteActive',
      'commitSetShareMediaActive',
      'commitSetPollActive',
      'commitSetGamesActive',
      'commitSetBroadCastActive',
      'commitSetBroadcastMenuActive',
      'commitSetFocusViewActive',
    ]),
    ...mapActions('$_userdata', [
      'commitSetChatBox',
      'commitSetTotalMessageCount',
    ]),
    broadcastMenu() {
      this.commitSetBroadcastMenuActive();
      /*
      if (window.mingly.msBroadCastId === window.mingly.board.me.uuid) {
        closeMSbroadCast();
      } else {
        broadCast('ms_video');
      } */
    },
    leaveRoom() {
      this.$router.push({ name: 'Login' });
    },
    createGame() {
      this.commitSetGamesActive();
    },
    createPoll() {
      this.commitSetPollActive();
    },
    changeChatActive() {
      this.commitSetChatBox();
      this.commitSetChatBoxActive();

      let totalCount = 0;
      for (let i = 0; i < this.messages.length; i += 1) {
        totalCount += this.messages[i].unreadMessages;
      }
      this.commitSetTotalMessageCount(totalCount);
    },
    changeParticipantActive() {
      this.commitSetParticipantActive();
    },
    changeSettingMenuActive() {
      this.commitSetSettingMenuActive();
    },
    changeShareMediaMenuActive() {
      this.commitSetShareMediaActive();
    },
    changeVideoView() {
      this.commitSetCanvasViewActive();
    },
    turnVideoOff() {
      this.commitSetVideoShareActive();
      noVideo();
    },
    turnAudioOff() {
      this.commitSetMuteActive();
      muteAudio();
    },
    deafen() {
      this.commitSetDeafenActive();
      getSilence();
    },
    goToSleep() {
      this.commitSetSleepActive();
      sleep();
    },
    shareMyScreen() {
      shareScreen();
    },
    hooverMode() {
      this.commitSetFocusViewActive();
      if (window.mingly.board.fullscreenMode) {
        window.mingly.board.fullscreenMode = false;
        // window.mingly.board.canvas0.style.opacity = 1;
        // window.mingly.board.canvas2.style.opacity = 1;
      } else {
        window.mingly.board.fullscreenMode = true;
        // window.mingly.board.canvas0.style.opacity = 0.5;
        // window.mingly.board.canvas2.style.opacity = 0.5;
      }
    },
  },
};
</script>

<style scoped>
  ::-webkit-scrollbar {
    width: 5px;
    padding-left: 2px;
  }
  ::-webkit-scrollbar-thumb {
    background: #b9b9b9;
  }
</style>
