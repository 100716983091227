var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c('v-row',[_c('v-col',[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":""},on:{"click":_vm.changeSettingMenuActive}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-cog ")])],1)]}}])},[_c('span',[_vm._v("Settings")])])],1)],1),_c('v-row',[_c('v-col',[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.isVideoShareActive)?_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":""},on:{"click":function($event){return _vm.turnVideoOff()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-video ")])],1):_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":""},on:{"click":function($event){return _vm.turnVideoOff()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"orange lighten-1"}},[_vm._v(" mdi-video-off ")])],1)]}}])},[_c('span',[_vm._v("Turn camera on/off")])])],1)],1),_c('v-row',[_c('v-col',[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(!_vm.isMuteActive)?_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":""},on:{"click":function($event){return _vm.turnAudioOff()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-microphone ")])],1):_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":""},on:{"click":function($event){return _vm.turnAudioOff()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"orange lighten-1"}},[_vm._v(" mdi-microphone-off ")])],1)]}}])},[_c('span',[_vm._v("Mute")])])],1)],1),_c('v-row',[_c('v-col',[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(!_vm.isDeafenActive)?_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":""},on:{"click":function($event){return _vm.deafen()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-headphones ")])],1):_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":""},on:{"click":function($event){return _vm.deafen()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"orange lighten-1"}},[_vm._v(" mdi-headphones-off ")])],1)]}}])},[_c('span',[_vm._v("Deafen")])])],1)],1),_c('v-row',[_c('v-col',[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(!_vm.isSleepActive)?_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":""},on:{"click":function($event){return _vm.goToSleep()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-sleep ")])],1):_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":""},on:{"click":function($event){return _vm.goToSleep()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"orange lighten-1"}},[_vm._v(" mdi-sleep ")])],1)]}}])},[_c('span',[_vm._v("Sleep")])])],1)],1),_c('v-row',[_c('v-col',[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.isScreenShareActive)?_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":""},on:{"click":function($event){return _vm.shareMyScreen()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"orange lighten-1"}},[_vm._v(" mdi-monitor ")])],1):_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":""},on:{"click":function($event){return _vm.shareMyScreen()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-monitor-off ")])],1)]}}])},[_c('span',[_vm._v("Share your screen")])])],1)],1),_c('v-row',[_c('v-col',[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":""},on:{"click":_vm.changeParticipantActive}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-account-multiple ")])],1)]}}])},[_c('span',[_vm._v("Online Users")])])],1)],1),_c('v-row',[_c('v-col',[(this.totalUnreadMessages > 0)?_c('div',{staticStyle:{"position":"absolute","color":"white","z-index":"1","font-size":"10px","right":"0"}},[_vm._v(" "+_vm._s(this.totalUnreadMessages)+" "),_c('v-icon',{attrs:{"size":"12","dark":""}},[_vm._v(" mdi-bell-ring ")])],1):_vm._e(),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":""},on:{"click":_vm.changeChatActive}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-chat ")])],1)]}}])},[_c('span',[_vm._v("Chat")])])],1)],1),_c('v-row',[_c('v-col',[(!_vm.isFocusViewActive)?_c('v-btn',_vm._g(_vm._b({attrs:{"btn":"","fab":"","dark":""},on:{"click":function($event){return _vm.hooverMode()}}},'v-btn',_vm.attrs,false),_vm.on),[_c('v-icon',[_vm._v(" mdi-magnify ")])],1):_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":""},on:{"click":function($event){return _vm.hooverMode()}}},'v-btn',_vm.attrs,false),_vm.on),[_c('v-icon',{attrs:{"color":"orange lighten-1"}},[_vm._v(" mdi-magnify ")])],1)],1)],1),(this.menuItems.broadCastMenu)?_c('v-row',[_c('v-col',[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":""},on:{"click":function($event){return _vm.broadcastMenu()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-bullhorn-outline ")])],1)]}}],null,false,4146143311)},[_c('span',[_vm._v("Broadcast")])])],1)],1):_vm._e(),(this.menuItems.shareMediaMenu || this.username.match('(\\[Mingly Crew\\])'))?_c('v-row',[_c('v-col',[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":""},on:{"click":function($event){return _vm.changeShareMediaMenuActive()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-image ")])],1)]}}],null,false,3877705429)},[_c('span',[_vm._v("Change background image")])])],1)],1):_vm._e(),(this.menuItems.pollMenu)?_c('v-row',[_c('v-col',[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":""},on:{"click":function($event){return _vm.createPoll()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-poll ")])],1)]}}],null,false,3379940262)},[_c('span',[_vm._v("Create new poll")])])],1)],1):_vm._e(),(this.menuItems.gameMenu)?_c('v-row',[_c('v-col',[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":""},on:{"click":function($event){return _vm.createGame()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-controller ")])],1)]}}],null,false,927207092)},[_c('span',[_vm._v("Start new game")])])],1)],1):_vm._e(),_c('v-row',[_c('v-col',[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":""},on:{"click":function($event){return _vm.leaveRoom()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-logout ")])],1)]}}])},[_c('span',[_vm._v("Leave Room")])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }