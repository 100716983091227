<template>
  <div>
    <vue-drag-resize :isResizable="false"
                     :w="this.width" :h="this.height"
                     :x="this.left" :y="this.top">
      <v-card dark :height="this.height" :width="this.width">
        <div class="scrollbar">
          <v-list-item v-for="(entry, index) in this.gameData.players" :key="index">
            <v-row>
              <v-col cols="4">
                {{ entry.name }}
              </v-col>
              <v-col cols="4">
                 {{ entry.score }}
              </v-col>
            </v-row>
          </v-list-item>
        </div>
        <v-row v-if="this.gameData.creatorId === myId" >
          <v-col class="pa-6" style="text-align: center;">
            <v-btn v-on:click="startTheGame()">Start Game</v-btn>
          </v-col>
          <v-col class="pa-6" style="text-align: center;">
            <v-btn v-on:click="stopTheGame()">Stop Game</v-btn>
          </v-col>
        </v-row>
      </v-card>
    </vue-drag-resize>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import VueDragResize from 'vue-drag-resize';
import { drawGame, stopGame } from '../../../../../assets/js/webrtc';

export default {
  name: 'ScoreboardGTC',
  components: {
    VueDragResize,
  },
  data: () => ({
    top: 0.15 * window.innerHeight,
    left: 0.02 * window.innerWidth,
    width: 0.3 * window.innerWidth,
    height: 0.4 * window.innerHeight,
    myId: window.mingly.board.me.uuid,
  }),
  computed: {
    // ...mapGetters('$_userdata', ['userID']),
    ...mapGetters('$_canvas', ['gameData']),
    totalAnswers() {
      let result = 0;
      for (let i = 0; i < this.pollResults.length; i += 1) {
        result += this.pollResults[i];
      }
      return result;
    },
  },
  methods: {
    ...mapActions('$_canvas', [
      'commitSetGameData',
      'commitSetScoreBoardGTCActive',
    ]),
    startTheGame() {
      // this.checkedAlternatives
      drawGame();
      console.log('test');
    },
    stopTheGame() {
      stopGame(this.gameData);
      const gameDat = {
        Type: '',
        creatorId: '',
        invitedPlayers: [],
        players: [{
          userId: '',
          name: '',
          score: null,
        }],
      };
      this.commitSetGameData(gameDat);
      this.commitSetScoreBoardGTCActive();
      // this.checkedAlternatives
      console.log('test');
    },
  },
};
</script>

<style scoped>
  .header_scrollbar {
    max-height: 10vh;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .scrollbar {
    max-height: 15vh;
    overflow-y: auto;
    overflow-x: hidden;
  }
  ::-webkit-scrollbar {
    width: 3px;
  }
  ::-webkit-scrollbar-thumb {
    background: #b9b9b9;
  }
</style>
